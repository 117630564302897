import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import Rating from './Rating';
import axios from 'axios';
import { useContext } from 'react';
import { Store } from '../Store';
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBBtn,
  MDBRipple
} from 'mdb-react-ui-kit';

function Product(props) {
  const { product } = props;

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const {
    cart: { cartItems },
  } = state;

  const addToCartHandler = async (item) => {
    const existItem = cartItems.find((x) => x._id === product._id);
    const quantity = existItem ? existItem.quantity + 1 : 1;
    const { data } = await axios.get(`https://et-jewelry-api.onrender.com/api/products/${item._id}`);
    if (data.countInStock < quantity) {
      window.alert('הוספת לעגלה את האחרון במלאי');
      return;
    }
    ctxDispatch({
      type: 'CART_ADD_ITEM',
      payload: { ...item, quantity },
    });
  };

  return (
    <MDBCard className='mdb-card'>
      <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
        <Link to={`/product/${product.slug}`} className="prod-img">
          <MDBCardImage src={product.image} className="card-img-top" fluid alt='...' />
          <a>
            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }}></div>
          </a>
        </Link>
      </MDBRipple>
      <MDBCardBody className='card-body'>
        <Link to={`/product/${product.slug}`}>
          <MDBCardTitle Style="cursor:pointer">{product.name}</MDBCardTitle>
        </Link>
        <Rating rating={product.rating} numReviews={product.numReviews}></Rating>
        <MDBCardText>
        ₪{product.price}
        </MDBCardText>
        {product.countInStock === 0 ? (
          <MDBBtn color="dark" disabled>אזל במלאי</MDBBtn>
        ) : (
          <MDBBtn onClick={() => addToCartHandler(product)}>הוסף לעגלה</MDBBtn>
        )}
      </MDBCardBody>
    </MDBCard>
  );
}
export default Product;
