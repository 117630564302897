import React, { useEffect, useReducer } from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Product from '../components/Product';
import { Helmet } from 'react-helmet-async';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import Carousel from 'react-bootstrap/Carousel';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return { ...state, products: action.payload, loading: false };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

function HomeScreen() {
  const [{ loading, error, products }, dispatch] = useReducer(reducer, {
    products: [],
    loading: true,
    error: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'FETCH_REQUEST' });
      try {
        await axios.post('https://et-jewelry-api.onrender.com/hit');
        const result = await axios.get('https://et-jewelry-api.onrender.com/api/products');

        dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
      } catch (err) {
        dispatch({ type: 'FETCH_FAIL', payload: err.message });
      }

      // setProducts(result.data);
    };
    fetchData();
  }, []);
  
  return (
    <div>
      <Helmet>
        <title>אִיתִּי תכשיטים</title>
      </Helmet>
      <div className="container">
        <p Style="text-align:right;  float:right;">
          <h4>אִיתִּי תכשיטים בעבודת יד</h4>

          <p>
            .אנחנו חברת תכשיטים בעובדת יד לגברים ולנשים בכל הגילאים
          </p>
          <p>
            .שרשראות עשויות מאבני חן, עוצבו והותאמו על ידי איתי עדיני
          </p>
          <p>
            .אנחנו מבצעים משלוחים לכל הארץ
          </p>
          <p>
            .ניתן ליצור איתנו קשר בנייד או בווצ'אפ לשאלות נוספות
          </p>
        </p>
        <div Style="width:800px" className='home-carousel'>
          <Carousel variant="dark" controls={false} interval={4000} Style="border-radius:3px;">
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="https://i.imgur.com/AjrKq2X.jpeg"
                alt="..."
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="https://i.imgur.com/iT6uLaE.jpeg"
                alt="..."
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="https://imgur.com/q6KhJKS.jpeg"
                alt="..."
              />
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
      <br></br>
      <h1>מוצרים חדשים</h1>
      <div className="products">
        {loading ? (
          <LoadingBox />
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <Row>
            {products.map((product) => (
              <Col key={product.slug} xs={6} sm={6} md={4} lg={3} className="mb-3">
                <Product product={product}></Product>
              </Col>
            ))}
          </Row>
        )}
      </div>
    </div>
  );
}

export default HomeScreen;
