import axios from 'axios';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { Store } from '../Store';
import { getError } from '../utils';
import { toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';

function reducer(state, action) {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true, error: '' };
    case 'FETCH_SUCCESS':
      return { ...state, loading: false, order: action.payload, error: '' };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    case 'PAY_REQUEST':
      return { ...state, loadingPay: true };
    case 'PAY_SUCCESS':
      return { ...state, loadingPay: false, successPay: true };
    case 'PAY_FAIL':
      return { ...state, loadingPay: false };
    case 'PAY_RESET':
      return { ...state, loadingPay: false, successPay: false };

    case 'DELIVER_REQUEST':
      return { ...state, loadingDeliver: true };
    case 'DELIVER_SUCCESS':
      return { ...state, loadingDeliver: false, successDeliver: true };
    case 'DELIVER_FAIL':
      return { ...state, loadingDeliver: false };
    case 'DELIVER_RESET':
      return {
        ...state,
        loadingDeliver: false,
        successDeliver: false,
      };
    default:
      return state;
  }
}
export default function OrderScreen() {
  const { state } = useContext(Store);
  const { userInfo } = state;

  const params = useParams();
  const { id: orderId } = params;
  const navigate = useNavigate();

  const [paymentCash, setpaymentCash] = useState(false);

  const togglePaymentTrue = () => {
    setpaymentCash(true);
  };

  const togglePaymentFalse = () => {
    setpaymentCash(false);
  };


  const [paymentMethodName, setPaymentMethod] = useState('');
  const [user, setUser] = useState('');

  const [
    {
      loading,
      error,
      order,
      successPay,
      loadingPay,
      loadingDeliver,
      successDeliver,
    },
    dispatch,
  ] = useReducer(reducer, {
    loading: true,
    order: {},
    error: '',
    successPay: false,
    loadingPay: false,
  });

  const [{ isPending }, paypalDispatch] = usePayPalScriptReducer();

  function createOrder(data, actions) {
    return actions.order
      .create({
        purchase_units: [
          {
            amount: { value: order.totalPrice },
          },
        ],
      })
      .then((orderID) => {
        return orderID;
      });
  }

  function takeAway() {
    toast.success('צור קשר 052-3500700');
  };



  function onApprove(data, actions) {
    return actions.order.capture().then(async function (details) {
      try {
        dispatch({ type: 'PAY_REQUEST' });
        const { data } = await axios.put(
          `https://et-jewelry-api.onrender.com/api/orders/${order._id}/pay`,
          details,
          {
            headers: { authorization: `Bearer ${userInfo.token}` },
          }
        );
        dispatch({ type: 'PAY_SUCCESS', payload: data });
        toast.success('הזמנה שולמה בהצלחה, תודה!. קבלה תשלח לאימייל');
        window.Email.send({
          Host: "smtp.elasticemail.com",
          Username: `${process.env.REACT_APP_MAIL_USERNAME}`,
          Password: `${process.env.REACT_APP_MAIL_PASSWORD}`,
          To: `${userInfo.email}`,
          From: `${process.env.REACT_APP_MAIL_USERNAME}`,
          Subject: `קבלה הזמנה באִיתִּי תכשיטים${order._id}`,
          Body: `
          <div style="text-align:right;">
          <h1>תודה לך על הזמנתך באִיתִּי תכשיטים</h1>
          <p>
          ,${userInfo.name} שלום </p>
          <p>.סיימנו לעבד את ההזמנה שלך</p>
          <h2> (${order.createdAt.toString().substring(0, 10)}) [${order._id} הזמנה מספר]</h2>
          <table align="right">
          <thead>
          <tr>
          <td><strong align="left">מחיר</strong></td>
          <td><strong>כמות</strong></td>
          <td><strong>מוצרים</strong></td>
          </thead>
          <tbody>
          ${order.orderItems
              .map(
                (item) => `
            <tr>
            <td align="left"> ₪${item.price.toFixed(2)}</td>
            <td align="center">${item.quantity}</td>
            <td>${item.name}</td>
            </tr>
          `
              )
              .join('\n')}
          </tbody>
          <tfoot>
          <tr>
          <td align="right"> ₪${order.itemsPrice.toFixed(2)}</td>
          <td colspan="2">:מחיר מוצרים</td>
          </tr>
          <tr>
          <td align="right"> ₪${order.shippingPrice.toFixed(2)}</td>
          <td colspan="2">:מחיר משלוח</td>
          </tr>
          <tr>
          <td align="right"><strong> ₪${order.totalPrice.toFixed(2)}</strong></td>
          <td colspan="2"><strong>:סה"כ מחיר</strong></td>
          </tr>
          <tr>
          <td align="right">PayPal / Credit Card</td>
          <td colspan="2">:סוג תשלום</td>
          </tr>
          </table>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <h2>:כתובת למשלוח</h2>
          <p>
         ,${order.shippingAddress.fullName}<br/>
          ,${order.shippingAddress.address}<br/>
          ,${order.shippingAddress.city}<br/>
          ,${order.shippingAddress.country}<br/>
         ,${order.shippingAddress.postalCode}<br/>
          </p>
          <h3 style="color:red; text-decoration:underline;"> בעת שליחת ההזמנה לכתובת המבוקשת, תקבל/י אימייל נוסף</h3>
          <hr/>
          <p>
תודה שקנית איתנו, נשמח לראותך שוב. אִיתִּי תכשיטים
          </p>
          </div>
          `
        });
        // toast.success('הודעה נשלחה בהצלחה, ניצור איתך קשר בהקדם');
      } catch (err) {
        dispatch({ type: 'PAY_FAIL', payload: getError(err) });
        toast.error(getError(err));
      }
    });
  }
  function onError(err) {
    toast.error(getError(err));
  }

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        dispatch({ type: 'FETCH_REQUEST' });
        const { data } = await axios.get(`https://et-jewelry-api.onrender.com/api/orders/${orderId}`, {
          headers: { authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (err) {
        dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
      }
    };

    if (!userInfo) {
      return navigate('/login');
    }
    if (
      !order._id ||
      successPay ||
      successDeliver ||
      (order._id && order._id !== orderId)
    ) {
      fetchOrder();
      if (successPay) {
        dispatch({ type: 'PAY_RESET' });
      }
      if (successDeliver) {
        dispatch({ type: 'DELIVER_RESET' });
      }
    } else {
      const loadPaypalScript = async () => {
        const { data: clientId } = await axios.get(`https://et-jewelry-api.onrender.com/api/keys/paypal`, {
          headers: { authorization: `Bearer ${userInfo.token}` },
        });
        paypalDispatch({
          type: 'resetOptions',
          value: {
            'client-id': clientId,
            currency: 'ILS',
          },
        });
        paypalDispatch({ type: 'setLoadingStatus', value: 'pending' });
      };
      loadPaypalScript();
    }
  }, [
    order,
    userInfo,
    orderId,
    navigate,
    paypalDispatch,
    successPay,
    successDeliver,
  ]);
  async function deliverOrderHandler() {
    try {
      dispatch({ type: 'DELIVER_REQUEST' });
      const { data } = await axios.put(
        `https://et-jewelry-api.onrender.com/api/orders/${order._id}/deliver`,
        {},
        {
          headers: { authorization: `Bearer ${userInfo.token}` },
        }
      );
      dispatch({ type: 'DELIVER_SUCCESS', payload: data });
      toast.success('הזמנה עודכן כנשלחה בהצלחה');
      window.Email.send({
        Host: "smtp.elasticemail.com",
        Username: `${process.env.REACT_APP_MAIL_USERNAME}`,
        Password: `${process.env.REACT_APP_MAIL_PASSWORD}`,
        To: `${data.data.email}`,
        From: `${process.env.REACT_APP_MAIL_USERNAME}`,
        Subject: `הזמנה מאִיתִּי תכשיטים נשלחה לכתובת`,
        Body: `
        <div style="text-align:right;">
        <h1>הזמנה מאִיתִּי תכשיטים</h1>
        <p>
        ,${data.data.name} שלום </p>
        <p>.ההזמנה שלך נשלחה לכתובת המבוקשת בהצלחה</p>
        <p>.משלוח יגיע לתיבת הדואר או בדואר רשום עד 14 ימי עסקים</p>
        <h2>:כתובת למשלוח</h2>
        <p>
       ,${order.shippingAddress.fullName}<br/>
        ,${order.shippingAddress.address}<br/>
        ,${order.shippingAddress.city}<br/>
        ,${order.shippingAddress.country}<br/>
       ,${order.shippingAddress.postalCode}<br/>
        </p>
        <h2> (${order.createdAt.toString().substring(0, 10)}) [${order._id} הזמנה מספר]</h2>
        <table align="right">
        <thead>
        <tr>
        <td><strong align="left">מחיר</strong></td>
        <td><strong>כמות</strong></td>
        <td><strong>מוצרים</strong></td>
        </thead>
        <tbody>
        ${order.orderItems
            .map(
              (item) => `
          <tr>
          <td align="left"> ₪${item.price.toFixed(2)}</td>
          <td align="center">${item.quantity}</td>
          <td>${item.name}</td>
          </tr>
        `
            )
            .join('\n')}
        </tbody>
        <tfoot>
        <tr>
        <td align="right"> ₪${order.itemsPrice.toFixed(2)}</td>
        <td colspan="2">:מחיר מוצרים</td>
        </tr>
        <tr>
        <td align="right"> ₪${order.shippingPrice.toFixed(2)}</td>
        <td colspan="2">:מחיר משלוח</td>
        </tr>
        <tr>
        <td align="right"><strong> ₪${order.totalPrice.toFixed(2)}</strong></td>
        <td colspan="2"><strong>:סה"כ מחיר</strong></td>
        </tr>
        <tr>
        <td align="right">PayPal / Credit Card</td>
        <td colspan="2">:סוג תשלום</td>
        </tr>
        </table>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <hr/>
        <p>
        .תודה שקנית איתנו, נשמח לראותך שוב אִיתִּי תכשיטים
        </p>
        </div>
        `
      });
    } catch (err) {
      toast.error(getError(err));
      dispatch({ type: 'DELIVER_FAIL' });
    }
  }

  return loading ? (
    <LoadingBox></LoadingBox>
  ) : error ? (
    <MessageBox variant="danger">{error}</MessageBox>
  ) : (

    <div>
      <Helmet>
        <title>{orderId}:הזמנה</title>
      </Helmet>
      <h1 className="my-3">{orderId} :הזמנה מספר</h1>
      <Row>
        <Col md={8}>
          <Card className="mdb-card order-details mb-3">
            <Card.Body>
              <Card.Title>פרטי משלוח</Card.Title>
              <Card.Text Style="color:white">
               {order.shippingAddress.fullName} <strong>:שם מלא </strong> <br />
              {order.shippingAddress.postalCode},&nbsp;{order.shippingAddress.city},&nbsp;{order.shippingAddress.country},&nbsp;{order.shippingAddress.address}&nbsp; <strong Style="float:right"> :כתובת למשלוח </strong>
              {/* <strong>שם מלא: </strong>{order.shippingAddress.fullName} <br />
                {order.paymentMethod === '0' && `כתובת לאיסוף: יהודה הלוי 79 תל אביב`}
                {order.paymentMethod === '30' && `${order.shippingAddress.address}, ${order.shippingAddress.city}, ${order.shippingAddress.postalCode}, ${order.shippingAddress.country} :כתובת למשלוח`} */}
              </Card.Text>
              {order.isDelivered ? (
                <MessageBox variant="success">
                  {order.deliveredAt} :נשלח בתאריך
                </MessageBox>
              ) : (
                <MessageBox variant="danger">לא נשלח עדיין</MessageBox>
              )}
            </Card.Body>
          </Card>
          <Card className="mdb-card order-details mb-3">
            <Card.Body>
              <Card.Title>בחר סוג תשלום</Card.Title>
              <Form id="finalPaymentForm" Style="color:white">
                {/* {!userInfo.isAdmin && order.paymentMethod === '0' &&
                  <Form.Check
                    type="radio"
                    id="normalShipping"
                    label="מזומן"
                    name="payment"
                    value="0"
                    onClick={togglePaymentTrue}
                    onChange={(e) => setPaymentMethod(e.target.value)}
                  />

                } */}
                {!userInfo.isAdmin && order.paymentMethod === '0' &&
                  <Form.Check
                    selected={true}
                    type="radio"
                    id="normalShipping"
                    name="payment"
                    label="PayPal / כרטיס אשראי"
                    value="30"                
                    onClick={togglePaymentFalse}
                    onChange={(e) => setPaymentMethod(e.target.value)}
                  />

                }
                {/* <option type="radio" value='PayPal / כרטיס אשראי'> PayPal / כרטיס אשראי</option> */}
                {order.paymentMethod === '30' &&


                  <p >PayPal / כרטיס אשראי</p>
                }
              </Form>

              {order.isPaid ? (
                <MessageBox variant="success">
                  {order.paidAt} :שולם בתאריך
                </MessageBox>
              ) : (
                <MessageBox variant="danger">לא שולם</MessageBox>
              )}
            </Card.Body>
          </Card>

          <Card className="mdb-card order-details mb-3">
            <Card.Body>
              <Card.Title>מוצרים</Card.Title>
              <ListGroup variant="flush">
                {order.orderItems.map((item) => (
                  <ListGroup.Item key={item._id} Style="background-color: #181a1b;color: white;">
                    <Row className="align-items-center">
                      <Col md={4}>
                        <img
                          src={item.image}
                          alt={item.name}
                          className="img-fluid rounded img-thumbnail"
                        ></img>{' '}
                        <Link to={`/product/${item.slug}`} Style="color:white">{item.name}</Link>
                      </Col>
                      <Col md={3}>
                        <span>{item.quantity}</span>
                      </Col>
                      <Col md={3}>₪{item.price}</Col>
                    </Row>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="mdb-card order-details mb-3">
            <Card.Body>
              <Card.Title>סיכום הזמנה</Card.Title>
              <ListGroup variant="flush">
                <ListGroup.Item  Style="background-color: #181a1b;color: white;">
                  <Row>
                    <Col>₪{order.itemsPrice.toFixed(2)}</Col>
                    <Col>מוצרים</Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item  Style="background-color: #181a1b;color: white;">
                  <Row>
                    <Col>₪{order.paymentMethod === '30' ? '30' : '0'}</Col>
                    <Col>מחיר משלוח</Col>
                  </Row>
                </ListGroup.Item>
                {/* <ListGroup.Item>
                  <Row>
                    <Col>₪{order.taxPrice.toFixed(2)}</Col>
                    <Col>מיסים</Col>
                  </Row>
                </ListGroup.Item> */}
                <ListGroup.Item  Style="background-color: #181a1b;color: white;">
                  <Row>
                    <Col>
                      <strong>₪{order.totalPrice.toFixed(2)}</strong>
                    </Col>
                    <Col>
                      <strong> סה"כ הזמנה</strong>
                    </Col>
                  </Row>
                </ListGroup.Item>
                {!order.isPaid && (
                  <ListGroup.Item  Style="background-color: #181a1b;color: white;">
                    {isPending ? (
                      <LoadingBox />
                    ) : !userInfo.isAdmin && paymentCash === false && (
                      <div>
                        <PayPalButtons
                          createOrder={createOrder}
                          onApprove={onApprove}
                          onError={onError}
                        ></PayPalButtons>
                      </div>
                    )}
                    {loadingPay && <LoadingBox></LoadingBox>}
                  </ListGroup.Item>
                )}
                {userInfo.isAdmin && order.isPaid && !order.isDelivered && (
                  <ListGroup.Item  Style="background-color: #181a1b;color: white;">
                    {loadingDeliver && <LoadingBox></LoadingBox>}
                    <div className="d-grid">
                      <Button type="button" onClick={deliverOrderHandler}>
                        שלח הזמנה
                      </Button>
                    </div>
                  </ListGroup.Item>
                )}
                {!userInfo.isAdmin && paymentCash === true && (

                  <Button type="button" onClick={takeAway}>
                    שלח הזמנה
                  </Button>

                )}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div >
  );
}
