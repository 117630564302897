import React, { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import { Store } from '../Store';



export default function TermsOfServiceScreen() {

    const { state } = useContext(Store);
    const { userInfo } = state;

    return (
        <div>
            <Helmet>
                <title>מדיניות פרטיות באתר</title>
            </Helmet>
            {!userInfo && <Link to="/signup" Style="color:white;">חזור לעמוד הרשמה&nbsp;<i className="fas fa-arrow-right" Style="color:white"></i></Link>}
            {userInfo && <Link to="/" Style="color:white;">חזור לעמוד הבית&nbsp;<i className="fas fa-arrow-right" Style="color:white"></i></Link>}
            <br></br>
            <br></br>
            {/* <ol Style='text-align:right;direction: rtl;'>
                <li> אתר https://et-jewelery.com ( להלן - "האתר" (המצוי בבעלות איתי עדיני )להלן - "החברה"
                    ( אִיתִּי תכשיטים מציע לגולשים ברשת האינטרנט באמצעות מחשב ו/או כל מדיה אחרת, בין באופן שוטף ובין באופן אקראי, לרבות משתמש מזדמן )להלן-
                    "המשתמשים" ( מידע בתחום עיסוקיהן של החברות השונות בקבוצה והפתרונות המשווקים על ידן. כלל המידע באתר )להלן - "המידע" ( מצוי
                    בחלקו בבעלות החברה ובחלקו בבעלות צדדים שלישיים אשר נתנו לחברה הרשאה לעשות בו שימוש ) להלן - "ספקי המידע" ( השימוש באתר
                    כפוף ומותנה בהסכמת המשתמשים לאמור בתנאי שימוש אלה.) השימוש באתר מעיד על הסכמת המשתמש לתנאים אלה. תנאי השימוש
                    באתר מנוסחים בלשון זכר מטעמי נוחות בלבד והם מתייחסים לבני שני המינים באופן שווה .
                </li><br></br>

                <li>החברה שומרת לעצמה את הזכות, על פי שיקול דעתה הבלעדי וללא הודעה מראש, להפסיק את פעילות האתר בכל עת ו/או למנוע את
                    הגישה לאתר ו/או למנוע את ביצוען של פעולות מסוימות באתר.
                    כותרות הסעיפים בתנאים אלו נועדו לשם נוחות והתמצאות בלבד ואין בהן כדי לשמש לשם פרשנות.
                </li><br></br>

                <li>תנאים אלו נית נים לשינוי ו/או עדכון בכל עת ע"י החברה ו/או מי מטעמה, על פי שיקול דעתה הבלעדי.</li><br></br>
                <li>תנאי שימוש אלה יחולו על כל פעולה שתבוצע באתר על ידי "המשתמש", כהגדרתו להלן ויהוו חוזה התקשרות מחייב בין המשתמש ובין
                    החברה, לכל דבר ועניין. מובהר כי גלישה באתר ו/או ביצוע פעולות באתר, מהוות הסכמת המשתמש לקבל ולנהוג על פי תנאי השימוש . אם
                    אינך מסכים לתנאי מתנאי השימוש, הנך מתבקש לא לעשות כל שימוש באתר. ויודגש, כל המבצע פעולה באתר מצהיר כי הינו מודע לתנאי
                    השימוש האתר ומקבלם, וכי לא תהא לו ו/או למי מטעמו כל טענה ו/או תביעה כנגד בעלי האתר ו/או מפעיליו ו/או מי מטעמם מלבד טענות
                    הקשורות בהפרת התחייבויות בעלי האתר ו/או מפעיליו על פי תנאי שימוש אלו.</li><br></br>
                <li>לצורך תנאי שימוש אלו, "משתמש" הינו - כל אדם, לרבות חברה, רשאי להשתמש באתר, לרבות לבצע פעולות באמצעות האתר, ובלבד
                    שהמשתמש הנו כשיר לבצע פעולות משפטיות מחייבות; במידה והנך קטין )מתחת לגיל 18 )או אינך זכאי לבצע פעולות משפטיות ללא אישור
                    אפוטרופוס, הנך נדרש ליידע את הוריך ו/או את האפוטרופוסים החוקיים שלך )להלן: "ההורים"( בדבר תנאי שימוש אלו ולקבל את אישורם
                    לביצוע כל פעילות, מכל סוג שהוא, במסגרת האתר. על ההורים חלה אחריות ליידע את הקטינים המעוניינים ל גלוש באתר בדבר תנאי שימוש
                    אלו וכן לפקח על פעילות הקטינים בכדי שהקטינים יפעלו על פי תנאי תקנון זה. כל פעילות אשר תבוצע על ידי הקטינים באתר מהווה הסכמה
                    של הקטינים ו/או ההורים לאמור בתנאי שימוש אלו.
                    המוצרים / השירותים המוצעים באתר</li><br></br>
                <li>האתר מציע מגוון תכנים בהתייחס למידע ואולם החברה אינה מתחייבת כי כל התכנים ו/או השירותים הללו יינתנו באופן רציף ושוטף באתר
                    והיא רשאית לשנות את רשימת התכנים ו/או השירותים הניתנים באתר לפי שיקול דעתה הבלעדי ומבלי ליתן על כך הודעה מוקדמת כלשהי.
                    שירות לקוחות</li><br></br>
                <li>לפרטים ובירורים באשר לשירותים, למוצרים ו/או לאספקתם ניתן לפנות אל מחלקת שירות הלקוחות של החברה באמצעות הטופס המצוי
                    באתר תחת "צור קשר".
                    תנאי השימוש במידע</li><br></br>
                <li>מובהר בזאת, כי זכות השימוש במידע נתונה לך בלבד, למטרת שימוש אישי, פרטי ולא מסחרי, וכי אינך רשאי להרשות שימוש כלשהו,
                    במידע, בין בתמורה ובין שלא בתמורה, לצד ג' כלשהו.</li><br></br>
                <li>הינך מתחייב להשתמש במידע ובשירותים המוצעים באתר בהתאם לדרישות כל דין ובכפוף להנחיות ולהוראות באתר. הינך מתחייב שלא
                    לפגוע בחברה או במשתמשים אחרים של האתר או ברשת האינטרנט בכלל, ובכלל זה הינך מתחייב להימנע מכל פגיעה ביכולתם ליהנות
                    מהתכנים הניתנים באתר. איסור זה כולל גם משלוח דואר זבל, מכתבי שרשרת, משחקי פירמידה או כל נסיון למסור מידע או להציע שירותים
                    שהמשתמשים או החברה אינם מעוניינים בהם. הינך מתחייב להימנע לחלוטין מנסיון לאיסוף מידע על האתר באמצעות תוכנות מחשב וכן
                    להימנע מע ריכת שינויים בלתי מורשים בקוד התוכנה של האתר.</li><br></br>
                <li>אין לפרסם ברבים את המידע ו/או כל חלק ממנו, אלא בתנאים כמפורט באתר, ואין לפרסם ברבים כל מוצר ו/או פלט של המידע, בין שהוא
                    מודפס כשרטוט או כדו"ח ובין שהוא ניתן כקובץ על גבי אמצעים מגנטיים או בכל צורה אחרת ואין לשכפל, לצלם, להעתיק ו/או להדפיס כל
                    מוצר ו/או פלט כאמור לעיל מתוך המידע או כל חלק ממנו לצורך הפצה או פרסום בכל דרך שהיא. כן, אין לאפשר, בין במישרין ובין בעקיפין,
                    בין בתמורה ובין ללא תמורה, את ביצוע אחת או יותר מהפעולות המפורטות לעיל.</li><br></br>
                <li> .כל העתקה, הפצה, משלוח, שידור או פרסום של מידע המאוחסן באתר, שלא הותר במפורש, אסור, אלא אם נתנה לכך הסכמת החברה
                    מראש ובכתב.</li><br></br>
                <li>בנוסף לכל האמור לעיל, מובהר בזאת כי ב שימושך באתר הינך מתחייב כי המידע לא ישמש למטרות רווח או למטרות מסחריות אחרות ולא
                    יאוחסן באתרים אחרים ברשת האינטרנט.</li><br></br>
                <li>המשתמש מתחייב בזאת שלא לבצע שינויים במידע, לרבות שיכתוב, עריכה, עיבוד, תקצור, תרגום, העתקה, איחסון, פרסום, הגבלת או
                    הסרת פרסום של המידע או חלק ממנו, ברשת האינטרנט ו/או בכל מדיה אחרת, קיימת ו/או עתידית, וכן שלא להעניק רשיונות משניים, שלא
                    ליצור עבודות נגזרות, ושלא לבצע עסקאות ו/או התקשרויות עם צדדים שלישיים, בתמורה או שלא בתמורה, במידע או חלק ממנו.</li><br></br>
                <li>הינך מתחייב לשמור בהקפדה על המידע ולנקוט בכל אמצעי הזהירות הנדרשים לשם מניעת אובדנו ו/או הגעתו לידי אחר.
                    אחריות, אבטחה ופרטיות</li><br></br>
                <li>החברה ו/או מי מטעמה נוקטים באמצעי זהירות סבירים ומקובלים על מנת לשמור, ככל האפשר על סודיות המידע ולמנוע כניסה לא מורשית
                    לאתר. פרטי ההתקשרות של משתמש )שם ודואר אלקטרוני( אשר נרשם לאתר ומסר מרצונו פרטים אלו, יישמרו במערכות חברת החברה
                    לצורך דיוור ישיר. להסרה, יש לפנות בכתב לשירות הלקוחות של החברה ב אמצעות הטופס המצוי באתר תחת "צור קשר". ביצוע ההסרה
                    עשוי להימשך עד 14 ימי עבודה מיום קבלת הפנייה.</li><br></br>
                <li>מובהר כי מאחר ומדובר בביצוע פעולות בסביבה מקוונת, אין באפשרותה של החברה להבטיח חסינות מוחלטת מפני חדירות למחשביה או
                    חשיפת המידע האגור בידי מבצעי פעולות בלתי ח וקיות ועל כן, אם יעלה בידי צד שלישי לחדור למידע אשר שמור בידי החברה ו/או להשתמש
                    בו לרעה, לא תהיה למשתמש כל טענה, תביעה או דרישה כלפי החברה. כמו כן, במקרים הנובעים מכוח עליון, החברה לא תהיה אחראית לכל
                    נזק מכל סוג שהוא, עקיף או ישיר שייגרם למשתמש או למי מטעמו, אם מידע כלשהו שמסר יאבד או יגיע לגורם עוין ו/או יעשה בו שימוש שלא
                    בהרשאה.</li><br></br>
                <li>החברה מתחייבת כי לא תעשה שימוש בפרטי המשתמשים הרשומים באתר אלא לצרכי תפעול האתר בלבד וכן לצורך שמירת קשר עם
                    המשתמש.</li><br></br>
                <li>למרות האמור לעיל, החברה תהא רשאית להעביר פרטיו האישיים של משתמש לצד שלישי במקרים בהם המשתמש ביצע מעשה או מחדל
                    הפוגעים ו/או העלולים לפגוע בחברה ו/או בצדדים שלישיים כלשהם, המשתמש עשה שימוש בשירותי החברה לביצוע מעשה בלתי חוקי, אם
                    התקבל בידי החברה צו שיפוטי המורה לה למסור את פרטי המשתמש לצד שלישי וכן בכל מחלוקת או הליך משפטי. כמו כן, החברה תוכל
                    להשתמש בפרטיו האישיים של המשתמש, ללא זיהוי המשתמש הספציפי, לצורך ניתוח מידע סטטיסטי והצגתו ו/או מסירתו לגורמים אחרים.
                    אחריות מוגבלת</li><br></br>
                <li>האתר והמידע המפורט בו מ וצע ים למשתמשים is as והחברה אינה מתחייבת ולא תישא בכל אחריות לנכונות המידע , שלמותו, מידת דיוקו
                    ו/או להתאמתו לצרכיך ו/או למטרותיך. באחריותך לבצע בדיקות נוספות ולקבל יעוץ מקצועי מתאים, במידה שהנסיבות מצריכות זאת.
                    <li>ספקי המידע והחברה אינם אחראים לזמינות המידע, לתוכן, לצורה, לשלמות, לעדכניות, לחוקיות ולדיוק הנתונים, להתאמתם למשתמש</li><br></br>
                    בטיבם, אופיים, והיקפם, לליקוי בדרך העברתו של המידע , לתוכנו של המידע, לאי התאמת הנתונים שהתקבלו במחשב החברה לנתונים
                    המתקבלים במחשב המשתמש, לאובדן, הפסד או נזק ישיר, עקיף תוצאתי או אחר, ש יגרמו למשתמש או לצד ג' כלשהו. המשתמש פוטר בזה
                    את ספקי המידע והחברה מכל נזק כאמור גם אם האחריות בגינו חלה עליהם על פי כל דין ובכלל זה דיני החוזים או דיני הנזיקין, לרבות עוולת
                    הרשלנות.</li><br></br>
                <li>החברה אינה אחראית לכל שימוש שהינך עושה, בין במישרין בין בעקיפין, במידע וב שירותים המוצעים באתר ו/או להסתמכותך עליהם והינך
                    אחראי באופן בלעדי ומלא לכל שימוש שהינך עושה או תעשה בהם ו/או להסתמכותך עליהם.</li><br></br>
                <li>מחיר המוצרים המחייב ותנאי רכישתם הם אלו שיפורטו בהצעת מחיר פורמלית שתעביר החברה למשתמש לפי בקשתו. בכל מקרה של
                    סתירה בין האמור באתר לבין האמור בהצעת המחיר של החברה ו/או במסמך רשמי של החברה, יגבר האמור במסמך הרשמי הכתוב של
                    החברה.</li><br></br>
                <li>החברה אינה מתחייבת, כי השימוש במידע באתר לא יופרע, יינתן כסדרו, בלא הפסקות, יתקיים בבטחה ללא טעויות או יהיה חסין מפני נזקים,
                    קלקולים, תקלות או כשלים.</li><br></br>
                <li>החברה וס פקי המידע פועלים במידת האפשר על מנת שהמידע יהיה נכון ומדויק ככל שניתן, אולם על אף האמור ייתכן ויחול ליקוי ו/או טעות
                    ו/או אי דיוק במידע ו/או בדרך העברתו אליך.</li><br></br>
                <li>החברה וספקי המידע, לרבות מנהליהם, עובדיהם וכל מי מטעמם, לא יהיו אחראים בגין כל נזק, ישיר או עקיף, שיגרם לך ו/או לכל צד שלישי
                    בגין השימוש במידע ו/או הסתמכות עליו ו/או בגין ביטול ו/או הפסקת איזה מהשירותים המוצעים באתר לרבות בגין שיבושים ו/או ליקויים
                    בהעברת המידע בין אם נגרמו במזיד על ידי צד ג' כלשהו ובין אם נגרמו בשל תקלה כלשהי.
                    קנין רוחני</li><br></br>
                <li>כל זכויות הקניין הרוחני בכל התכנים באתר, לרבות סימני המסחר, זכויות הפטנטים , זכויות היוצרים, המדגמים, השיטות , הסודות המסחריים,
                    זכויות הבעלות במידע לרבות זכויות ההפצה וכל קנין רוחני אחר במידע הינם רכושה של החברה בלבד. זכויות אלה חלות, בין היתר, על
                    עיצובו הגרפי של האתר, בס יסי הנתונים בו )לרבות רשימות המוצרים, תיאור המוצרים וכד'(, קוד המחשב של האתר וכל פרט אחר הקשור
                    בהפעלתו. למשתמש לא תהיה כל זכות מכל סוג שהוא במידע.</li><br></br>
                <li>אין לעשות כל שימוש מסחרי בנתונים המתפרסמים באתר, בבסיס הנתונים באתר, ברשימות ובתמונות המוצרים המופיעים בו או בפרטים
                    אחרים המתפרסמים על ידי ו/או מטעם החברה בלא קבלת הסכמת החברה מראש ובכתב. אין להשתמש בנתונים כלשהם המתפרסמים
                    באתר לצורך הצגתם באתר אינטרנט או בשירות אחר כלשהו בלא לקבל את הסכמת החברה מראש ובכתב ובכפוף לתנאי אותה הסכמה )אם
                    וככל תינתן(. בכלל זה נאסר לאס וף נתונים מן האתר באמצעות תוכנות ו/או להפיץ נתונים כאלה ברבים באופן מסחרי או במסגרת מסחרית.</li><br></br>
                <li>אין להעתיק, לשכפל, להפיץ, למכור, לשווק ולתרגם מידע כלשהו מן האתר )לרבות סימני מסחר, תמונות, טקסטים וקוד-מחשב בלא קבלת
                    רשותה המפורשת של החברה מראש ובכתב. אין להציג את האתר בתוך מסגרת )Frame ,)גלויה או סמויה ואין לקשר לעמודים המצויים
                    בתוכו )"קישור עומק"(, אלא לעמוד הבית בלבד. אין להציג את האתר בעיצוב או ממשק גרפי שונים מאלה שעיצבה לו החברה, אלא בכפוף
                    לקבלת הסכמתה לכך מראש ובכתב.</li><br></br>
                <li>השם איתי עדיני , סימני המסחר  )בין אם נרשמו ובין אם לאו(, וכיו"ב - הם
                    כולם רכושה של החברה בלבד. אין לעשות בהם שימוש בלא קבלת הסכמת החברה מראש ובכתב.</li><br></br>
                <li>הינך מתחייב בזאת שלא לפרסם ברבים את המידע ו/או כל חלק ממנו, אלא בתנאים, כמפורט באתר. כן, הינך מתחייב, כי לא תפרסם ברבים
                    כל מוצר ו/או פלט של המידע, בין שהוא מודפס כשרטוט או כדו"ח ובין שהוא ניתן כקובץ על גבי אמצעים מגנטיים או בכל צורה אחרת וכי לא
                    תשכפל, לא תצלם, לא תעתיק ולא תדפיס כל מוצר ו/או פלט כאמור לעיל מתוך המידע או כל חלק ממנו לצורך הפצה או פרס ום בכל דרך
                    שהיא וכן לא תאפשר, בין במישרין ובין בעקיפין, בין בתמורה ובין ללא תמורה, את ביצוע אחת או יותר מהפעולות המפורטות לעיל.
                    זכויות שונות של החברה</li><br></br>
                <li>החברה רשאית לכלול באתר תכנים מסחריים, לרבות פרסומות, באנרים, מודעות, מודעות קופצות, קבצים דיגיטליים, קישורים ותוצאות
                    חיפוש. החברה רשאית לבצע שינויים באתר, ובכלל זה להפסיק מתן שירותים, להוריד מהאתר פרסומים שונים ולמחוק פרסומים ללא שמירת
                    גיבויים, וזאת על פי שיקול דעתה וללא צורך בקבלת הודעה מוקדמת או הסכמה מהמשתמש.
                    סיום ההתקשרות בין הצדדים</li><br></br>
                <li>החברה רשאית להפסיק את פעילות האתר באופן זמני ו/או באופן קבוע בכל עת ועל פי שיקול דעתה הבלעדי.
                    תנאים נוספים</li><br></br>
                <li>החברה שומרת על זכותה לשנות את הוראות וכללי השימוש באתר מעת לעת, בהתאם לשיקול דעתה הבלעדי.
                    דין ושיפוט</li><br></br>
                <li>הדין החל על תנאי שימוש אלה, פירוש ם, תוקפ ם, תחולתם ואכיפתם ו/או על כל פעולה ו/או על סכסוך הנובע ים מהם, הוא הדין של מדינת
                    ישראל בלבד. כל מחלוקת בין הצדדים לתקנון זה לרבות בנוגע לביצועו, תחולתו, פירושו או תוקפו – תתברר בבית המשפט בעל הסמכות
                    העניינית במחוז תל אביב-יפו.
                </li><br></br>

            </ol> */}
            <div Style="text-align:left;">
                <h1 Style="margin-top: 10px;">Privacy Policy</h1>
                <p>Updated at 2022-11-05</p>
                <p Style="display: inline;"> אִיתִּי תכשיטים</p>
                <p className="p4" Style="display: inline;"><span className="s1">("we," "our," or "us") is
                    committed to
                    protecting your privacy. This Privacy Policy explains how your personal
                    information is
                    collected, used, and disclosed by</span></p>
                <br></br>
                <p Style="display: inline;"> אִיתִּי תכשיטים.</p>
                <div Style="display: inline;">
                    <p Style="display: inline;"><span className="s1">This Privacy Policy applies to our
                        website,</span></p>
                    <p Style="display: inline;"><span className="s1">
                        https://et-jewelry.onrender.com</span></p>
                    <p Style="display: inline;"><span className="s1">, and its associated subdomains
                        (collectively, our
                        "Service").</span></p>
                </div>
                <p Style="display: inline;"><span className="s1">By accessing or using our Service, you
                    signify that you
                    have read, understood, and agree to our collection, storage, use, and
                    disclosure of your
                    personal information as described in this Privacy Policy and our Terms
                    of Service.</span></p>
                <p className="p6"></p>
                <h1>Definitions and key terms</h1>
                <p className="p8"><span className="s1">To help explain things as clearly as possible in this
                    Privacy Policy,
                    every time any of these terms are referenced, are strictly defined
                    as:</span></p>
                <p className="p9"></p>
                <ul className="ul1">
                    <li className="li8"><span className="s1"><strong>Cookie:</strong> small amount of data
                        generated by a
                        website and saved by your web browser. It is used to identify
                        your browser, provide
                        analytics, remember information about you such as your language
                        preference or login
                        information.</span></li>

                    <li className="li8"><span className="s1"><strong>Company:</strong> when this policy
                        mentions “Company,”
                        “we,” “us,” or “our,” it refers to <a>אִיתִּי תכשיטים</a> that
                        is responsible for your
                        information under this Privacy Policy.</span></li>
                    <li className="li8"><span className="s1"><strong>Country:</strong> where <a>אִיתִּי
                        תכשיטים</a> or the
                        owners/founders of <a>אִיתִּי תכשיטים</a> are based, in this
                        case is
                        <a>Israel</a>.</span></li>
                    <li className="li8"><span className="s1"><strong>Customer:</strong> refers to the
                        company, organization or
                        person that signs up to use the <a>אִיתִּי תכשיטים</a> Service
                        to manage the relationships
                        with your consumers or service users.</span></li>
                    <li className="li8"><span className="s1"><strong>Device:</strong> any internet connected
                        device such as a
                        phone, tablet, computer or any other device that can be used to
                        visit <a>אִיתִּי תכשיטים</a>
                        and use the services.</span></li>
                    <li className="li8"><span className="s1"><strong>IP address:</strong> Every device
                        connected to the Internet
                        is assigned a number known as an Internet protocol (IP) address.
                        These numbers are usually
                        assigned in geographic blocks. An IP address can often be used
                        to identify the location from
                        which a device is connecting to the Internet.</span></li>
                    <li className="li8"><span className="s1"><strong>Personnel:</strong> refers to those
                        individuals who are
                        employed by <a>אִיתִּי תכשיטים</a> or are under contract to
                        perform a service on behalf of
                        one of the parties.</span></li>
                    <li className="li8"><span className="s1"><strong>Personal Data:</strong> any information
                        that directly,
                        indirectly, or in connection with other information — including
                        a personal identification
                        number — allows for the identification or identifiability of a
                        natural person.</span>
                    </li>
                    <li className="li8"><span className="s1"><strong>Service:</strong> refers to the service
                        provided by
                        <a>אִיתִּי תכשיטים</a> as described in the relative terms (if
                        available) and on this
                        platform.</span></li>
                    <li className="li8"><span className="s1"><strong>Third-party service:</strong> refers to
                        advertisers,
                        contest sponsors, promotional and marketing partners, and others
                        who provide our content or
                        whose products or services we think may interest you.</span>
                    </li>
                    <li className="li8"><span className="s1"><strong>Website:</strong> <a>אִיתִּי
                        תכשיטים</a>’s site, which can
                        be accessed via this URL:
                        <a>https://et-jewelry.onrender.com.</a></span></li>
                    <li className="li8"><span className="s1"><strong>You:</strong> a person or entity that
                        is registered with
                        <a>אִיתִּי תכשיטים</a> to use the Services.</span></li>
                </ul>
                <p className="p9"></p>
                <div>
                    <h1>What Information Do We Collect?</h1>
                    <p className="p10"><span className="s1">We collect information from you when you visit
                        our service,
                        register, place an order, subscribe to our newsletter, respond
                        to a survey or fill out a
                        form.</span></p>
                    <ul className="ul1">
                        <li className="li11"><span className="s3">Name / Username</span></li>
                        <li className="li11"><span className="s3">Phone Numbers</span></li>
                        <li className="li11"><span className="s3">Email Addresses</span></li>
                        <li className="li11"><span className="s3">Billing Addresses</span></li>
                        <li className="li11"><span className="s3">Internet protocol (IP) address</span></li>
                    </ul>
                </div>
                <p className="p12"></p>


                <p className="p3"></p>
                <h1>How Do We Use The Information We Collect?</h1>
                <p className="p10"><span className="s1">Any of the information we collect from you may be used
                    in one of the
                    following ways:</span></p>
                <ul className="ul1">
                    <li className="li11"><span className="s3">To personalize your experience (your
                        information helps us to
                        better respond to your individual needs)</span></li>
                    <li className="li11"><span className="s3">To improve our service (we continually strive
                        to improve our
                        service offerings based on the information and feedback we
                        receive from you)</span></li>
                    <li className="li11"><span className="s3">To improve customer service (your information
                        helps us to more
                        effectively respond to your customer service requests and
                        support needs)</span></li>
                    <li className="li11"><span className="s3">To process transactions</span></li>
                    <li className="li11"><span className="s3">To administer a contest, promotion, survey or
                        other site
                        feature</span></li>
                    <li className="li11"><span className="s3">To send periodic emails</span></li>
                </ul>
                <p className="p3"></p>
                <h1>When do we use customer information from third parties?</h1>
                <p className="p10"><span className="s1">We receive some information from the third parties when
                    you contact us.
                    For example, when you submit your email address to us to show interest
                    in becoming our customer,
                    we receive information from a third party that provides automated fraud
                    detection services to
                    us. We also occasionally collect information that is made publicly
                    available on social media
                    websites. You can control how much of your information social media
                    websites make public by
                    visiting these websites and changing your privacy settings.</span></p>
                <p className="p3"></p>
                <h1>Do we share the information we collect with third parties?</h1>
                <p className="p10"><span className="s1">We may share the information that we collect, both
                    personal and
                    non-personal, with third parties such as advertisers, contest sponsors,
                    promotional and
                    marketing partners, and others who provide our content or whose products
                    or services we think
                    may interest you. We may also share it with our current and future
                    affiliated companies and
                    business partners, and if we are involved in a merger, asset sale or
                    other business
                    reorganization, we may also share or transfer your personal and
                    non-personal information to our
                    successors-in-interest.

                    We may engage trusted third party service providers to perform functions
                    and provide services to
                    us, such as hosting and maintaining our servers and our service,
                    database storage and
                    management, e-mail management, storage marketing, credit card
                    processing, customer service and
                    fulfilling orders for products and services you may purchase through our
                    service. We will likely
                    share your personal information, and possibly some non-personal
                    information, with these third
                    parties to enable them to perform these services for us and for you.

                    We may share portions of our log file data, including IP addresses, for
                    analytics purposes with
                    third parties such as web analytics partners, application developers,
                    and ad networks. If your
                    IP address is shared, it may be used to estimate general location and
                    other technographics such
                    as connection speed, whether you have visited the service in a shared
                    location, and type of
                    device used to visit the service. They may aggregate information about
                    our advertising and what
                    you see on the service and then provide auditing, research and reporting
                    for us and our
                    advertisers.

                    We may also disclose personal and non-personal information about you to
                    government or law
                    enforcement officials or private parties as we, in our sole discretion,
                    believe necessary or
                    appropriate in order to respond to claims, legal process (including
                    subpoenas), to protect our
                    rights and interests or those of a third party, the safety of the public
                    or any person, to
                    prevent or stop any illegal, unethical, or legally actionable activity,
                    or to otherwise comply
                    with applicable court orders, laws, rules and regulations.
                </span></p>
                <p className="p3"></p>
                <h1>Where and when is information collected from customers and end users?</h1>
                <p className="p10"><span className="s1">
                    When you visit the Site, we may automatically collect certain information about you, such as estimated geo-location, and Internet Protocol Address for secuirty purposes.
                    We will collect personal information that you submit to
                    us. We may also
                    receive personal information about you from you via submitting an order, adding address information and third parties as described
                    above.

                </span></p>
                <p className="p3"></p>
                <h1>How Do We Use Your Email Address?</h1>
                <p className="p10"><span className="s1">By submitting your email address on our service, you
                    agree to receive
                    emails from us.  We only send emails to people who have authorized us to contact
                    them, either directly, or
                    through a third party. We will send an email receipt when an order is paid, and another email when the order has bee shipped to the destination.
                     We do not send unsolicited commercial emails,
                    because we hate spam as
                    much as you do. By submitting your email address, you also agree to
                    allow us to use your email
                    address for customer audience targeting on sites like Facebook, where we
                    display custom
                    advertising to specific people who have opted-in to receive
                    communications from us. Email
                    addresses submitted only through the order processing page will be used
                    for the sole purpose of
                    sending you information and updates pertaining to your order. If,
                    however, you have provided the
                    same email to us through another method, we may use it for any of the
                    purposes stated in this
                    Policy. Note: If at any time you would like to unsubscribe from
                    receiving future emails, we
                    include detailed unsubscribe instructions at the bottom of each
                    email.</span></p>
                <p className="p3"></p>
                <h1>Could my information be transferred to other countries?</h1>
                <p className="p10"><span className="s1">We are incorporated in Israel. Information collected via
                    our website,
                    through direct interactions with you, or from use of our help services
                    may be transferred from
                    time to time to our offices or personnel, or to third parties, located
                    throughout the world, and
                    may be viewed and hosted anywhere in the world, including countries
                    that may not have laws of general applicability regulating the use and
                    transfer of such data. To
                    the fullest extent allowed by applicable law, by using any of the above,
                    you voluntarily consent
                    to the trans- border transfer and hosting of such information.</span>
                </p>
                <p className="p3"></p>
                <h1>Is the information collected through our service secure?</h1>
                <p className="p10"><span className="s1">We take precautions to protect the security of your
                    information. We have
                    physical, electronic, and managerial procedures to help safeguard,
                    prevent unauthorized access,
                    maintain data security, and correctly use your information. However,
                    neither people nor security
                    systems are foolproof, including encryption systems. In addition, people
                    can commit intentional
                    crimes, make mistakes or fail to follow policies. Therefore, while we
                    use reasonable efforts to
                    protect your personal information, we cannot guarantee its absolute
                    security. If applicable law
                    imposes any non-disclaimable duty to protect your personal information,
                    you agree that
                    intentional misconduct will be the standards used to measure our
                    compliance with that
                    duty.</span></p>
                <p className="p3"></p>
                <h1>Can I update or correct my information?</h1>
                <p className="p10"><span className="s1">The rights you have to request updates or corrections to
                    the information
                    we collect depend on your relationship with us. Personnel may update or
                    correct their
                    information as detailed in our internal company employment policies.
                    Customers have the right to request the restriction of certain uses and
                    disclosures of
                    personally identifiable information as follows. You can contact us in
                    order to (1) update or
                    correct your personally identifiable information, (2) change your
                    preferences with respect to
                    communications and other information you receive from us, or (3) delete
                    the personally
                    identifiable information maintained about you on our systems (subject to
                    the following
                    paragraph), by cancelling your account. Such updates, corrections,
                    changes and deletions will
                    have no effect on other information that we maintain, or information
                    that we have provided to
                    third parties in accordance with this Privacy Policy prior to such
                    update, correction, change or
                    deletion. To protect your privacy and security, we may take reasonable
                    steps (such as requesting
                    a unique password) to verify your identity before granting you profile
                    access or making
                    corrections. You are responsible for maintaining the secrecy of your
                    unique password and account
                    information at all times.
                    You should be aware that it is not technologically possible to remove
                    each and every record of
                    the information you have provided to us from our system. The need to
                    back up our systems to
                    protect information from inadvertent loss means that a copy of your
                    information may exist in a
                    non-erasable form that will be difficult or impossible for us to locate.
                    Promptly after
                    receiving your request, all personal information stored in databases we
                    actively use, and other
                    readily searchable media will be updated, corrected, changed or
                    deleted, as appropriate, as soon as and to the extent reasonably and
                    technically practicable.
                    If you are an end user and wish to update, delete, or receive any
                    information we have about you,
                    you may do so by contacting the organization of which you are a
                    customer.</span></p>
                <p className="p3"></p>



                <h1>Sale of Business</h1>
                <p className="p10"><span className="s1">We reserve the right to transfer information to a third
                    party in the
                    event of a sale, merger or other transfer of all or substantially all of
                    the assets of us or any
                    of its Corporate Affiliates (as defined herein), or that portion of us
                    or any of its
                    Corporate Affiliates to which the Service relates, or in the event that
                    we discontinue our
                    business or file a petition or have filed against us a petition in
                    bankruptcy, reorganization or
                    similar proceeding, provided that the third party agrees to adhere to
                    the terms of this Privacy
                    Policy.</span></p>
                <p className="p3"></p>
                <h1>Affiliates</h1>
                <p className="p10"><span className="s1">We may disclose information (including personal
                    information) about you
                    to our Corporate Affiliates. For purposes of this Privacy Policy,
                    "Corporate Affiliate" means
                    any person or entity which directly or indirectly controls, is
                    controlled by or is under common
                    control with us, whether by ownership or otherwise. Any information
                    relating to you that we
                    provide to our Corporate Affiliates will be treated by those Corporate
                    Affiliates in accordance
                    with the terms of this Privacy Policy.</span></p>
                <p className="p3"></p>
                <h1>How Long Do We Keep Your Information?</h1>
                <p className="p10"><span className="s1">We keep your information only so long as we need it to
                    provide service
                    to you and fulfill the purposes described in this policy. This is also
                    the case for anyone that
                    we share your information with and who carries out services on our
                    behalf. When we no longer
                    need to use your information and there is no need for us to keep it to
                    comply with our legal or
                    regulatory obligations, we'll either remove it from our systems or
                    depersonalize it so that we
                    can't identify you.</span></p>
                <p className="p3"></p>
                <h1>How Do We Protect Your Information?</h1>
                <p className="p10"><span className="s1">We implement a variety of security measures to maintain
                    the safety of
                    your personal information when you place an order or enter, submit, or
                    access your personal
                    information. We offer the use of a secure server. All supplied
                    sensitive/credit information is
                    transmitted via Secure Socket Layer (SSL) technology and then encrypted
                    into our Payment gateway
                    providers database only to be accessible by those authorized with
                    special access rights to such
                    systems, and are required to keep the information confidential. After a
                    transaction, your
                    private information (credit cards, social security numbers, financials,
                    etc.) is never kept on
                    file. We cannot, however, ensure or warrant the absolute security of any
                    information you
                    transmit to us or guarantee that your information on the Service may not
                    be accessed, disclosed,
                    altered, or destroyed by a breach of any of our physical, technical, or
                    managerial
                    safeguards.</span></p>
                <p className="p3"></p>
                <h1>Governing Law</h1>
                <p className="p10"><span className="s1">The laws of Israel, excluding its conflicts of law
                    rules, shall govern
                    this Agreement and your use of our service. Your use of our service may
                    also be subject to other
                    local, state, national, or international laws.</span></p>
                <p className="p3"></p>
                <h1>Your Consent</h1>
                <p className="p10"><span className="s1">By using our service, registering an account, or making
                    a purchase, you
                    consent to this Privacy Policy.</span></p>
                <p className="p3"></p>
                <h1>Links to Other Websites</h1>
                <p className="p10"><span className="s1">This Privacy Policy applies only to the Services. The
                    Services may
                    contain links to other websites not operated or controlled by us. We are
                    not responsible for the
                    content, accuracy or opinions expressed in such websites, and such
                    websites are not
                    investigated, monitored or checked for accuracy or completeness by us.
                    Please remember that when
                    you use a link to go from the Services to another website, our Privacy
                    Policy is no longer in
                    effect. Your browsing and interaction on any other website, including
                    those that have a link on
                    our platform, is subject to that website’s
                    own rules and policies. Such third parties may use their own cookies or
                    other methods to collect
                    information about you.</span></p>
                <p className="p3"></p>
                <h1>Cookies</h1>
                <p className="p10"><span className="s1">We use "Cookies" to identify the areas of our website
                    that you have
                    visited. A Cookie is a small piece of data stored on your computer or
                    mobile device by your web
                    browser. We use Cookies to personalize the Content that you see on our
                    website. Most web
                    browsers can be set to disable the use of Cookies. However, if you
                    disable Cookies, you may not
                    be able to access functionality on our website correctly or at all. We
                    never place Personally
                    Identifiable Information in Cookies.</span></p>
                <p className="p3"></p>





                <p className="p15"></p>



                <h1>Payment Details</h1>
                <p className="p10"><span className="s1">In respect to any credit card or other payment
                    processing details you
                    have provided us, we commit that this confidential information will be
                    stored in the most secure
                    manner possible.</span></p>
                <p className="p15"></p>



                <h1>Kids' Privacy</h1>
                <p className="p10"><span className="s1">We do not address anyone under the age of 13. We do not
                    knowingly
                    collect personally identifiable information from anyone under the age of
                    13. If You are a parent
                    or guardian and You are aware that Your child has provided Us with
                    Personal Data, please contact
                    Us. If We become aware that We have collected Personal Data from anyone
                    under the age of 13
                    without verification of parental consent, We take steps to remove that
                    information from our
                    servers.</span></p>
                <p className="p3"></p>
                <h1>Changes To Our Privacy Policy</h1>
                <p className="p10"><span className="s1">If we decide to change our privacy policy, we will post
                    those changes on
                    this page, and/or update the Privacy Policy modification date
                    below.</span></p>
                <p className="p3"></p>
                <h1>Third-Party Services</h1>
                <p className="p10"><span className="s1">We may display, include or make available third-party
                    content (including
                    data, information, applications and other products services) or provide
                    links to third-party
                    websites or services ("Third- Party Services").</span></p>
                <p className="p10"><span className="s1">You acknowledge and agree that we shall not be
                    responsible for any
                    Third-Party Services, including their accuracy, completeness,
                    timeliness, validity, copyright
                    compliance, legality, decency, quality or any other aspect thereof. We
                    do not assume and shall
                    not have any liability or responsibility to you or any other person or
                    entity for any
                    Third-Party Services.</span></p>
                <p className="p10"><span className="s1">Third-Party Services and links thereto are provided
                    solely as a
                    convenience to you and you access and use them entirely at your own risk
                    and subject to such
                    third parties' terms and conditions.</span></p>
                <p className="p3"></p>



                <h1>Tracking Technologies</h1>


                <li className="li11"><span className="s3">Cookies</span></li>
                <p className="p10"><span className="s1">
                    We use Cookies to enhance the performance and functionality of our
                    service but are non-essential
                    to their use. However, without these cookies, certain functionality like
                    videos may become
                    unavailable or you would be required to enter your
                    login details every time you visit our service as we would not be able
                    to remember that you had
                    logged in previously.
                </span></p>
                <li className="li11"><span className="s3">Local Storage</span></li>
                <p className="p10"><span className="s1">
                    Local Storage sometimes known as DOM storage, provides web apps with
                    methods and protocols for
                    storing client-side data. Web storage supports persistent data storage,
                    similar to cookies but
                    with a greatly enhanced capacity and no information stored in the HTTP
                    request header.
                </span></p>


                <p className="p3"></p>
                <h1>Information about General Data Protection Regulation (GDPR)</h1>
                <p className="p10"><span className="s1">We may be collecting and using information from you if
                    you are from the
                    European Economic Area (EEA), and in this section of our Privacy Policy
                    we are going to explain
                    exactly how and why is this data collected, and how we maintain this
                    data under protection from
                    being replicated or used in the wrong way.</span></p>
                <p className="p12"></p>
                <h1>What is GDPR?</h1>
                <h2>Your European Union Privacy Rights</h2>
                <p>
                    If you are a resident of the European Union, you have certain additional rights with respect to your personal information under the General Data Protection Regulation (Regulation (EU) 2016/679) (the “GDPR”), including the following:
                </p>

                <div class="space-half">
                    <ul class="list">
                        <li>
                            <p>
                                The right of access to your personal information.</p>
                        </li>
                        <li>
                            <p>
                                The right to rectify your personal information if it is incorrect or incomplete.
                            </p>
                        </li>
                        <li>
                            <p>
                                The right to have your personal information erased (“right to be forgotten”) if certain grounds are met.
                            </p>
                        </li>
                        <li>
                            <p>
                                The right to withdraw your consent to our processing of your personal information at any time (if our processing is based on consent).
                            </p>
                        </li>
                        <li>
                            <p>
                                The right to object to our processing of your personal information (if processing is based on legitimate interests).
                            </p>
                        </li>
                        <li>
                            <p>
                                The right to object to our processing of your personal information for direct marketing purposes.
                            </p>
                        </li>
                        <li>
                            <p>
                                The right to receive your personal information from us in a structured, commonly used and machine-readable format, and the right to transmit your personal information to another controller without hindrance from us (data portability).
                            </p>
                        </li>
                    </ul>
                </div>
                <p>
                    If you are located in the European Union and you are or have been our customer, we may send you marketing communications based on our legitimate interests, subject always to your right to opt out of such communications. Further, if you are located in the European Union, we will never share your personal information with a third party for such third party’s marketing purposes, unless you have specifically consented to us doing so.
                </p>
                <p>
                    You may contact us at et-el@hotmail.com to exercise any of the above rights. We may request specific information from you to confirm your identity, and in some circumstances we may charge a reasonable fee for access to your personal information. Furthermore, if you believe that our processing of your personal information is inconsistent with your data protection rights under the GDPR and we have not adequately addressed your concerns, you have the right to lodge a complaint with the data protection supervisory authority of your country.
                </p>
                <p>
                    For purposes of the GDPR, we are a “controller” and you are a “data subject.”
                </p>

                <div class="space">
                    <h2>Your California Privacy Rights</h2>
                    <p>California law permits customers in California to request certain details about how their information is shared with third parties for those third parties’ own direct marketing purposes. If you are a California resident, you may request such information from us by contacting us by email at et-el@hotmail.com. Any such request must include "California Privacy Rights Request" in the first line of the description and include your name, street address, city, state, and zip code. Please note that we are only required to respond to one request per customer each year.
                    </p>
                </div>


                <p className="p10"><span className="s1">GDPR is an EU-wide privacy and data protection law that
                    regulates how EU
                    residents' data is protected by companies and enhances the control the
                    EU residents have, over
                    their personal data.
                </span></p>
                <p className="p12"></p>
                <h1>What is personal data?</h1>
                <p className="p10"><span className="s1">Any data that relates to an identifiable or identified
                    individual. GDPR
                    covers a broad spectrum of information that could be used on its own, or
                    in combination with
                    other pieces of information, to identify a person. Personal data extends
                    beyond a person’s name
                    or email address. Some examples include financial information, political
                    opinions, genetic data,
                    biometric data, IP addresses, physical address, sexual orientation, and
                    ethnicity.
                    The Data Protection Principles include requirements such as:</span></p>
                <ul className="ul1">
                    <li className="li11"><span className="s3">Personal data collected must be processed in a
                        fair, legal, and
                        transparent way and should only be used in a way that a person
                        would reasonably
                        expect.</span></li>
                    <li className="li11"><span className="s3">Personal data should only be collected to
                        fulfil a specific
                        purpose and it should only be used for that purpose.
                        Organizations must specify why they
                        need the personal data when they collect it.</span></li>
                    <li className="li11"><span className="s3">Personal data should be held no longer than
                        necessary to fulfil
                        its purpose.</span></li>
                    <li className="li11"><span className="s3">People covered by the GDPR have the right to
                        access their own
                        personal data.
                        They can also request a copy of their data, and that their data
                        be updated, deleted,
                        restricted, or moved to another organization.</span></li>
                </ul>
                <p className="p12"></p>
                <h1>Why is GDPR important?</h1>
                <p className="p10"><span className="s1">GDPR adds some new requirements regarding how companies
                    should protect
                    individuals' personal data that they collect and process. It also raises
                    the stakes for
                    compliance by increasing enforcement and imposing greater fines for
                    breach. Beyond these facts
                    it's simply the right thing to do. At Help Scout we strongly believe
                    that your data privacy is
                    very important and we already have solid security and privacy practices
                    in place that go beyond
                    the requirements of this new regulation.</span></p>
                <p className="p12"></p>
                <h1>Individual Data Subject's Rights - Data Access, Portability and Deletion</h1>
                <p className="p10">

                    <span className="s1">
                        We are committed to helping our customers meet the data
                        subject rights
                        requirements of GDPR. processes or stores all personal data in fully
                        vetted, DPA compliant
                        vendors. We do store
                        all conversation and personal data for up to 6 years unless your account
                        is deleted. In which
                        case, we dispose of all data in accordance with our Terms of Service and
                        Privacy Policy, but we
                        will not hold it longer than 60 days.
                        <br></br>
                        We are aware that if you are working with EU customers, you need to be
                        able to provide them with
                        the ability to access, update, retrieve and remove personal data. We got
                        you! We've been set up
                        as self service from the start and have always given you access to your
                        data and your customers
                        data. Our customer support team is here for you to answer any questions
                        you might have about
                        working with the API.
                    </span>
                </p>
                <p className="p3"></p>
                <h1>California Residents</h1>
                <p className="p15"></p>
                <p className="p10"><span className="s1">The California Consumer Privacy Act (CCPA) requires us
                    to disclose
                    categories of Personal Information we collect and how we use it, the
                    categories of sources from
                    whom we collect Personal Information, and the third parties with whom we
                    share it, which we have
                    explained above.</span></p>
                <p className="p10"><span className="s1">We are also required to communicate information about
                    rights California
                    residents have under California law. You may exercise the following
                    rights:</span></p>
                <ul className="ul1">
                    <li className="li11"><span className="s3">Right to Know and Access. You may submit a
                        verifiable request for
                        information regarding the: (1) categories of Personal
                        Information we collect, use, or share;
                        (2) purposes for which categories of Personal Information are
                        collected or used by us; (3)
                        categories of sources from which we collect Personal
                        Information; and (4) specific pieces of
                        Personal Information we have collected about you.</span></li>
                    <li className="li11"><span className="s3">Right to Equal Service. We will not
                        discriminate against you if
                        you exercise your privacy rights.</span></li>
                    <li className="li11"><span className="s3">Right to Delete. You may submit a verifiable
                        request to close your
                        account and we will delete Personal Information about you that
                        we have collected.</span>
                    </li>
                    <li className="li11"><span className="s3">Request that a business that sells a
                        consumer's personal data, not
                        sell the consumer's personal data.</span></li>
                </ul>
                <p className="p12"></p>
                <p className="p10"><span className="s1">If you make a request, we have one month to respond to
                    you. If you would
                    like to exercise any of these rights, please contact us.</span></p>
                <p className="p10"><span className="s1">We do not sell the Personal Information of our
                    users.</span></p>
                <p className="p10"><span className="s1">For more information about these rights, please contact
                    us.</span></p>
                <p className="p3"></p>
                <h1>California Online Privacy Protection Act (CalOPPA)</h1>
                <p className="p10"><span className="s1">CalOPPA requires us to disclose categories of Personal
                    Information we
                    collect and how we use it, the categories of sources from whom we
                    collect Personal Information,
                    and the third parties with whom we share it, which we have explained
                    above.</span></p>
                <p className="p10"><span className="s1">CalOPPA users have the following rights:</span></p>
                <ul className="ul1">
                    <li className="li11"><span className="s3">Right to Know and Access. You may submit a
                        verifiable request for
                        information regarding the: (1) categories of Personal
                        Information we collect, use, or share;
                        (2) purposes for which categories of Personal Information are
                        collected or used by us; (3)
                        categories of sources from which we collect Personal
                        Information; and (4) specific pieces of
                        Personal Information we have collected about you.</span></li>
                    <li className="li11"><span className="s3">Right to Equal Service. We will not
                        discriminate against you if
                        you exercise your privacy rights.</span></li>
                    <li className="li11"><span className="s3">Right to Delete. You may submit a verifiable
                        request to close your
                        account and we will delete Personal Information about you that
                        we have collected.</span>
                    </li>
                    <li className="li11"><span className="s3">Right to request that a business that sells a
                        consumer's personal
                        data, not sell the consumer's personal data.</span></li>
                </ul>
                <p className="p12"></p>
                <p className="p10"><span className="s1">If you make a request, we have one month to respond to
                    you. If you would
                    like to exercise any of these rights, please contact us.
                    <br></br>
                    We do not sell the Personal Information of our users.
                    <br></br>
                    For more information about these rights, please contact us.</span></p>
                <p className="p3"></p>
                <h1>Contact Us</h1>
                <p className="p10"><span className="s1">Don't hesitate to contact us if you have any
                    questions.</span></p>
                <ul className="ul1">
                    <li className="li16"><span className="s3">Via Email: <a>et-el@hotmail.com</a></span>
                    </li>
                    <li className="li16"><span className="s3">Via Phone Number: <a>+972523500700</a></span>
                    </li>
                    <li className="li16"><span className="s3">Via this Link:
                        <a>https://et-jewelry.onrender.com/contact-us</a></span></li>

                </ul>
            </div>
            <hr></hr>
            <h2>מדיניות פרטיות באתר</h2>
            <div Style='text-align:right'>
                <p> !ברוכים הבאים לאִיתִּי תכשיטים</p>

                <p>https://et-jewelry.onrender.com/ :תנאים והגבלות אלה מתווים את הכללים והתקנות לשימוש באתר האינטרנט של אִיתִּי תכשיטים, הנמצא בכתובת</p>

                <p>.על ידי גישה לאתר זה אנו מניחים שאתה מקבל תנאים והגבלות אלה. אל תמשיך להשתמש באִיתִּי תכשיטים אם אינך מסכים לקבל את כל התנאים וההגבלות המופיעים בדף זה</p>

                <p>הטרמינולוגיה הבאה חלה על תנאים והגבלות אלה, הצהרת פרטיות והודעת כתב ויתור ועל כל ההסכמים: "לקוח", "אתה" ו"שלך" מתייחס אליך, האדם הנכנס לאתר זה ועומד בתנאי החברה ו תנאים. "החברה", "עצמנו", "אנחנו", "שלנו" ו"אנחנו", מתייחסים לחברה שלנו. "מסיבה", "צדדים" או "אנחנו", מתייחסים הן ללקוח והן לעצמנו. כל התנאים מתייחסים להצעה, הקבלה ותמורת התשלום הנחוצים לביצוע תהליך סיוענו ללקוח באופן המתאים ביותר למטרה מפורשת של מענה לצרכי הלקוח בגין מתן השירותים האמורים של החברה, בהתאם לאמור. ובכפוף לחוק המקובל בהולנד. כל שימוש בטרמינולוגיה שלעיל או במילים אחרות ביחיד, ברבים, באותיות רישיות ו/או הוא/היא או הם, נתפסים כניתנים להחלפה ולפיכך כמתייחסים לזה.</p>

                <h3><strong>Cookie</strong></h3>

                <p>אנו משתמשים בשימוש בעוגיות. בכניסה לאִיתִּי תכשיטים, הסכמת להשתמש בקובצי Cookie בהסכמה למדיניות הפרטיות של אִיתִּי תכשיטים. </p>

                <p>רוב האתרים האינטראקטיביים משתמשים בקובצי Cookie כדי לאפשר לנו לאחזר את פרטי המשתמש עבור כל ביקור. קובצי Cookie משמשים את האתר שלנו כדי לאפשר את הפונקציונליות של אזורים מסוימים כדי להקל על אנשים המבקרים באתר שלנו. חלק מהשותפים/שותפי הפרסום שלנו עשויים להשתמש גם בקובצי Cookie.</p>

                <h3><strong>רישיון</strong></h3>

                <p>אלא אם צוין אחרת, אִיתִּי תכשיטים ו/או מעניקי הרישיונות שלה הם הבעלים של זכויות הקניין הרוחני על כל החומר על אִיתִּי תכשיטים. כל זכויות הקניין הרוחני שמורות. אתה יכול לגשת אליו מאת אִיתִּי תכשיטים לשימושך האישי בכפוף להגבלות המוגדרות בתנאים והגבלות אלה.</p>

                <p>:אסור לך</p>
                <ul>
                    <li>פרסם מחדש חומר מאת אִיתִּי תכשיטים</li>
                    <li>מכירה, השכרה או רישיון משנה של חומר מאת אִיתִּי תכשיטים</li>
                    <li>שכפל, שכפל או העתק חומר מאִיתִּי תכשיטים</li>
                    <li>הפצה מחדש של תוכן מאת אִיתִּי תכשיטים</li>
                </ul>

                <p>הסכם זה יתחיל בתאריך זה. התנאים וההגבלות שלנו נוצרו בעזרת <a href="https://www.termsandconditionsgenerator.com/">מחולל התנאים וההגבלות החינמי</a>.</p>

                <p>חלקים מאתר זה מציעים הזדמנות למשתמשים לפרסם ולהחליף דעות ומידע באזורים מסוימים באתר. אִיתִּי תכשיטים אינו מסנן, עורך, מפרסם או סוקר תגובות לפני נוכחותן באתר. הערות אינן משקפות את דעותיהם ודעותיהם של אִיתִּי תכשיטים, סוכניה ו/או השותפים שלה. הערות משקפות את הדעות והדעות של האדם שמפרסם את דעותיו ודעותיו. ככל שמתיר החוקים החלים, אִיתִּי תכשיטים לא תישא באחריות להערות או לכל אחריות, נזק או הוצאות שייגרמו ו/או ייגרמו כתוצאה מכל שימוש ו/או פרסום ו/או הופעתן של ההערות ב אתר זה.</p>

                <p>אִיתִּי תכשיטים שומרת לעצמה את הזכות לעקוב אחר כל ההערות ולהסיר כל הערה העלולה להיחשב בלתי הולמת, פוגענית או הגורמת להפרה של תנאים והגבלות אלה.</p>

                <p>:אתה מתחייב ומייצג כי</p>

                <ul>
                    <li>אתה זכאי לפרסם את ההערות באתר האינטרנט שלנו ויש לך את כל הרישיונות וההסכמות הדרושים לכך;</li>
                    <li>ההערות אינן פולשות לכל זכות קניין רוחני, לרבות ללא הגבלה זכויות יוצרים, פטנט או סימן מסחרי של צד שלישי כלשהו;</li>
                    <li>התגובות אינן מכילות כל חומר משמיץ, לשון הרע, פוגעני, מגונה או בלתי חוקי אחר המהווה פגיעה בפרטיות</li>
                    <li>ההערות לא ישמשו לשידול או קידום עסקים או התאמה אישית או הצגת פעילויות מסחריות או פעילות בלתי חוקית.</li>
                </ul>

                <p>אתה מעניק בזאת לאִיתִּי תכשיטים רישיון לא בלעדי להשתמש, לשכפל, לערוך ולהתיר לאחרים להשתמש, לשכפל ולערוך כל אחת מההערות שלך בכל צורה, פורמט או מדיה.</p>

                <h3><strong>היפר-קישור לתוכן שלנו (Hyper-Link)</strong></h3>

                <p>:הארגונים הבאים עשויים לקשר לאתר שלנו ללא אישור מראש בכתב</p>

                <ul>
                    <li>;סוכנויות ממשלתיות</li>
                    <li>;מנועי חיפוש</li>
                    <li>;ארגוני חדשות</li>
                    <li>;מפיצי ספריות מקוונים עשויים לקשר לאתר שלנו באותו אופן שבו הם מקשרים היפר לאתרים של עסקים רשומים אחרי</li>
                    <li>.עסקים מוסמכים ברחבי המערכת למעט שידול לארגונים ללא מטרות רווח, מרכזי קניות לצדקה וקבוצות לגיוס כספים צדקה, שאולי לא יעברו קישור לאתר האינטרנט שלנו</li>
                </ul>

                <p>ארגונים אלה עשויים לקשר לדף הבית שלנו, לפרסומים או למידע אחר באתר כל עוד הקישור: (א) אינו מטעה בשום צורה; (ב) אינו מרמז באופן כוזב על חסות, תמיכה או אישור של הצד המקשר ומוצריו ו/או שירותיו; וכן (ג) מתאים להקשר של האתר של הצד המקשר.</p>

                <p>:אנו עשויים לשקול ולאשר בקשות קישור אחרות מהסוגים הבאים של ארגונים</p>

                <ul>
                    <li>;מקורות מידע צרכניים ו/או עסקיים ידועים</li>
                    <li>;אתרי קהילת dot.com</li>
                    <li>;עמותות או קבוצות אחרות המייצגות ארגוני צדקה</li>
                    <li>;מפיצי ספריות מקוונות</li>
                    <li>;פורטלי אינטרנט</li>
                    <li>;חברות ראיית חשבון, עורכי דין וייעוץ</li>
                    <li>.מוסדות חינוך ואיגודי מקצוע</li>
                </ul>

                <p>אנו נאשר בקשות קישור מארגונים אלה אם נחליט כי: (א) הקישור לא יגרום לנו להיראות לרעה כלפי עצמנו או כלפי העסקים המוסמכים שלנו; (ב) לארגון אין רישומים שליליים איתנו; (ג) התועלת לנו מנראות ההיפר-קישור מפצה על היעדר אִיתִּי תכשיטים; וכן (ד) הקישור הוא בהקשר של מידע כללי על משאבים.</p>

                <p>ארגונים אלה עשויים לקשר לדף הבית שלנו כל עוד הקישור: (א) אינו מטעה בשום צורה; (ב) אינו מרמז באופן כוזב על חסות, תמיכה או אישור של הצד המקשר ומוצריו או שירותיו; וכן (ג) מתאים להקשר של האתר של הצד המקשר.</p>

                <p>אם אתה אחד מהארגונים המפורטים בסעיף 2 לעיל ומעוניין לקשר לאתר שלנו, עליך להודיע ​​לנו על ידי שליחת דואר אלקטרוני לאִיתִּי תכשיטים. אנא כלול את שמך, שם הארגון שלך, מידע ליצירת קשר וכן את כתובת האתר שלך, רשימה של כל כתובות האתרים שמהן אתה מתכוון לקשר לאתר שלנו, ורשימה של כתובות האתרים באתר שלנו שאליהן תרצה קישור. המתן 2-3 שבועות לתגובה.</p>

                <p>:ארגונים מאושרים עשויים להשתמש להיפר קישור לאתר שלנו באופן הבא</p>

                <ul>
                    <li>על ידי שימוש בשם החברה שלנו; או</li>
                    <li>באמצעות מאתר המשאבים האחיד המקושר אליו; או</li>
                    <li>.על ידי שימוש בכל תיאור אחר של האתר שלנו שמקושר אליו הגיוני בהקשר ובפורמט של התוכן באתר של הצד המקשר</li>
                </ul>

                <p>.לא יתאפשר שימוש בלוגו של אִיתִּי תכשיטים או ביצירות אמנות אחרות לקישור ללא הסכם רישיון סימן מסחרי</p>

                <h3><strong>iFrames</strong></h3>

                <p>.ללא אישור מראש והרשאה בכתב, אינך רשאי ליצור מסגרות מסביב לדפי האינטרנט שלנו שמשנות בכל דרך את המצגת החזותית או המראה של האתר שלנו</p>

                <h3><strong>אחריות לתוכן</strong></h3>

                <p>לא נישא באחריות לכל תוכן המופיע באתר שלך. אתה מסכים להגן עלינו ולהגן עלינו מפני כל הטענות שעולות באתר שלך. אין להופיע קישור(ים) .באתר כלשהו שעלול להתפרש כעל לשון הרע, מגונה או פלילי, או המפר, מפר בדרך אחרת או תומך בהפרה או הפרה אחרת של זכויות צד שלישי כלשהן</p>

                <h3><strong>הפרטיות שלך</strong></h3>

                <p>אנא קרא את מדיניות הפרטיות</p>

                <h3><strong>שימור זכויות</strong></h3>

                <p>.אנו שומרים לעצמנו את הזכות לבקש שתסיר את כל הקישורים או כל קישור מסוים לאתר שלנו. אתה מאשר להסיר באופן מיידי את כל הקישורים לאתר שלנו לפי בקשה. כמו כן, אנו שומרים לעצמנו את הזכות לתקן את התנאים וההגבלות הללו ואת מדיניות הקישור שלו בכל עת. על ידי קישור מתמיד לאתר שלנו, אתה מסכים להיות מחויב לתנאים והגבלות אלה של קישורים ולעקוב אחריהם</p>

                <h3><strong>הסרת קישורים מהאתר שלנו</strong></h3>

                <p>.אם אתה מוצא קישור כלשהו באתר האינטרנט שלנו שהוא פוגעני מכל סיבה שהיא, אתה מוזמן ליצור קשר ולהודיע ​​לנו בכל רגע. אנו נשקול בקשות להסרת קישורים אך איננו מחויבים לכך או להגיב אליך ישירות</p>

                <p>.איננו מבטיחים שהמידע באתר זה נכון, איננו מתחייבים לשלמות או לדיוק שלו; אנחנו גם לא מתחייבים להבטיח שהאתר יישאר זמין או שהחומר באתר יישמר מעודכן</p>

                <h3><strong>כתב ויתור</strong></h3>

                <p>:במידה המקסימלית המותרת על פי החוק החל, אנו לא כוללים את כל המצגים, האחריות והתנאים הקשורים לאתר שלנו ולשימוש באתר זה. שום דבר בכתב ויתור זה אינו</p>

                <ul>
                    <li>להגביל או לא לכלול את החבות שלנו או שלך למוות או לפציעה אישיתף</li>
                    <li>להגביל או לא לכלול את החבות שלנו או שלך להונאה או מצג שווא במרמהף</li>
                    <li>להגביל כל אחת מהחבויות שלנו או שלך בכל דרך שאינה מותרת על פי החוק החל; או</li>
                    <li>.אל תכלול כל אחת מההתחייבויות שלנו או שלך שאולי לא תוחרג על פי החוק החל</li>
                </ul>

                <p>המגבלות ואיסורי החבות הקבועים בסעיף זה ובמקומות אחרים בכתב ויתור זה: (א) כפופים לפסקה הקודמת; וכן (ב) .לשלוט בכל ההתחייבויות הנובעות על פי כתב הוויתור, לרבות התחייבויות הנובעות בחוזה, בנזיקין ובגין הפרת חובה חקוקה</p>

                <p>.כל עוד האתר והמידע והשירותים באתר מסופקים ללא תשלום, לא נהיה אחראים לכל אובדן או נזק מכל סוג שהוא</p>
            </div>
        </div >
    )
}
