import React,{ useContext } from 'react';
import { Store } from '../Store';
import { Helmet } from 'react-helmet-async';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MessageBox from '../components/MessageBox';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function CartScreen() {
  const navigate = useNavigate();
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const {
    cart: { cartItems },
  } = state;

  const updateCartHandler = async (item, quantity) => {
    const { data } = await axios.get(`https://et-jewelry-api.onrender.com/api/products/${item._id}`);
    if (data.countInStock < quantity) {
      window.alert('Sorry. Product is out of stock');
      return;
    }
    ctxDispatch({
      type: 'CART_ADD_ITEM',
      payload: { ...item, quantity },
    });
  };
  const removeItemHandler = (item) => {
    ctxDispatch({ type: 'CART_REMOVE_ITEM', payload: item });
  };

  const checkoutHandler = () => {
    navigate('/signin?redirect=/shipping');
  };

  return (
    <div>
      <Helmet>
        <title>עגלת קניות</title>
      </Helmet>
      <h1>עגלת קניות</h1>
      <Row md={4} >
        <Col md={4}>
          <Card>
            <Card.Body>
              <ListGroup variant="flush">
                <ListGroup.Item Style="background-color: #181a1b;color: white;">
                  <h3>
                    סה"כ ({cartItems.reduce((a, c) => a + c.quantity, 0)}{' '}
                    מוצרים) : ₪
                    {cartItems.reduce((a, c) => a + c.price * c.quantity, 0)}
                  </h3>
                </ListGroup.Item>
                <ListGroup.Item Style="background-color: #181a1b;color: white;">
                  <div className="d-grid">
                    <Button
                      type="button"
                      variant="primary"
                      onClick={checkoutHandler}
                      disabled={cartItems.length === 0}
                    >
                      המשך לכתובת למשלוח
                    </Button>
                  </div>
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
        <Col md={8}> 
          {cartItems.length === 0 ? (
            <MessageBox>
             עגלה ריקה&nbsp;<Link to="/">לחץ כאן לעמוד מוצרים</Link>
            </MessageBox>
          ) : (
            <ListGroup>
              {cartItems.map((item) => (
                <ListGroup.Item key={item._id} Style="background-color: #181a1b;color: white;">
                  <Row className="align-items-center" >
                    <Col xs={2} sm={3} md={2}>
                      <Button

                        onClick={() => removeItemHandler(item)}
                        variant="danger"
                      >
                        <i className="fas fa-trash"></i>
                      </Button>
                    </Col>

                    <Col xs={1} sm={3} md={3} id="quan-button">
                      <Button
                        variant="dark"
                        onClick={() =>
                          updateCartHandler(item, item.quantity + 1)
                        }
                        disabled={item.quantity === item.countInStock}
                      >
                        <i className="fas fa-plus-circle"></i>
                      </Button>&nbsp;
                      <span id="quan-item">{item.quantity}</span>{' '}
                      <Button
                        onClick={() =>
                          updateCartHandler(item, item.quantity - 1)
                        }
                        variant="dark"
                        disabled={item.quantity === 1}
                      >
                        <i className="fas fa-minus-circle"></i>
                      </Button>
                    </Col>
                    <Col xs={5} sm={3} md={3} Style="font-weight:bold;">₪{item.price}</Col>
                    <Col xs={4} sm={3} md={4}>
                      <Link Style="text-decoration:none; font-weight:bold; color:white;" id="product-name" to={`/product/${item.slug}`}>{item.name}</Link>&nbsp;
                      <img
                        src={item.image}
                        alt={item.name}
                        className="img-fluid rounded img-thumbnail"
                      ></img>
                    </Col>
                  </Row>
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </Col>
      </Row>
    </div>
  );
}
