import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import CheckoutSteps from '../components/CheckoutSteps';
import { Store } from '../Store';
import { Link } from 'react-router-dom';

export default function PaymentMethodScreen() {
  const navigate = useNavigate();
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { cart: { shippingAddress, paymentMethod } } = state;
  const { cart } = state;
  const [paymentMethodName, setPaymentMethod] = useState(paymentMethod || 'PayPal');

  const round2 = (num) => Math.round(num * 100 + Number.EPSILON) / 100; // 123.2345 => 123.23
  cart.itemsPrice = round2(
    cart.cartItems.reduce((a, c) => a + c.quantity * c.price, 0)
  );
  cart.shippingPrice = cart.itemsPrice > 100 ? round2(0) : Number(cart.paymentMethod);
  cart.totalPrice = cart.itemsPrice;

  useEffect(() => {
    if (!shippingAddress.address) {
      navigate('/shipping');
    }
  }, [shippingAddress, navigate]);

  const submitHandler = (e) => {
    e.preventDefault();
    ctxDispatch({ type: 'SAVE_PAYMENT_METHOD', payload: paymentMethodName });
    localStorage.setItem('paymentMethod', paymentMethodName);
    navigate('/placeorder');
  };

  return (
    <div>
      <CheckoutSteps step1 step2></CheckoutSteps>
      <br></br>
      <Link to="/shipping"><i className="fas fa-arrow-right" Style="color:white"></i></Link>
      <Container className="small-container">
        <Helmet>
          <title>סוג התשלום</title>
        </Helmet>
        <h1 className="my-3">סוג המשלוח</h1>
        {cart.totalPrice < 100 ? (
          <Form onSubmit={submitHandler}>

            <Form.Check
              type="radio"
              name="delivery"
              id="reverse-radio"
              // className="form-check-input"
              label=" ₪30 משלוח רגיל"
              // Style="float: right;"
              value="30"
              checked={paymentMethodName === '30'}
              onChange={(e) => setPaymentMethod(e.target.value)}
              required
            />

            {/* <Form.Label className="form-check-label" Style="margin-right:30px">
  ₪30 משלוח רגיל
</Form.Label> */}

            <Form.Check

              type="radio"
              name="delivery"
              id="reverse-radio"
              // className="form-check-input"
              label=" איסוף עצמי (עם אופציה לתשלום במזומן)"
              // Style="float: right;"
              value="0"
              checked={paymentMethodName === '0'}
              onChange={(e) => setPaymentMethod(e.target.value)}
              required
            />

            {paymentMethodName === "0" &&
              <h5 className="my-3" Style="color:red">ניתן לשלם בכרטיס אשראי באיסוף עצמי </h5>
            }

            <div className="mb-3">
              <Button type="submit">המשך</Button>
            </div>
          </Form>
        ) : cart.totalPrice >= 100 ? (
          <Form onSubmit={submitHandler}>
            <Form.Check
              type="radio"
              name="delivery"
              id="reverse-radio"
              label="₪משלוח חינם על הזמנות מעל 100"
              value="0"
              checked={paymentMethodName === '0'}
              onChange={(e) => setPaymentMethod(e.target.value)}
              required
            />

            <div className="mb-3">
              <Button type="submit">המשך</Button>
            </div>
          </Form>

        ) : (
          <span></span>
        )}
        {/* <Form onSubmit={submitHandler}>

          <Form.Check
            type="radio"
            name="delivery"
            id="reverse-radio"
            label=" ₪30 משלוח רגיל"
            value="30"
            checked={paymentMethodName === '30'}
            onChange={(e) => setPaymentMethod(e.target.value)}
            required
          />


          <Form.Check

            type="radio"
            name="delivery"
            id="reverse-radio"
            label=" איסוף עצמי (עם אופציה לתשלום במזומן)"
            value="0"
            checked={paymentMethodName === '0'}
            onChange={(e) => setPaymentMethod(e.target.value)}
            required
          />

          {paymentMethodName === "0" &&
            <h5 className="my-3" Style="color:red">ניתן לשלם בכרטיס אשראי באיסוף עצמי </h5>
          }

          <div className="mb-3">
            <Button type="submit">המשך</Button>
          </div>
        </Form> */}

        {/* <Form id="paymentForm" onSubmit={submitHandler} >
          <Form.Group className="form-check mb-3">
            <input
              type="radio"
              name="delivery"
              id="normalShipping"
              className="form-check-input"
              Style="float: right;"
              value="30"
              checked={paymentMethodName === '30'}
              onChange={(e) => setPaymentMethod(e.target.value)}
              required

            />
            <Form.Label className="form-check-label" Style="margin-right:30px">
              ₪30 משלוח רגיל
            </Form.Label>
          </Form.Group>
          <Form.Group className="form-check mb-3">
            <input
              type="radio"
              name="delivery"
              id="takeAway"
              className="form-check-input"
              Style="float: right;"
              value="0"
              checked={paymentMethodName === '0'}
              onChange={(e) => setPaymentMethod(e.target.value)}
              required
            />
            <Form.Label className="form-check-label" Style="margin-right:30px">
              איסוף עצמי (עם אופציה לתשלום במזומן)
            </Form.Label>
          </Form.Group>

          <div className="mb-3">
            <Button type="submit">המשך</Button>
          </div>
        </Form> */}
      </Container>
    </div>
  );
}
