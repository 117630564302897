
import axios from 'axios';
import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Rating from '../components/Rating';
import { Helmet } from 'react-helmet-async';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { getError } from '../utils';
import { Store } from '../Store';
import { toast } from 'react-toastify';

const reducer = (state, action) => {
  switch (action.type) {
    case 'REFRESH_PRODUCT':
      return { ...state, product: action.payload };
    case 'CREATE_REQUEST':
      return { ...state, loadingCreateReview: true };
    case 'CREATE_SUCCESS':
      return { ...state, loadingCreateReview: false };
    case 'CREATE_FAIL':
      return { ...state, loadingCreateReview: false };
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return { ...state, product: action.payload, loading: false };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default function ProductScreen() {
  let reviewsRef = useRef();

  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [selectedImage, setSelectedImage] = useState('');

  const navigate = useNavigate();
  const params = useParams();
  const { slug } = params;

  const [{ loading, error, product, loadingCreateReview }, dispatch] =
    useReducer(reducer, {
      product: [],
      loading: true,
      error: '',
    });
  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'FETCH_REQUEST' });
      try {
        const result = await axios.get(`https://et-jewelry-api.onrender.com/api/products/slug/${slug}`);
        dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
      } catch (err) {
        dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
      }
    };
    fetchData();
  }, [slug]);

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { cart, userInfo } = state;
  const addToCartHandler = async () => {
    const existItem = cart.cartItems.find((x) => x._id === product._id);
    const quantity = existItem ? existItem.quantity + 1 : 1;
    const { data } = await axios.get(`https://et-jewelry-api.onrender.com/api/products/${product._id}`);
    if (data.countInStock < quantity) {
      window.alert('מצטערים, אך כמות המלאי למוצר זה מוגבלת');
      return;
    }
    ctxDispatch({
      type: 'CART_ADD_ITEM',
      payload: { ...product, quantity },
    });
    navigate('/cart');
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!comment || !rating) {
      toast.error('וודא כי בחרת דירוג, וכתבת ביקורת');
      return;
    }
    try {
      const { data } = await axios.post(
        `https://et-jewelry-api.onrender.com/api/products/${product._id}/reviews`,
        { rating, comment, name: userInfo.name },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );

      dispatch({
        type: 'CREATE_SUCCESS',
      });
      toast.success('ביקורת נשלחה בהצלחה! תודה');
      product.reviews.unshift(data.review);
      product.numReviews = data.numReviews;
      product.rating = data.rating;
      dispatch({ type: 'REFRESH_PRODUCT', payload: product });
      window.scrollTo({
        behavior: 'smooth',
        top: reviewsRef.current.offsetTop,
      });
    } catch (error) {
      toast.error(getError(error));
      dispatch({ type: 'CREATE_FAIL' });
    }
  };
  return loading ? (
    <LoadingBox />
  ) : error ? (
    <MessageBox variant="danger">{error}</MessageBox>
  ) : (
    <div className="productCard">
      <Helmet>
        <title>{product.name}</title>
      </Helmet>
      <Row >
        <Col md={9}>
          <img
            className="img-large"
            src={selectedImage || product.image}
            alt={product.name}
            Style="border-radius:3px;"
          // height="766"
          ></img>
        </Col>
        <Col md={3}>
          <ListGroup>
            <ListGroup.Item Style="background-color: #181a1b;color: white;">
              <h1>{product.name}</h1>
            </ListGroup.Item>
            <ListGroup.Item Style="background-color: #181a1b;">
              <Rating
                rating={product.rating}
                numReviews={product.numReviews}
              ></Rating>
            </ListGroup.Item >
            <ListGroup.Item Style="background-color: #181a1b;color: white;">₪{product.price} :מחיר</ListGroup.Item>
            <ListGroup.Item Style="background-color: #181a1b;color: white;">
              <Row>
                <Col>
                  {product.countInStock > 0 ? (
                    <Badge bg="success">זמין במלאי</Badge>
                  ) : (
                    <Badge bg="danger">לא קיים במלאי</Badge>
                  )}
                </Col>
                <Col>:סטטוס</Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item Style="background-color: #181a1b;color: white;" > 
              <Row md={3} className="g-2" >
                {[product.image, ...product.images].map((x) => (
                  <Col key={x} >
                    <Card>
                      <Button
                        className="thumbnail"
                        type="button"
                        variant="none"
                        onClick={() => setSelectedImage(x)}
                      >
                        <Card.Img className="product-card-image" variant="top" src={x} alt="product" />
                      </Button>
                    </Card>
                  </Col>
                ))}
              </Row>
            </ListGroup.Item>
            <ListGroup.Item Style="background-color: #181a1b;color: white;">
              :תיאור
              <p>{product.description}</p>
            </ListGroup.Item>
            {product.countInStock > 0 && !userInfo?.isAdmin ? (
              <ListGroup.Item Style="background-color: #181a1b;color: white;">
                <div className="d-grid">
                  <Button variant="primary" onClick={addToCartHandler}>
                    הוסף לעגלה
                  </Button>
                </div>
              </ListGroup.Item>
            ) : userInfo?.isAdmin === true &&(
              <span></span>
            )}
          </ListGroup>
        </Col>
      </Row>
      <div className="my-3">
        <h2 ref={reviewsRef}>ביקורות</h2>
        <div className="mb-3">
          {product.reviews.length === 0 && (
            <MessageBox>אין ביקורות למוצר זה</MessageBox>
          )}
        </div>
        <ListGroup>
          {product.reviews.map((review) => (
            <ListGroup.Item key={review._id} Style="background-color: #222526;color: white;">
              <strong>{review.name}</strong>
              <Rating rating={review.rating} caption=" "></Rating>
              <p>{review.createdAt.substring(0, 10)}</p>
              <p>{review.comment}</p>
            </ListGroup.Item>
          ))}
        </ListGroup>
        <div className="my-3">
          {userInfo ? (
            <form onSubmit={submitHandler}>
              <h2>כתוב ביקורת חדשה</h2>
              <Form.Group className="mb-3" controlId="rating">
                <Form.Label>דירוג</Form.Label>
                <Form.Select
                  aria-label="Rating"
                  value={rating}
                  onChange={(e) => setRating(e.target.value)}
                >
                  <option selected>בחר דירוג</option>
                  <option value="1">1- גרוע</option>
                  <option value="2">2- ממוצע</option>
                  <option value="3">3- טוב</option>
                  <option value="4">4- מצויין</option>
                  <option value="5">5- מדהים</option>
                </Form.Select>
              </Form.Group>
              <Form.Label>ביקורת</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="...כתוב ביקורת"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
              <br></br>

              <div className="mb-3">
                <Button disabled={!comment.length} type="submit">
                  הוסף ביקורת
                </Button>
                {loadingCreateReview && <LoadingBox></LoadingBox>}
              </div>
            </form>
          ) : (
            <MessageBox>
              בבקשה{' '}
              <Link to={`/signin?redirect=/product/${product.slug}`}>
                התחבר לאתר
              </Link>{' '}
              בכדי לכתוב ביקורת
            </MessageBox>
          )}
        </div>
      </div>
    </div>
  );
}
