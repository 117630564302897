import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'
import { Helmet } from 'react-helmet-async'
import { toast } from 'react-toastify';

export default function ContactScreen() {

    const [email, setEmail] = useState('');
    const [fullName, setFullName] = useState('');
    const [message, setMessage] = useState('');

    const sendMessage = () => {
        const date = new Date();
        window.Email.send({
            Host: "smtp.elasticemail.com",
            Username: `${process.env.REACT_APP_MAIL_USERNAME}`,
            Password: `${process.env.REACT_APP_MAIL_PASSWORD}`,
            To: `${process.env.REACT_APP_MAIL_USERNAME}`,
            From: `${process.env.REACT_APP_MAIL_USERNAME}`,
            Subject: ` ניסה ליצור קשר מהאתר ${fullName}`,
            Body: `
            <br>
            :נשלח בתאריך ${date}
            <br>
            :שם מלא של השולח ${fullName} 
            <br>
            :תוכן ההודעה ${message}` 
        });
        toast.success('הודעה נשלחה בהצלחה, ניצור איתך קשר בהקדם');
        setTimeout(() => {
            window.location.href = '/contact-us';
        }, 3000);

    }

    return (
        <div>
            <Helmet>
                <title>צור קשר</title>
            </Helmet>
            <h1>צור קשר</h1>
            <h5>טלפון: 052-3500700</h5>
            <h5>שעות פעילות: 09:00 - 19:00</h5>
            <br></br>
            <h1>שלח הודעה</h1>
            <Form>
                <Form.Group className="mb-3" controlId="fullName">
                    <Form.Label>:שם מלא</Form.Label>
                    <Form.Control
                        type="text"
                        onChange={(e) => setFullName(e.target.value)}
                        autoComplete="off"
                        required
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="email">
                    <Form.Label>:אימייל</Form.Label>
                    <Form.Control
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        autoComplete="off"
                        required
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="message">
                    <Form.Label>:אימייל</Form.Label>
                    <Form.Control
                        as="textarea"
                        type="text"
                        onChange={(e) => setMessage(e.target.value)}
                        autoComplete="off"
                        required
                    />
                </Form.Group>
                <div className="mb-3">
                    <Button type="button" onClick={sendMessage} disabled={!email.length || !fullName.length || !message.length}>שלח הודעה</Button>
                </div>

            </Form>
        </div>
    )
}
