import axios from 'axios';
import React, { useContext, useEffect, useReducer } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import { toast } from 'react-toastify';
import { getError } from '../utils';
import { Store } from '../Store';
import CheckoutSteps from '../components/CheckoutSteps';
import LoadingBox from '../components/LoadingBox';

const reducer = (state, action) => {
  switch (action.type) {
    case 'CREATE_REQUEST':
      return { ...state, loading: true };
    case 'CREATE_SUCCESS':
      return { ...state, loading: false };
    case 'CREATE_FAIL':
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default function PlaceOrderScreen() {
  const navigate = useNavigate();

  const [{ loading }, dispatch] = useReducer(reducer, {
    loading: false,
  });

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { cart, userInfo } = state;

  const round2 = (num) => Math.round(num * 100 + Number.EPSILON) / 100; // 123.2345 => 123.23
  cart.itemsPrice = round2(
    cart.cartItems.reduce((a, c) => a + c.quantity * c.price, 0)
  );
  cart.shippingPrice = cart.itemsPrice > 100 ? round2(0) : Number(cart.paymentMethod);
  // cart.taxPrice = round2(0.10 * cart.itemsPrice);
  // cart.totalPrice = cart.itemsPrice + cart.shippingPrice + cart.taxPrice;
  cart.totalPrice = cart.itemsPrice + cart.shippingPrice;

  const placeOrderHandler = async () => {
    try {
      dispatch({ type: 'CREATE_REQUEST' });

      const { data } = await axios.post(
        `https://et-jewelry-api.onrender.com/api/orders`,
        {
          orderItems: cart.cartItems,
          shippingAddress: cart.shippingAddress,
          paymentMethod: cart.paymentMethod,
          itemsPrice: cart.itemsPrice,
          shippingPrice: cart.paymentMethod,
          taxPrice: 0,
          totalPrice: cart.totalPrice,
        },
        {
          headers: {
            authorization: `Bearer ${userInfo.token}`,
          },
        }
      );
      ctxDispatch({ type: 'CART_CLEAR' });
      dispatch({ type: 'CREATE_SUCCESS' });
      localStorage.removeItem('cartItems');
      navigate(`/order/${data.order._id}`);
    } catch (err) {
      dispatch({ type: 'CREATE_FAIL' });
      toast.error(getError(err));
    }
  };

  useEffect(() => {
    if (!cart.paymentMethod) {
      navigate('/payment');
    }
  }, [cart, navigate]);

  console.log(cart.paymentMethod);
  return (
    <div>
      <CheckoutSteps step1 step2 step3></CheckoutSteps>
      <br></br>
      <Link to="/payment"><i className="fas fa-arrow-right" Style="color:white"></i></Link>
      <Helmet>
        <title>הזמנה</title>
      </Helmet>
      <h1 className="my-3">הזמנה</h1>
      <Row className="order-details">
        <Col md={8}>
          <Card className="mdb-card order-details mb-3">
            <Card.Body>
              <Card.Title>:פרטי משלוח</Card.Title>
              <Card.Text>
              {cart.shippingAddress.fullName} <strong>:שם מלא </strong> <br />
              {cart.shippingAddress.postalCode},&nbsp;{cart.shippingAddress.city},&nbsp;{cart.shippingAddress.country},&nbsp;{cart.shippingAddress.address}&nbsp; <strong Style="float:right"> :כתובת למשלוח </strong>
              </Card.Text>
              <Link to="/shipping">ערוך</Link>
            </Card.Body>
          </Card>

          <Card className="mdb-card order-details mb-3">
            <Card.Body>
              <Card.Title>משלוח</Card.Title>
              <Card.Text>
                <strong>סוג משלוח</strong>
                <br></br>
                <Col>
                  {cart.paymentMethod === '0' && cart.itemsPrice < 100 && 'איסוף עצמי'}
                  {/* <strong>סוג משלוח:</strong> {cart.shippingPrice === '0' && cart.paymentMethod === '0' ? 'משלוח חינם על הזמנה מעל ₪100' : 'משלוח רגיל ₪30'} */}
                  {cart.paymentMethod === '30' && cart.itemsPrice < 100 && 'משלוח רגיל ₪30'}
                  {cart.paymentMethod === '0' && cart.itemsPrice >= 100 && '₪משלוח חינם על הזמנה מעל 100'}
                </Col>
              </Card.Text>
              <Link to="/payment">ערוך</Link>
            </Card.Body>
          </Card>

          <Card className="mdb-card order-details mb-3">
            <Card.Body>
              <Card.Title>מוצרים</Card.Title>
              <ListGroup variant="dark" Style="background-color: #181a1b;color: white;">
                {cart.cartItems.map((item) => (
                  <ListGroup.Item key={item._id} Style="background-color: #181a1b;color: white;">
                    <Row className="align-items-center">
                      <Col md={4}>
                        <img
                          src={item.image}
                          alt={item.name}
                          className="img-fluid rounded img-thumbnail"
                        ></img>{' '}
                        <Link to={`/product/${item.slug}`}>{item.name}</Link>
                      </Col>
                      <Col md={3}>
                        <span>{item.quantity}</span>
                      </Col>
                      <Col md={3}>₪{item.price}</Col>
                    </Row>
                  </ListGroup.Item>
                ))}
              </ListGroup>
              <Link to="/cart">ערוך</Link>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className='mdb-card'>
            <Card.Body  Style="border-radius:3px;"> 
              <Card.Title>סיכום הזמנה</Card.Title>
              <ListGroup variant="flush" >
                <ListGroup.Item Style="background-color: #181a1b;color: white;">
                  <Row>
                    <Col>₪{cart.itemsPrice.toFixed(2)}</Col>
                    <Col>מוצרים</Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item Style="background-color: #181a1b;color: white;">
                  <Row>
                    {/* <Col>₪{cart.shippingPrice.toFixed(2)}</Col> */}
                    {/* <Col>₪{cart.paymentMethod === '30' ? '30' : '0'}</Col> */}
                    {/* <strong>סוג משלוח:</strong> {cart.shippingPrice === '0' && cart.paymentMethod === '0' ? 'משלוח חינם על הזמנה מעל ₪100' : 'משלוח רגיל ₪30'} */}

                    <Col id='freeShippingLabel'>{cart.paymentMethod === '0' && 'חינם'}</Col>
                    <Col id='shippingLabel2'> {cart.paymentMethod === '30' && cart.itemsPrice > 100 && 'חינם'}</Col>
                    <Col id='shippingLabel'> {cart.paymentMethod === '30' && cart.itemsPrice < 100 && '₪30'}</Col>
                    {/* <Col>{cart.shippingPrice === '0' && cart.paymentMethod === '0' ? '₪30' : 'חינם'}</Col> */}
                    <Col > מחיר משלוח</Col>
                  </Row>
                </ListGroup.Item>
                {/* <ListGroup.Item>
                   <Row>
                    <Col>₪{cart.taxPrice.toFixed(2)}</Col>
                    <Col>מיסים</Col>
                  </Row> 
                </ListGroup.Item>*/}
                <ListGroup.Item Style="background-color: #181a1b;color: white;">
                  <Row>
                    <Col>
                      <strong>₪{cart.totalPrice.toFixed(2)}</strong>
                    </Col>
                    <Col>
                      <strong> סה"כ הזמנה</strong>
                    </Col>
                  </Row>
                </ListGroup.Item>
                <br></br>
                <ListGroup.Item Style="background-color: #181a1b;color: white;">
                  <div className="d-grid">
                    <Button
                      type="button"
                      onClick={placeOrderHandler}
                      disabled={cart.cartItems.length === 0}
                    >
                      המשך לתשלום
                    </Button>
                  </div>
                  {loading && <LoadingBox></LoadingBox>}
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
