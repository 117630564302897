import React from 'react';

function Rating(props) {
  const { rating, numReviews, caption } = props;
  return (
    <div className="rating">
      <span>
        <i
          className={
            rating >= 1
              ? 'fas fa-star'
              : rating >= 0.5
                ? 'fas fa-star-half-alt'
                : 'far fa-star'
          }
        />
      </span>
      <span>
        <i
          className={
            rating >= 2
              ? 'fas fa-star'
              : rating >= 1.5
                ? 'fas fa-star-half-alt'
                : 'far fa-star'
          }
        />
      </span>
      <span>
        <i
          className={
            rating >= 3
              ? 'fas fa-star'
              : rating >= 2.5
                ? 'fas fa-star-half-alt'
                : 'far fa-star'
          }
        />
      </span>
      <span>
        <i
          className={
            rating >= 4
              ? 'fas fa-star'
              : rating >= 3.5
                ? 'fas fa-star-half-alt'
                : 'far fa-star'
          }
        />
      </span>
      <span>
        <i
          className={
            rating >= 5
              ? 'fas fa-star'
              : rating >= 4.5
                ? 'fas fa-star-half-alt'
                : 'far fa-star'
          }
        />
      </span>
      {numReviews <= 0 ? (
        <span id="rate">{' אין ביקורות'}</span>
      ) : caption ? (
        <span>{caption}</span>
      ) : (<span id="rate">{' ביקורות ' + numReviews}</span>)}
      {/* {caption ? (
        <span>{caption}</span>
      ) : numReviews <= 0 ? (
        <span id="rate">{' אין ביקורות'}</span>
      ) : (<span id="rate">{' ביקורות ' + numReviews}</span>)} */}
    </div>
  );
}
export default Rating;
