import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { Store } from '../Store';
import CheckoutSteps from '../components/CheckoutSteps';

export default function ShippingAddressScreen() {

  const navigate = useNavigate();
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const {
    fullBox,
    userInfo,
    cart: { shippingAddress },
  } = state;
  const [fullName, setFullName] = useState(shippingAddress.fullName || '');
  const [address, setAddress] = useState(shippingAddress.address || '');
  const [city, setCity] = useState(shippingAddress.city || '');
  const [postalCode, setPostalCode] = useState(shippingAddress.postalCode || '');

  // const cities = [{
  //   title: 'אבו גוש   ',
  //   title: 'אבו סנאן  ',
  //   title: 'אבו סריחאן שבט      ',
  //   title: 'אבו עבדון שבט       ',
  //   title: 'אבו עמאר שבט        ',
  //   title: 'אבו עמרה שבט        ',
  //   title: 'אבו קורינאת שבט     ',
  //   title: 'אבו קרינאת יישוב    ',
  //   title: 'אבו רובייעה שבט     ',
  //   title: 'אבו רוקייק שבט      ',
  //   title: 'אבו תלול  ',
  //   title: 'אבטין     ',
  //   title: 'אבטליון   ',
  //   title: 'אביאל     ',
  //   title: 'אביבים    ',
  //   title: 'אביגדור   ',
  //   title: 'אביחיל    ',
  //   title: 'אביטל     ',
  //   title: 'אביעזר    ',
  //   title: 'אבירים    ',
  //   title: 'אבן יהודה ',
  //   title: 'אבן מנחם  ',
  //   title: 'אבן ספיר  ',
  //   title: 'אבן שמואל ',
  //   title: 'אבני איתן ',
  //   title: 'אבני חפץ  ',
  //   title: 'אבנת      ',
  //   title: 'אבשלום    ',
  //   title: 'אדורה     ',
  //   title: 'אדירים    ',
  //   title: 'אדמית     ',
  //   title: 'אדרת      ',
  //   title: 'אודים     ',
  //   title: 'אודם      ',
  //   title: 'אוהד      ',
  //   title: 'אום אל-פחם',
  //   title: 'אום אל-קוטוף        ',
  //   title: 'אום בטין  ',
  //   title: 'אומן      ',
  //   title: 'אומץ      ',
  //   title: 'אופקים    ',
  //   title: 'אור הגנוז ',
  //   title: 'אור הנר   ',
  //   title: 'אור יהודה ',
  //   title: 'אור עקיבא ',
  //   title: 'אורה      ',
  //   title: 'אורות     ',
  //   title: 'אורטל     ',
  //   title: 'אורים     ',
  //   title: 'אורנים    ',
  //   title: 'אורנית    ',
  //   title: 'אושה      ',
  //   title: 'אזור      ',
  //   title: 'אחווה     ',
  //   title: 'אחוזם     ',
  //   title: 'אחוזת ברק ',
  //   title: 'אחיהוד    ',
  //   title: 'אחיטוב    ',
  //   title: 'אחיסמך    ',
  //   title: 'אחיעזר    ',
  //   title: 'אטרש שבט  ',
  //   title: 'איבים     ',
  //   title: 'אייל      ',
  //   title: 'איילת השחר',
  //   title: 'אילון     ',
  //   title: 'אילות     ',
  //   title: 'אילניה    ',
  //   title: 'אילת      ',
  //   title: 'אירוס     ',
  //   title: 'איתמר     ',
  //   title: 'איתן      ',
  //   title: 'איתנים    ',
  //   title: 'אכסאל     ',
  //   title: 'אל סייד   ',
  //   title: 'אל-עזי    ',
  //   title: 'אל-עריאן  ',
  //   title: 'אל-רום    ',
  //   title: 'אלומה     ',
  //   title: 'אלומות    ',
  //   title: 'אלון הגליל',
  //   title: 'אלון מורה ',
  //   title: 'אלון שבות ',
  //   title: 'אלוני אבא ',
  //   title: 'אלוני הבשן',
  //   title: 'אלוני יצחק',
  //   title: 'אלונים    ',
  //   title: 'אלי-עד    ',
  //   title: 'אליאב     ',
  //   title: 'אליכין    ',
  //   title: 'אליפז     ',
  //   title: 'אליפלט    ',
  //   title: 'אליקים    ',
  //   title: 'אלישיב    ',
  //   title: 'אלישמע    ',
  //   title: 'אלמגור    ',
  //   title: 'אלמוג     ',
  //   title: 'אלעד      ',
  //   title: 'אלעזר     ',
  //   title: 'אלפי מנשה ',
  //   title: 'אלקוש     ',
  //   title: 'אלקנה     ',
  //   title: 'אמונים    ',
  //   title: 'אמירים    ',
  //   title: 'אמנון     ',
  //   title: 'אמציה     ',
  //   title: 'אניעם     ',
  //   title: 'אסד שבט   ',
  //   title: 'אספר      ',
  //   title: 'אעבלין    ',
  //   title: 'אעצם שבט  ',
  //   title: 'אפיניש שבט',
  //   title: 'אפיק      ',
  //   title: 'אפיקים    ',
  //   title: 'אפק       ',
  //   title: 'אפרת      ',
  //   title: 'ארבל      ',
  //   title: 'ארגמן     ',
  //   title: 'ארז       ',
  //   title: 'אריאל     ',
  //   title: 'ארסוף     ',
  //   title: 'אשבול     ',
  //   title: 'אשבל      ',
  //   title: 'אשדוד     ',
  //   title: 'אשדות יעקב  איחוד   ',
  //   title: 'אשדות יעקב  מאוחד   ',
  //   title: 'אשחר      ',
  //   title: 'אשכולות   ',
  //   title: 'אשל הנשיא ',
  //   title: 'אשלים     ',
  //   title: 'אשקלון    ',
  //   title: 'אשרת      ',
  //   title: 'אשתאול    ',
  //   title: 'אתגר      ',
  //   title: 'באקה אל-גרביה       ',
  //   title: 'באר אורה  ',
  //   title: 'באר גנים  ',
  //   title: 'באר טוביה ',
  //   title: 'באר יעקב  ',
  //   title: 'באר מילכה ',
  //   title: 'באר שבע   ',
  //   title: 'בארות יצחק',
  //   title: 'בארותיים  ',
  //   title: 'בארי      ',
  //   title: 'בוסתן הגליל         ',
  //   title: 'בוקעאתא   ',
  //   title: 'בורגתה    ',
  //   title: 'בחן       ',
  //   title: 'בטחה      ',
  //   title: 'ביצרון    ',
  //   title: 'ביר אל-מכסור        ',
  //   title: 'ביר הדאג',
  //   title: 'ביריה     ',
  //   title: 'בית אורן  ',
  //   title: 'בית אל    ',
  //   title: 'בית אלעזרי',
  //   title: 'בית אלפא  ',
  //   title: 'בית אריה  ',
  //   title: 'בית ברל   ',
  //   title: 'בית גן   ',
  //   title: 'בית גוברין',
  //   title: 'בית גמליאל',
  //   title: 'בית דגן   ',
  //   title: 'בית הגדי  ',
  //   title: 'בית הלוי  ',
  //   title: 'בית הלל   ',
  //   title: 'בית העמק  ',
  //   title: 'בית הערבה ',
  //   title: 'בית השיטה ',
  //   title: 'בית זיד   ',
  //   title: 'בית זית   ',
  //   title: 'בית זרע   ',
  //   title: 'בית חורון ',
  //   title: 'בית חירות ',
  //   title: 'בית חלקיה ',
  //   title: 'בית חנן   ',
  //   title: 'בית חנניה ',
  //   title: 'בית חשמונאי         ',
  //   title: 'בית יהושע ',
  //   title: 'בית יוסף  ',
  //   title: 'בית ינאי  ',
  //   title: 'בית יצחק-שער חפר    ',
  //   title: 'בית לחם הגלילית     ',
  //   title: 'בית מאיר  ',
  //   title: 'בית נחמיה ',
  //   title: 'בית ניר   ',
  //   title: 'בית נקופה ',
  //   title: 'בית עובד  ',
  //   title: 'בית עוזיאל',
  //   title: 'בית עזרא  ',
  //   title: 'בית עריף  ',
  //   title: 'בית צבי   ',
  //   title: 'בית קמה   ',
  //   title: 'בית קשת   ',
  //   title: 'בית רבן   ',
  //   title: 'בית רימון ',
  //   title: 'בית שאן   ',
  //   title: 'בית שמש   ',
  //   title: 'בית שערים ',
  //   title: 'בית שקמה  ',
  //   title: 'ביתן אהרן ',
  //   title: 'ביתר עילית',
  //   title: 'בלפוריה   ',
  //   title: 'בן זכאי   ',
  //   title: 'בן עמי    ',
  //   title: 'בן שמן כפר נוער     ',
  //   title: 'בן שמן מושב         ',
  //   title: 'בני ברק   ',
  //   title: 'בני דקלים ',
  //   title: 'בני דרום  ',
  //   title: 'בני דרור  ',
  //   title: 'בני יהודה ',
  //   title: 'בני נצרים ',
  //   title: 'בני עטרות ',
  //   title: 'בני עיש   ',
  //   title: 'בני ציון  ',
  //   title: 'בני ראם   ',
  //   title: 'בניה      ',
  //   title: 'בנימינה-גבעת עדה    ',
  //   title: 'בסמה      ',
  //   title: 'בסמת טבעון',
  //   title: 'בענה      ',
  //   title: 'בצרה      ',
  //   title: 'בצת       ',
  //   title: 'בקוע      ',
  //   title: 'בקעות     ',
  //   title: 'בר גיורא  ',
  //   title: 'בר יוחאי  ',
  //   title: 'ברוכין    ',
  //   title: 'ברור חיל  ',
  //   title: 'ברוש      ',
  //   title: 'ברכה      ',
  //   title: 'ברכיה     ',
  //   title: 'ברעם      ',
  //   title: 'ברק       ',
  //   title: 'ברקאי     ',
  //   title: 'ברקן      ',
  //   title: 'ברקת      ',
  //   title: 'בת הדר    ',
  //   title: 'בת חן     ',
  //   title: 'בת חפר    ',
  //   title: 'בת חצור   ',
  //   title: 'בת ים     ',
  //   title: 'בת עין    ',
  //   title: 'בת שלמה   ',
  //   title: ' גוש חלב',
  //   title: 'גאולי תימן',
  //   title: 'גאולים    ',
  //   title: 'גאליה     ',
  //   title: 'גבולות    ',
  //   title: 'גבים      ',
  //   title: 'גבע       ',
  //   title: 'גבע בנימין',
  //   title: 'גבע כרמל  ',
  //   title: 'גבעולים   ',
  //   title: 'גבעון החדשה         ',
  //   title: 'גבעות בר  ',
  //   title: 'גבעת אבני ',
  //   title: 'גבעת אלה  ',
  //   title: 'גבעת ברנר ',
  //   title: 'גבעת השלושה         ',
  //   title: 'גבעת זאב  ',
  //   title: 'גבעת חן   ',
  //   title: 'גבעת חיים איחוד     ',
  //   title: 'גבעת חיים מאוחד     ',
  //   title: 'גבעת יואב ',
  //   title: 'גבעת יערים',
  //   title: 'גבעת ישעיהו         ',
  //   title: 'גבעת כח   ',
  //   title: 'גבעת נילי ',
  //   title: 'גבעת עוז  ',
  //   title: 'גבעת שמואל',
  //   title: 'גבעת שמש  ',
  //   title: 'גבעת שפירא',
  //   title: 'גבעתי     ',
  //   title: 'גבעתיים   ',
  //   title: 'גברעם     ',
  //   title: 'גבת       ',
  //   title: 'גדות      ',
  //   title: 'גדיש      ',
  //   title: 'גדעונה    ',
  //   title: 'גדרה      ',
  //   title: 'גונן      ',
  //   title: 'גורן      ',
  //   title: 'גורנות הגליל        ',
  //   title: 'גזית      ',
  //   title: 'גזר       ',
  //   title: 'גיאה      ',
  //   title: 'גיבתון    ',
  //   title: 'גיזו      ',
  //   title: 'גילון     ',
  //   title: 'גילת      ',
  //   title: 'גינוסר    ',
  //   title: 'גיניגר    ',
  //   title: 'גינתון    ',
  //   title: 'גיתה      ',
  //   title: 'גיתית     ',
  //   title: 'גלאון     ',
  //   title: 'גלגל      ',
  //   title: 'גליל ים   ',
  //   title: 'גלעד אבן יצחק       ',
  //   title: 'גמזו      ',
  //   title: 'גן הדרום  ',
  //   title: 'גן השומרון',
  //   title: 'גן חיים   ',
  //   title: 'גן יאשיה  ',
  //   title: 'גן יבנה   ',
  //   title: 'גן נר     ',
  //   title: 'גן שורק   ',
  //   title: 'גן שלמה   ',
  //   title: 'גן שמואל  ',
  //   title: 'גנות      ',
  //   title: 'גנות הדר  ',
  //   title: 'גני הדר   ',
  //   title: 'גני טל    ',
  //   title: 'גני יוחנן ',
  //   title: 'גני מודיעין         ',
  //   title: 'גני עם    ',
  //   title: 'גני תקווה ',
  //   title: 'געש       ',
  //   title: 'געתון     ',
  //   title: 'גפן       ',
  //   title: 'גרופית    ',
  //   title: 'גשור      ',
  //   title: 'גשר       ',
  //   title: 'גשר הזיו  ',
  //   title: 'גת קיבוץ  ',
  //   title: 'גת רימון  ',
  //   title: 'דאלית אל-כרמל       ',
  //   title: 'דבורה     ',
  //   title: 'דבוריה    ',
  //   title: 'דבירה     ',
  //   title: 'דברת      ',
  //   title: 'דגניה א  ',
  //   title: 'דגניה ב  ',
  //   title: 'דובב      ',
  //   title: 'דולב      ',
  //   title: 'דור       ',
  //   title: 'דורות     ',
  //   title: 'דחי       ',
  //   title: 'דייר אל-אסד         ',
  //   title: 'דייר חנא  ',
  //   title: 'דייר ראפאת',
  //   title: 'דימונה    ',
  //   title: 'דישון     ',
  //   title: 'דליה      ',
  //   title: 'דלתון     ',
  //   title: 'דמיידה    ',
  //   title: 'דן        ',
  //   title: 'דפנה      ',
  //   title: 'דקל       ',
  //   title: 'האון      ',
  //   title: 'הבונים    ',
  //   title: 'הגושרים   ',
  //   title: 'הדר עם    ',
  //   title: 'הוד השרון ',
  //   title: 'הודיה     ',
  //   title: 'הודיות    ',
  //   title: 'הוואשלה שבט         ',
  //   title: 'הוזייל שבט',
  //   title: 'הושעיה    ',
  //   title: 'הזורע     ',
  //   title: 'הזורעים   ',
  //   title: 'החותרים   ',
  //   title: 'היוגב     ',
  //   title: 'הילה      ',
  //   title: 'המעפיל    ',
  //   title: 'הסוללים   ',
  //   title: 'העוגן     ',
  //   title: 'הר אדר    ',
  //   title: 'הר גילה   ',
  //   title: 'הר עמשא   ',
  //   title: 'הראל      ',
  //   title: 'הרדוף     ',
  //   title: 'הרצליה    ',
  //   title: 'הררית     ',
  //   title: 'ורד יריחו ',
  //   title: 'ורדון     ',
  //   title: 'זבארגה שבט',
  //   title: 'זבדיאל    ',
  //   title: 'זוהר      ',
  //   title: 'זיקים     ',
  //   title: 'זיתן      ',
  //   title: 'זכרון יעקב',
  //   title: 'זכריה     ',
  //   title: 'זמר       ',
  //   title: 'זמרת      ',
  //   title: 'זנוח      ',
  //   title: 'זרועה     ',
  //   title: 'זרזיר     ',
  //   title: 'זרחיה     ',
  //   title: 'חבצלת השרון         ',
  //   title: 'חבר       ',
  //   title: 'חברון     ',
  //   title: 'חגור      ',
  //   title: 'חגי       ',
  //   title: 'חגלה      ',
  //   title: 'חד-נס     ',
  //   title: 'חדיד      ',
  //   title: 'חדרה      ',
  //   title: 'חולדה     ',
  //   title: 'חולון     ',
  //   title: 'חולית     ',
  //   title: 'חולתה     ',
  //   title: 'חוסן      ',
  //   title: 'חוסנייה   ',
  //   title: 'חופית     ',
  //   title: 'חוקוק     ',
  //   title: 'חורה      ',
  //   title: 'חורפיש    ',
  //   title: 'חורשים    ',
  //   title: 'חזון      ',
  //   title: 'חיבת ציון ',
  //   title: 'חיננית    ',
  //   title: 'חיפה      ',
  //   title: 'חירות     ',
  //   title: 'חלוץ      ',
  //   title: 'חלמיש     ',
  //   title: 'חלץ       ',
  //   title: 'חמאם      ',
  //   title: 'חמד       ',
  //   title: 'חמדיה     ',
  //   title: 'חמדת      ',
  //   title: 'חמרה      ',
  //   title: 'חניאל     ',
  //   title: 'חניתה     ',
  //   title: 'חנתון     ',
  //   title: 'חספין     ',
  //   title: 'חפץ חיים  ',
  //   title: 'חפצי-בה   ',
  //   title: 'חצב       ',
  //   title: 'חצבה      ',
  //   title: 'חצור הגלילית        ',
  //   title: 'חצור-אשדוד',
  //   title: 'חצר בארותיים        ',
  //   title: 'חצרות חולדה         ',
  //   title: 'חצרות יסף ',
  //   title: 'חצרות כח  ',
  //   title: 'חצרים     ',
  //   title: 'חרב לאת   ',
  //   title: 'חרוצים    ',
  //   title: 'חריש      ',
  //   title: 'חרמש      ',
  //   title: 'חרשים     ',
  //   title: 'חשמונאים  ',
  //   title: 'טבריה     ',
  //   title: 'טובא-זנגריה         ',
  //   title: 'טורעאן    ',
  //   title: 'טייבה     ',
  //   title: 'טייבה בעמק',
  //   title: 'טירה      ',
  //   title: 'טירת יהודה',
  //   title: 'טירת כרמל ',
  //   title: 'טירת צבי  ',
  //   title: 'טל שחר    ',
  //   title: 'טל-אל     ',
  //   title: 'טללים     ',
  //   title: 'טלמון     ',
  //   title: 'טמרה      ',
  //   title: 'טמרה יזרעאל         ',
  //   title: 'טנא       ',
  //   title: 'טפחות     ',
  //   title: 'יבול      ',
  //   title: 'יבנאל     ',
  //   title: 'יבנה      ',
  //   title: 'יגור      ',
  //   title: 'יגל       ',
  //   title: 'יד בנימין ',
  //   title: 'יד השמונה ',
  //   title: 'יד חנה    ',
  //   title: 'יד מרדכי  ',
  //   title: 'יד נתן    ',
  //   title: 'יד רמבם   ',
  //   title: 'ידידה     ',
  //   title: 'יהוד-מונוסון        ',
  //   title: 'יהל       ',
  //   title: 'יובל      ',
  //   title: 'יובלים    ',
  //   title: 'יודפת     ',
  //   title: 'יונתן     ',
  //   title: 'יושיביה   ',
  //   title: 'יזרעאל    ',
  //   title: 'יחיעם     ',
  //   title: 'יטבתה     ',
  //   title: 'ייטב      ',
  //   title: 'יכיני     ',
  //   title: 'ינוב      ',
  //   title: 'ינון      ',
  //   title: 'יסוד המעלה',
  //   title: 'יסודות    ',
  //   title: 'יסעור     ',
  //   title: 'יעד       ',
  //   title: 'יעל       ',
  //   title: 'יעף       ',
  //   title: 'יערה      ',
  //   title: 'יפיע      ',
  //   title: 'יפית      ',
  //   title: 'יפעת      ',
  //   title: 'יפתח      ',
  //   title: 'יצהר      ',
  //   title: 'יציץ      ',
  //   title: 'יקום      ',
  //   title: 'יקיר      ',
  //   title: 'יקנעם מושבה         ',
  //   title: 'יקנעם עילית         ',
  //   title: 'יראון     ',
  //   title: 'ירדנה     ',
  //   title: 'ירוחם     ',
  //   title: 'ירושלים   ',
  //   title: 'ירחיב     ',
  //   title: 'ירכא      ',
  //   title: 'ירקונה    ',
  //   title: 'ישע       ',
  //   title: 'ישעי      ',
  //   title: 'ישרש      ',
  //   title: 'יתד       ',
  //   title: 'יתיר      ',
  //   title: 'כאבול     ',
  //   title: 'כברי      ',
  //   title: 'כדורי     ',
  //   title: 'כדיתה     ',
  //   title: 'כוכב השחר ',
  //   title: 'כוכב יאיר ',
  //   title: 'כוכב יעקב ',
  //   title: 'כוכב מיכאל',
  //   title: 'כורזים    ',
  //   title: 'כחל       ',
  //   title: 'כחלה      ',
  //   title: 'כיסופים   ',
  //   title: 'כישור     ',
  //   title: 'כליל      ',
  //   title: 'כלנית     ',
  //   title: 'כמאנה     ',
  //   title: 'כמהין     ',
  //   title: 'כמון      ',
  //   title: 'כנות      ',
  //   title: 'כנף       ',
  //   title: 'כנרת מושבה',
  //   title: 'כנרת קבוצה',
  //   title: 'כסיפה     ',
  //   title: 'כסלון     ',
  //   title: 'כסרא-סמיע ',
  //   title: 'כפר אביב  ',
  //   title: 'כפר אדומים',
  //   title: 'כפר אוריה ',
  //   title: 'כפר אחים  ',
  //   title: 'כפר ביאליק',
  //   title: 'כפר בילו  ',
  //   title: 'כפר בלום  ',
  //   title: 'כפר בן נון',
  //   title: 'כפר ברא   ',
  //   title: 'כפר ברוך  ',
  //   title: 'כפר גדעון ',
  //   title: 'כפר גלים  ',
  //   title: 'כפר גליקסון         ',
  //   title: 'כפר גלעדי ',
  //   title: 'כפר דניאל ',
  //   title: 'כפר האורנים         ',
  //   title: 'כפר החורש ',
  //   title: 'כפר המכבי ',
  //   title: 'כפר הנגיד ',
  //   title: 'כפר הנוער הדתי      ',
  //   title: 'כפר הנשיא ',
  //   title: 'כפר הס    ',
  //   title: 'כפר הראה  ',
  //   title: 'כפר הריף  ',
  //   title: 'כפר ויתקין',
  //   title: 'כפר ורבורג',
  //   title: 'כפר ורדים ',
  //   title: 'כפר זוהרים',
  //   title: 'כפר זיתים ',
  //   title: 'כפר חבד   ',
  //   title: 'כפר חושן  ',
  //   title: 'כפר חיטים ',
  //   title: 'כפר חיים  ',
  //   title: 'כפר חנניה ',
  //   title: 'כפר חרוב  ',
  //   title: 'כפר טרומן ',
  //   title: 'כפר יאסיף ',
  //   title: 'כפר ידידיה',
  //   title: 'כפר יהושע ',
  //   title: 'כפר יונה  ',
  //   title: 'כפר יחזקאל',
  //   title: 'כפר יעבץ  ',
  //   title: 'כפר כמא   ',
  //   title: 'כפר כנא   ',
  //   title: 'כפר מונש  ',
  //   title: 'כפר מימון ',
  //   title: 'כפר מלל   ',
  //   title: 'כפר מנדא  ',
  //   title: 'כפר מנחם  ',
  //   title: 'כפר מסריק ',
  //   title: 'כפר מצר   ',
  //   title: 'כפר מרדכי ',
  //   title: 'כפר נטר   ',
  //   title: 'כפר סאלד  ',
  //   title: 'כפר סבא   ',
  //   title: 'כפר סילבר ',
  //   title: 'כפר סירקין',
  //   title: 'כפר עבודה ',
  //   title: 'כפר עזה   ',
  //   title: 'כפר עציון ',
  //   title: 'כפר פינס  ',
  //   title: 'כפר קאסם  ',
  //   title: 'כפר קיש   ',
  //   title: 'כפר קרע   ',
  //   title: 'כפר ראש הנקרה       ',
  //   title: 'כפר רוזנואלד זרעי   ',
  //   title: 'כפר רופין ',
  //   title: 'כפר רות   ',
  //   title: 'כפר שמאי  ',
  //   title: 'כפר שמואל ',
  //   title: 'כפר שמריהו',
  //   title: 'כפר תבור  ',
  //   title: 'כפר תפוח  ',
  //   title: 'כרי דשא   ',
  //   title: 'כרכום     ',
  //   title: 'כרם בן זמרה         ',
  //   title: 'כרם בן שמן',
  //   title: 'כרם יבנה ישיבה      ',
  //   title: 'כרם מהרל  ',
  //   title: 'כרם שלום  ',
  //   title: 'כרמי יוסף ',
  //   title: 'כרמי צור  ',
  //   title: 'כרמי קטיף ',
  //   title: 'כרמיאל    ',
  //   title: 'כרמיה     ',
  //   title: 'כרמים     ',
  //   title: 'כרמל      ',
  //   title: 'לא רשום   ',
  //   title: 'לבון      ',
  //   title: 'לביא      ',
  //   title: 'לבנים     ',
  //   title: 'להב       ',
  //   title: 'להבות הבשן',
  //   title: 'להבות חביבה         ',
  //   title: 'להבים     ',
  //   title: 'לוד       ',
  //   title: 'לוזית     ',
  //   title: 'לוחמי הגיטאות       ',
  //   title: 'לוטם      ',
  //   title: 'לוטן      ',
  //   title: 'לימן      ',
  //   title: 'לכיש      ',
  //   title: 'לפיד      ',
  //   title: 'לפידות    ',
  //   title: 'לקיה      ',
  //   title: 'מאור      ',
  //   title: 'מאיר שפיה ',
  //   title: 'מבוא ביתר ',
  //   title: 'מבוא דותן ',
  //   title: 'מבוא חורון',
  //   title: 'מבוא חמה  ',
  //   title: 'מבוא מודיעים        ',
  //   title: 'מבואות ים ',
  //   title: 'מבועים    ',
  //   title: 'מבטחים    ',
  //   title: 'מבקיעים   ',
  //   title: 'מבשרת ציון',
  //   title: 'מגאר      ',
  //   title: 'מגדים     ',
  //   title: 'מגדל      ',
  //   title: 'מגדל העמק ',
  //   title: 'מגדל עוז  ',
  //   title: 'מגדלים    ',
  //   title: 'מגידו     ',
  //   title: 'מגל       ',
  //   title: 'מגן       ',
  //   title: 'מגן שאול  ',
  //   title: 'מגשימים   ',
  //   title: 'מדרך עוז  ',
  //   title: 'מדרשת בן גוריון     ',
  //   title: 'מדרשת רופין         ',
  //   title: 'מודיעין עילית       ',
  //   title: 'מודיעין-מכבים-רעו   ',
  //   title: 'מולדת     ',
  //   title: 'מוצא עילית',
  //   title: 'מוקייבלה  ',
  //   title: 'מורן      ',
  //   title: 'מורשת     ',
  //   title: 'מזור      ',
  //   title: 'מזכרת בתיה',
  //   title: 'מזרע      ',
  //   title: 'מזרעה     ',
  //   title: 'מחולה     ',
  //   title: 'מחנה הילה ',
  //   title: 'מחנה טלי  ',
  //   title: 'מחנה יהודית         ',
  //   title: 'מחנה יוכבד',
  //   title: 'מחנה יפה  ',
  //   title: 'מחנה יתיר ',
  //   title: 'מחנה מרים ',
  //   title: 'מחנה תל נוף         ',
  //   title: 'מחניים    ',
  //   title: 'מחסיה     ',
  //   title: 'מטולה     ',
  //   title: 'מטע       ',
  //   title: 'מי עמי    ',
  //   title: 'מיטב      ',
  //   title: 'מייסר     ',
  //   title: 'מיצר      ',
  //   title: 'מירב      ',
  //   title: 'מירון     ',
  //   title: 'מישר      ',
  //   title: 'מיתר      ',
  //   title: 'מכורה     ',
  //   title: 'מכחול     ',
  //   title: 'מכמורת    ',
  //   title: 'מכמנים    ',
  //   title: 'מלאה      ',
  //   title: 'מלילות    ',
  //   title: 'מלכיה     ',
  //   title: 'מלכישוע   ',
  //   title: 'מנוחה     ',
  //   title: 'מנוף      ',
  //   title: 'מנות      ',
  //   title: 'מנחמיה    ',
  //   title: 'מנרה      ',
  //   title: 'מנשית זבדה',
  //   title: 'מסד       ',
  //   title: 'מסדה      ',
  //   title: 'מסילות    ',
  //   title: 'מסילת ציון',
  //   title: 'מסלול     ',
  //   title: 'מסעדה     ',
  //   title: 'מסעודין אל-עזאזמה   ',
  //   title: 'מעברות    ',
  //   title: 'מעגלים    ',
  //   title: 'מעגן      ',
  //   title: 'מעגן מיכאל',
  //   title: 'מעוז חיים ',
  //   title: 'מעון      ',
  //   title: 'מעונה     ',
  //   title: 'מעיליא    ',
  //   title: 'מעין ברוך ',
  //   title: 'מעין צבי  ',
  //   title: 'מעלה אדומים         ',
  //   title: 'מעלה אפרים',
  //   title: 'מעלה גלבוע',
  //   title: 'מעלה גמלא ',
  //   title: 'מעלה החמישה         ',
  //   title: 'מעלה לבונה',
  //   title: 'מעלה מכמש ',
  //   title: 'מעלה עירון',
  //   title: 'מעלה עמוס ',
  //   title: 'מעלה שומרון         ',
  //   title: 'מעלות-תרשיחא        ',
  //   title: 'מענית     ',
  //   title: 'מעש       ',
  //   title: 'מפלסים    ',
  //   title: 'מצדות יהודה         ',
  //   title: 'מצובה     ',
  //   title: 'מצליח     ',
  //   title: 'מצפה      ',
  //   title: 'מצפה אביב ',
  //   title: 'מצפה אילן ',
  //   title: 'מצפה יריחו',
  //   title: 'מצפה נטופה',
  //   title: 'מצפה רמון ',
  //   title: 'מצפה שלם  ',
  //   title: 'מצר       ',
  //   title: 'מקווה ישראל         ',
  //   title: 'מרגליות   ',
  //   title: 'מרום גולן ',
  //   title: 'מרחב עם   ',
  //   title: 'מרחביה מושב         ',
  //   title: 'מרחביה קיבוץ        ',
  //   title: 'מרכז שפירא',
  //   title: 'משאבי שדה ',
  //   title: 'משגב דב   ',
  //   title: 'משגב עם   ',
  //   title: 'משהד      ',
  //   title: 'משואה     ',
  //   title: 'משואות יצחק         ',
  //   title: 'משכיות    ',
  //   title: 'משמר איילון         ',
  //   title: 'משמר דוד  ',
  //   title: 'משמר הירדן',
  //   title: 'משמר הנגב ',
  //   title: 'משמר העמק ',
  //   title: 'משמר השבעה',
  //   title: 'משמר השרון',
  //   title: 'משמרות    ',
  //   title: 'משמרת     ',
  //   title: 'משען      ',
  //   title: 'מתן       ',
  //   title: 'מתת       ',
  //   title: 'מתתיהו    ',
  //   title: 'נאות גולן ',
  //   title: 'נאות הכיכר',
  //   title: 'נאות מרדכי',
  //   title: 'נאות סמדר ',
  //   title: 'נאעורה    ',
  //   title: 'נבטים     ',
  //   title: 'נגבה      ',
  //   title: 'נגוהות    ',
  //   title: 'נהורה     ',
  //   title: 'נהלל      ',
  //   title: 'נהריה     ',
  //   title: 'נוב       ',
  //   title: 'נוגה      ',
  //   title: 'נווה      ',
  //   title: 'נווה אבות ',
  //   title: 'נווה אור  ',
  //   title: 'נווה אטיב ',
  //   title: 'נווה אילן ',
  //   title: 'נווה איתן ',
  //   title: 'נווה דניאל',
  //   title: 'נווה זוהר ',
  //   title: 'נווה זיו  ',
  //   title: 'נווה חריף ',
  //   title: 'נווה ים   ',
  //   title: 'נווה ימין ',
  //   title: 'נווה ירק  ',
  //   title: 'נווה מבטח ',
  //   title: 'נווה מיכאל',
  //   title: 'נווה שלום ',
  //   title: 'נועם      ',
  //   title: 'נוף איילון',
  //   title: 'נופים     ',
  //   title: 'נופית     ',
  //   title: 'נופך      ',
  //   title: 'נוקדים    ',
  //   title: 'נורדיה    ',
  //   title: 'נורית     ',
  //   title: 'נחושה     ',
  //   title: 'נחל עוז   ',
  //   title: 'נחלה      ',
  //   title: 'נחליאל    ',
  //   title: 'נחלים     ',
  //   title: 'נחם       ',
  //   title: 'נחף       ',
  //   title: 'נחשולים   ',
  //   title: 'נחשון     ',
  //   title: 'נחשונים   ',
  //   title: 'נטועה     ',
  //   title: 'נטור      ',
  //   title: 'נטע       ',
  //   title: 'נטעים     ',
  //   title: 'נטף       ',
  //   title: 'ניין      ',
  //   title: 'נילי      ',
  //   title: 'ניצן      ',
  //   title: 'ניצני סיני',
  //   title: 'ניצני עוז ',
  //   title: 'ניצנים    ',
  //   title: 'ניר אליהו ',
  //   title: 'ניר בנים  ',
  //   title: 'ניר גלים  ',
  //   title: 'ניר דוד תל עמל      ',
  //   title: 'ניר חן    ',
  //   title: 'ניר יפה   ',
  //   title: 'ניר יצחק  ',
  //   title: 'ניר ישראל ',
  //   title: 'ניר משה   ',
  //   title: 'ניר עוז   ',
  //   title: 'ניר עם    ',
  //   title: 'ניר עציון ',
  //   title: 'ניר עקיבא ',
  //   title: 'ניר צבי   ',
  //   title: 'נירים     ',
  //   title: 'נירית     ',
  //   title: 'נירן      ',
  //   title: 'נמרוד     ',
  //   title: 'נס הרים   ',
  //   title: 'נס עמים   ',
  //   title: 'נס ציונה  ',
  //   title: 'נעורים    ',
  //   title: 'נעלה      ',
  //   title: 'נעמה      ',
  //   title: 'נען       ',
  //   title: 'נצאצרה שבט',
  //   title: 'נצר חזני  ',
  //   title: 'נצר סרני  ',
  //   title: 'נצרת      ',
  //   title: 'נצרת עילית',
  //   title: 'נשר       ',
  //   title: 'נתיב הגדוד',
  //   title: 'נתיב הלה  ',
  //   title: 'נתיב העשרה',
  //   title: 'נתיב השיירה         ',
  //   title: 'נתיבות    ',
  //   title: 'נתניה     ',
  //   title: 'סאסא      ',
  //   title: 'סביון     ',
  //   title: 'סגולה     ',
  //   title: 'סואעד חמרייה        ',
  //   title: 'סואעד כמאנה שבט     ',
  //   title: 'סולם      ',
  //   title: 'סוסיה     ',
  //   title: 'סופה      ',
  //   title: 'סייד שבט  ',
  //   title: 'סלמה      ',
  //   title: 'סלעית     ',
  //   title: 'סמר       ',
  //   title: 'סנסנה     ',
  //   title: 'סעד       ',
  //   title: 'סעוה      ',
  //   title: 'סער       ',
  //   title: 'ספיר      ',
  //   title: 'סתריה     ',
  //   title: 'עבדון     ',
  //   title: 'עברון     ',
  //   title: 'עגור      ',
  //   title: 'עדי       ',
  //   title: 'עדנים     ',
  //   title: 'עוזה      ',
  //   title: 'עוזייר    ',
  //   title: 'עולש      ',
  //   title: 'עומר      ',
  //   title: 'עופר      ',
  //   title: 'עופרה     ',
  //   title: 'עוצם      ',
  //   title: 'עוקבי בנו עוקבה     ',
  //   title: 'עזוז      ',
  //   title: 'עזר       ',
  //   title: 'עזריאל    ',
  //   title: 'עזריה     ',
  //   title: 'עזריקם    ',
  //   title: 'עטאוונה שבט         ',
  //   title: 'עטרת      ',
  //   title: 'עידן      ',
  //   title: 'עיילבון   ',
  //   title: 'עיינות    ',
  //   title: 'עילוט     ',
  //   title: 'עין איילה ',
  //   title: 'עין אל-אסד',
  //   title: 'עין גב    ',
  //   title: 'עין גדי   ',
  //   title: 'עין דור   ',
  //   title: 'עין הבשור ',
  //   title: 'עין הוד   ',
  //   title: 'עין החורש ',
  //   title: 'עין המפרץ ',
  //   title: 'עין הנציב ',
  //   title: 'עין העמק  ',
  //   title: 'עין השופט ',
  //   title: 'עין השלושה',
  //   title: 'עין ורד   ',
  //   title: 'עין זיוון ',
  //   title: 'עין חוד   ',
  //   title: 'עין חצבה  ',
  //   title: 'עין חרוד איחוד      ',
  //   title: 'עין חרוד מאוחד      ',
  //   title: 'עין יהב   ',
  //   title: 'עין יעקב  ',
  //   title: 'עין כרם-ביס חקלא    ',
  //   title: 'עין כרמל  ',
  //   title: 'עין מאהל  ',
  //   title: 'עין נקובא ',
  //   title: 'עין עירון ',
  //   title: 'עין צורים ',
  //   title: 'עין קנייא ',
  //   title: 'עין ראפה  ',
  //   title: 'עין שמר   ',
  //   title: 'עין שריד  ',
  //   title: 'עין תמר   ',
  //   title: 'עינת      ',
  //   title: 'עיר אובות ',
  //   title: 'עכו       ',
  //   title: 'עלומים    ',
  //   title: 'עלי       ',
  //   title: 'עלי זהב   ',
  //   title: 'עלמה      ',
  //   title: 'עלמון     ',
  //   title: 'עמוקה     ',
  //   title: 'עמיחי     ',
  //   title: 'עמינדב    ',
  //   title: 'עמיעד     ',
  //   title: 'עמיעוז    ',
  //   title: 'עמיקם     ',
  //   title: 'עמיר      ',
  //   title: 'עמנואל    ',
  //   title: 'עמקה      ',
  //   title: 'ענב       ',
  //   title: 'עספיא     ',
  //   title: 'עפולה     ',
  //   title: 'עץ אפרים  ',
  //   title: 'עצמון שגב ',
  //   title: 'עראבה     ',
  //   title: 'עראמשה    ',
  //   title: 'ערב אל נעים         ',
  //   title: 'ערד       ',
  //   title: 'ערוגות    ',
  //   title: 'ערערה     ',
  //   title: 'ערערה-בנגב',
  //   title: 'עשרת      ',
  //   title: 'עתלית     ',
  //   title: 'עתניאל    ',
  //   title: 'פארן      ',
  //   title: 'פדואל     ',
  //   title: 'פדויים    ',
  //   title: 'פדיה      ',
  //   title: 'פוריה - כפר עבודה   ',
  //   title: 'פוריה - נווה עובד   ',
  //   title: 'פוריה עילית         ',
  //   title: 'פוריידיס  ',
  //   title: 'פורת      ',
  //   title: 'פטיש      ',
  //   title: 'פלך       ',
  //   title: 'פלמחים    ',
  //   title: 'פני חבר   ',
  //   title: 'פסגות     ',
  //   title: 'פסוטה     ',
  //   title: 'פעמי תשז  ',
  //   title: 'פצאל      ',
  //   title: 'פקיעין בוקייעה      ',
  //   title: 'פקיעין חדשה         ',
  //   title: 'פרדס חנה-כרכור      ',
  //   title: 'פרדסיה    ',
  //   title: 'פרוד      ',
  //   title: 'פרזון     ',
  //   title: 'פרי גן    ',
  //   title: 'פתח תקווה ',
  //   title: 'פתחיה     ',
  //   title: 'צאלים     ',
  //   title: 'צביה      ',
  //   title: 'צבעון     ',
  //   title: 'צובה      ',
  //   title: 'צוחר      ',
  //   title: 'צופיה     ',
  //   title: 'צופים     ',
  //   title: 'צופית     ',
  //   title: 'צופר      ',
  //   title: 'צוקי ים   ',
  //   title: 'צוקים     ',
  //   title: 'צור הדסה  ',
  //   title: 'צור יצחק  ',
  //   title: 'צור משה   ',
  //   title: 'צור נתן   ',
  //   title: 'צוריאל    ',
  //   title: 'צורית     ',
  //   title: 'ציפורי    ',
  //   title: 'צלפון     ',
  //   title: 'צנדלה     ',
  //   title: 'צפריה     ',
  //   title: 'צפרירים   ',
  //   title: 'צפת       ',
  //   title: 'צרופה     ',
  //   title: 'צרעה      ',
  //   title: 'קבועה שבט ',
  //   title: 'קבוצת יבנה',
  //   title: 'קדומים    ',
  //   title: 'קדימה-צורן',
  //   title: 'קדמה      ',
  //   title: 'קדמת צבי  ',
  //   title: 'קדר       ',
  //   title: 'קדרון     ',
  //   title: 'קדרים     ',
  //   title: 'קודייראת א-צאנעשב   ',
  //   title: 'קוואעין שבט         ',
  //   title: 'קוממיות   ',
  //   title: 'קורנית    ',
  //   title: 'קטורה     ',
  //   title: 'קיסריה    ',
  //   title: 'קלחים     ',
  //   title: 'קליה      ',
  //   title: 'קלנסווה   ',
  //   title: 'קלע       ',
  //   title: 'קציר      ',
  //   title: 'קצר א-סר  ',
  //   title: 'קצרין     ',
  //   title: 'קרית אונו ',
  //   title: 'קרית ארבע ',
  //   title: 'קרית אתא  ',
  //   title: 'קרית ביאליק         ',
  //   title: 'קרית גת   ',
  //   title: 'קרית טבעון',
  //   title: 'קרית ים   ',
  //   title: 'קרית יערים',
  //   title: 'קרית יעריםמוסד      ',
  //   title: 'קרית מוצקין         ',
  //   title: 'קרית מלאכי',
  //   title: 'קרית נטפים',
  //   title: 'קרית ענבים',
  //   title: 'קרית עקרון',
  //   title: 'קרית שלמה ',
  //   title: 'קרית שמונה',
  //   title: 'קרני שומרון         ',
  //   title: 'קשת       ',
  //   title: 'ראמה      ',
  //   title: 'ראס אל-עין',
  //   title: 'ראס עלי   ',
  //   title: 'ראש העין  ',
  //   title: 'ראש פינה  ',
  //   title: 'ראש צורים ',
  //   title: 'ראשון לציון         ',
  //   title: 'רבבה      ',
  //   title: 'רבדים     ',
  //   title: 'רביבים    ',
  //   title: 'רביד      ',
  //   title: 'רגבה      ',
  //   title: 'רגבים     ',
  //   title: 'רהט       ',
  //   title: 'רווחה     ',
  //   title: 'רוויה     ',
  //   title: 'רוח מדבר  ',
  //   title: 'רוחמה     ',
  //   title: 'רומאנה    ',
  //   title: 'רומת הייב ',
  //   title: 'רועי      ',
  //   title: 'רותם      ',
  //   title: 'רחוב      ',
  //   title: 'רחובות    ',
  //   title: 'רחלים     ',
  //   title: 'ריחאניה   ',
  //   title: 'ריחן      ',
  //   title: 'ריינה     ',
  //   title: 'רימונים   ',
  //   title: 'רינתיה    ',
  //   title: 'רכסים     ',
  //   title: 'רם-און    ',
  //   title: 'רמות      ',
  //   title: 'רמות השבים',
  //   title: 'רמות מאיר ',
  //   title: 'רמות מנשה ',
  //   title: 'רמות נפתלי',
  //   title: 'רמלה      ',
  //   title: 'רמת גן    ',
  //   title: 'רמת דוד   ',
  //   title: 'רמת הכובש ',
  //   title: 'רמת השופט ',
  //   title: 'רמת השרון ',
  //   title: 'רמת יוחנן ',
  //   title: 'רמת ישי   ',
  //   title: 'רמת מגשימים         ',
  //   title: 'רמת צבי   ',
  //   title: 'רמת רזיאל ',
  //   title: 'רמת רחל   ',
  //   title: 'רנן       ',
  //   title: 'רעים      ',
  //   title: 'רעננה     ',
  //   title: 'רקפת      ',
  //   title: 'רשפון     ',
  //   title: 'רשפים     ',
  //   title: 'רתמים     ',
  //   title: 'שאר ישוב  ',
  //   title: 'שבי דרום  ',
  //   title: 'שבי ציון  ',
  //   title: 'שבי שומרון',
  //   title: 'שבלי - אום אל-גנם   ',
  //   title: 'שגב-שלום  ',
  //   title: 'שדה אילן  ',
  //   title: 'שדה אליהו ',
  //   title: 'שדה אליעזר',
  //   title: 'שדה בוקר  ',
  //   title: 'שדה דוד   ',
  //   title: 'שדה ורבורג',
  //   title: 'שדה יואב  ',
  //   title: 'שדה יעקב  ',
  //   title: 'שדה יצחק  ',
  //   title: 'שדה משה   ',
  //   title: 'שדה נחום  ',
  //   title: 'שדה נחמיה ',
  //   title: 'שדה ניצן  ',
  //   title: 'שדה עוזיהו',
  //   title: 'שדה צבי   ',
  //   title: 'שדות ים   ',
  //   title: 'שדות מיכה ',
  //   title: 'שדי אברהם ',
  //   title: 'שדי חמד   ',
  //   title: 'שדי תרומות',
  //   title: 'שדמה      ',
  //   title: 'שדמות דבורה         ',
  //   title: 'שדמות מחולה         ',
  //   title: 'שדרות     ',
  //   title: 'שואבה     ',
  //   title: 'שובה      ',
  //   title: 'שובל      ',
  //   title: 'שוהם      ',
  //   title: 'שומרה     ',
  //   title: 'שומריה    ',
  //   title: 'שוקדה     ',
  //   title: 'שורש      ',
  //   title: 'שורשים    ',
  //   title: 'שושנת העמקים        ',
  //   title: 'שזור      ',
  //   title: 'שחר       ',
  //   title: 'שחרות     ',
  //   title: 'שיבולים   ',
  //   title: 'שיטים     ',
  //   title: 'שייח דנון',
  //   title: 'שילה      ',
  //   title: 'שילת      ',
  //   title: 'שכניה     ',
  //   title: 'שלווה     ',
  //   title: 'שלווה במדבר         ',
  //   title: 'שלוחות    ',
  //   title: 'שלומי     ',
  //   title: 'שלומית    ',
  //   title: 'שמיר      ',
  //   title: 'שמעה      ',
  //   title: 'שמרת      ',
  //   title: 'שמשית     ',
  //   title: 'שני       ',
  //   title: 'שניר      ',
  //   title: 'שעב       ',
  //   title: 'שעורים    ',
  //   title: 'שעל       ',
  //   title: 'שעלבים    ',
  //   title: 'שער אפרים ',
  //   title: 'שער הגולן ',
  //   title: 'שער העמקים',
  //   title: 'שער מנשה  ',
  //   title: 'שערי תקווה',
  //   title: 'שפיים     ',
  //   title: 'שפיר      ',
  //   title: 'שפר       ',
  //   title: 'שפרעם     ',
  //   title: 'שקד       ',
  //   title: 'שקף       ',
  //   title: 'שרונה     ',
  //   title: 'שריגים לי-און       ',
  //   title: 'שריד      ',
  //   title: 'שרשרת     ',
  //   title: 'שתולה     ',
  //   title: 'שתולים    ',
  //   title: 'תאשור     ',
  //   title: 'תדהר      ',
  //   title: 'תובל      ',
  //   title: 'תומר      ',
  //   title: 'תושיה     ',
  //   title: 'תימורים   ',
  //   title: 'תירוש     ',
  //   title: 'תל אביב - יפו',
  //   title: 'תל יוסף',
  //   title: 'תל יצחק   ',
  //   title: 'תל מונד   ',
  //   title: 'תל עדשים  ',
  //   title: 'תל קציר   ',
  //   title: 'תל שבע    ',
  //   title: 'תל תאומים ',
  //   title: 'תלם       ',
  //   title: 'תלמי אליהו',
  //   title: 'תלמי אלעזר',
  //   title: 'תלמי בילו ',
  //   title: 'תלמי יוסף ',
  //   title: 'תלמי יחיאל',
  //   title: 'תלמי יפה  ',
  //   title: 'תלמים     ',
  //   title: 'תמרת      ',
  //   title: 'תנובות    ',
  //   title: 'תעוז      ',
  //   title: 'תפרח      ',
  //   title: 'תקומה     ',
  //   title: 'תקוע      ',

  // }];

  useEffect(() => {
    if (!userInfo) {
      navigate('/signin?redirect=/shipping');
    }
  }, [userInfo, navigate]);

  const [country, setCountry] = useState(shippingAddress.country || '');
  // const submitHandler = (e) => {
  //   e.preventDefault();
  //   ctxDispatch({
  //     type: 'SAVE_SHIPPING_ADDRESS',
  //     payload: {
  //       fullName,
  //       address,
  //       city,
  //       postalCode,
  //       country: 'ישראל',
  //       location: shippingAddress.location,
  //     },
  //   });
  //   localStorage.setItem(
  //     'shippingAddress',
  //     JSON.stringify({
  //       fullName,
  //       address,
  //       city,
  //       postalCode,
  //       country: 'ישראל',
  //       location: shippingAddress.location,
  //     })
  //   );
  //   navigate('/payment');
  // };

  const submitHandler = (e) => {
    e.preventDefault();

    ctxDispatch({
        type: 'SAVE_SHIPPING_ADDRESS',
        payload: { fullName, address, city, postalCode, country:'ישראל' },
    });

    localStorage.setItem('shippingAddress', JSON.stringify({ fullName, address, city, postalCode, country: 'ישראל' })
    );
    navigate('/payment');
};

  useEffect(() => {
    ctxDispatch({ type: 'SET_FULLBOX_OFF' });
  }, [ctxDispatch, fullBox]);

  return (
    <div>
      <Helmet>
        <title>פרטי כתובת למשלוח</title>
      </Helmet>

      <CheckoutSteps step1></CheckoutSteps>
      <div className="container small-container">
        <h1 className="my-3">פרטי כתובת למשלוח</h1>
        <Form onSubmit={submitHandler}>
          <Form.Group className="mb-3" controlId="fullName">
            <Form.Label>שם מלא</Form.Label>
            <Form.Control
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="address">
            <Form.Label>כתובת מלאה</Form.Label>
            <Form.Control
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="city">
            <Form.Label>עיר</Form.Label>

            <Form.Select value={city} onChange={(e) => setCity(e.target.value)} required>

              <option>בחר עיר / ישוב</option>
              <option value="אבו ג'ווייעד שבט ">אבו ג'ווייעד שבט</option>
              <option value="אבו גוש          ">אבו גוש</option>
              <option value="אבו סנאן         ">אבו סנאן</option>
              <option value="אבו סריחאן שבט   ">אבו סריחאן שבט</option>
              <option value="אבו עבדון שבט    ">אבו עבדון שבט</option>
              <option value="אבו עמאר שבט     ">אבו עמאר שבט</option>
              <option value="אבו עמרה שבט     ">אבו עמרה שבט</option>
              <option value="אבו קורינאת שבט  ">אבו קורינאת שבט</option>
              <option value="אבו קרינאת יישוב ">אבו קרינאת יישוב  </option>
              <option value="אבו רובייעה שבט  ">אבו רובייעה שבט   </option>
              <option value="אבו רוקייק שבט   ">אבו רוקייק שבט    </option>
              <option value="אבו תלול         ">אבו תלול          </option>
              <option value="אבטין            ">אבטין             </option>
              <option value="אבטליון          ">אבטליון           </option>
              <option value="אביאל            ">אביאל             </option>
              <option value="אביבים           ">אביבים            </option>
              <option value="אביגדור          ">אביגדור           </option>
              <option value="אביחיל           ">אביחיל            </option>
              <option value="אביטל            ">אביטל             </option>
              <option value="אביעזר           ">אביעזר            </option>
              <option value="אבירים           ">אבירים            </option>
              <option value="אבן יהודה        ">אבן יהודה         </option>
              <option value="אבן מנחם         ">אבן מנחם          </option>
              <option value="אבן ספיר         ">אבן ספיר          </option>
              <option value="אבן שמואל        ">אבן שמואל         </option>
              <option value="אבני איתן        ">אבני איתן         </option>
              <option value="אבני חפץ         ">אבני חפץ          </option>
              <option value="אבנת             ">אבנת              </option>
              <option value="אבשלום           ">אבשלום            </option>
              <option value="אדורה            ">אדורה             </option>
              <option value="אדירים           ">אדירים            </option>
              <option value="אדמית            ">אדמית             </option>
              <option value="אדרת             ">אדרת              </option>
              <option value="אודים            ">אודים             </option>
              <option value="אודם             ">אודם              </option>
              <option value="אוהד             ">אוהד              </option>
              <option value="אום אל-פחם       ">אום אל-פחם        </option>
              <option value="אום אל-קוטוף     ">אום אל-קוטוף      </option>
              <option value="אום בטין         ">אום בטין          </option>
              <option value="אומן             ">אומן              </option>
              <option value="אומץ             ">אומץ              </option>
              <option value="אופקים           ">אופקים            </option>
              <option value="אור הגנוז        ">אור הגנוז         </option>
              <option value="אור הנר          ">אור הנר           </option>
              <option value="אור יהודה        ">אור יהודה         </option>
              <option value="אור עקיבא        ">אור עקיבא         </option>
              <option value="אורה             ">אורה              </option>
              <option value="אורות            ">אורות             </option>
              <option value="אורטל            ">אורטל             </option>
              <option value="אורים            ">אורים             </option>
              <option value="אורנים           ">אורנים            </option>
              <option value="אורנית           ">אורנית            </option>
              <option value="אושה             ">אושה              </option>
              <option value="אזור             ">אזור              </option>
              <option value="אחווה            ">אחווה             </option>
              <option value="אחוזם            ">אחוזם             </option>
              <option value="אחוזת ברק        ">אחוזת ברק         </option>
              <option value="אחיהוד           ">אחיהוד            </option>
              <option value="אחיטוב           ">אחיטוב            </option>
              <option value="אחיסמך           ">אחיסמך            </option>
              <option value="אחיעזר           ">אחיעזר            </option>
              <option value="אטרש שבט         ">אטרש שבט          </option>
              <option value="איבים            ">איבים             </option>
              <option value="אייל             ">אייל              </option>
              <option value="איילת השחר       ">איילת השחר        </option>
              <option value="אילון            ">אילון             </option>
              <option value="אילות            ">אילות             </option>
              <option value="אילניה           ">אילניה            </option>
              <option value="אילת             ">אילת              </option>
              <option value="אירוס            ">אירוס             </option>
              <option value="איתמר            ">איתמר             </option>
              <option value="איתן             ">איתן              </option>
              <option value="איתנים           ">איתנים            </option>
              <option value="אכסאל            ">אכסאל             </option>
              <option value="אל סייד          ">אל סייד           </option>
              <option value="אל-עזי           ">אל-עזי            </option>
              <option value="אל-עריאן         ">אל-עריאן          </option>
              <option value="אל-רום           ">אל-רום            </option>
              <option value="אלומה            ">אלומה             </option>
              <option value="אלומות           ">אלומות            </option>
              <option value="אלון הגליל       ">אלון הגליל        </option>
              <option value="אלון מורה        ">אלון מורה         </option>
              <option value="אלון שבות        ">אלון שבות         </option>
              <option value="אלוני אבא        ">אלוני אבא         </option>
              <option value="אלוני הבשן       ">אלוני הבשן        </option>
              <option value="אלוני יצחק       ">אלוני יצחק        </option>
              <option value="אלונים           ">אלונים            </option>
              <option value="אלי-עד           ">אלי-עד            </option>
              <option value="אליאב            ">אליאב             </option>
              <option value="אליכין           ">אליכין            </option>
              <option value="אליפז            ">אליפז             </option>
              <option value="אליפלט           ">אליפלט            </option>
              <option value="אליקים           ">אליקים            </option>
              <option value="אלישיב           ">אלישיב            </option>
              <option value="אלישמע           ">אלישמע            </option>
              <option value="אלמגור           ">אלמגור            </option>
              <option value="אלמוג            ">אלמוג             </option>
              <option value="אלעד             ">אלעד              </option>
              <option value="אלעזר            ">אלעזר             </option>
              <option value="אלפי מנשה        ">אלפי מנשה         </option>
              <option value="אלקוש            ">אלקוש             </option>
              <option value="אלקנה            ">אלקנה             </option>
              <option value="אמונים           ">אמונים            </option>
              <option value="אמירים           ">אמירים            </option>
              <option value="אמנון            ">אמנון             </option>
              <option value="אמציה            ">אמציה             </option>
              <option value="אניעם            ">אניעם             </option>
              <option value="אסד שבט          ">אסד שבט           </option>
              <option value="אספר             ">אספר              </option>
              <option value="אעבלין           ">אעבלין            </option>
              <option value="אעצם שבט         ">אעצם שבט          </option>
              <option value="אפיניש שבט       ">אפיניש שבט        </option>
              <option value="אפיק             ">אפיק              </option>
              <option value="אפיקים           ">אפיקים            </option>
              <option value="אפק              ">אפק               </option>
              <option value="אפרת             ">אפרת              </option>
              <option value="ארבל             ">ארבל              </option>
              <option value="ארגמן            ">ארגמן             </option>
              <option value="ארז              ">ארז               </option>
              <option value="אריאל            ">אריאל             </option>
              <option value="ארסוף            ">ארסוף             </option>
              <option value="אשבול            ">אשבול             </option>
              <option value="אשבל             ">אשבל              </option>
              <option value="אשדוד            ">אשדוד             </option>
              <option value="אשדות יעקב  איחוד">אשדות יעקב  איחוד </option>
              <option value="אשדות יעקב  מאוחד">אשדות יעקב  מאוחד </option>
              <option value="אשחר             ">אשחר              </option>
              <option value="אשכולות          ">אשכולות           </option>
              <option value="אשל הנשיא        ">אשל הנשיא         </option>
              <option value="אשלים            ">אשלים             </option>
              <option value="אשקלון           ">אשקלון            </option>
              <option value="אשרת             ">אשרת              </option>
              <option value="אשתאול           ">אשתאול            </option>
              <option value="אתגר             ">אתגר              </option>
              <option value="באקה אל-גרביה    ">באקה אל-גרביה     </option>
              <option value="באר אורה         ">באר אורה          </option>
              <option value="באר גנים         ">באר גנים          </option>
              <option value="באר טוביה        ">באר טוביה         </option>
              <option value="באר יעקב         ">באר יעקב          </option>
              <option value="באר מילכה        ">באר מילכה         </option>
              <option value="באר שבע          ">באר שבע           </option>
              <option value="בארות יצחק       ">בארות יצחק        </option>
              <option value="בארותיים         ">בארותיים          </option>
              <option value="בארי             ">בארי              </option>
              <option value="בוסתן הגליל      ">בוסתן הגליל       </option>
              <option value="בועיינה-נוג'ידאת ">בועיינה-נוג'ידאת  </option>
              <option value="בוקעאתא          ">בוקעאתא           </option>
              <option value="בורגתה           ">בורגתה            </option>
              <option value="בחן              ">בחן               </option>
              <option value="בטחה             ">בטחה              </option>
              <option value="ביצרון           ">ביצרון            </option>
              <option value="ביר אל-מכסור     ">ביר אל-מכסור      </option>
              <option value="ביר הדאג'        ">ביר הדאג'         </option>
              <option value="ביריה            ">ביריה             </option>
              <option value="בית אורן         ">בית אורן          </option>
              <option value="בית אל           ">בית אל            </option>
              <option value="בית אלעזרי       ">בית אלעזרי        </option>
              <option value="בית אלפא         ">בית אלפא          </option>
              <option value="בית אריה         ">בית אריה          </option>
              <option value="בית ברל          ">בית ברל           </option>
              <option value="בית ג'ן          ">בית ג'ן           </option>
              <option value="בית גוברין       ">בית גוברין        </option>
              <option value="בית גמליאל       ">בית גמליאל        </option>
              <option value="בית דגן          ">בית דגן           </option>
              <option value="בית הגדי         ">בית הגדי          </option>
              <option value="בית הלוי         ">בית הלוי          </option>
              <option value="בית הלל          ">בית הלל           </option>
              <option value="בית העמק         ">בית העמק          </option>
              <option value="בית הערבה        ">בית הערבה         </option>
              <option value="בית השיטה        ">בית השיטה         </option>
              <option value="בית זיד          ">בית זיד           </option>
              <option value="בית זית          ">בית זית           </option>
              <option value="בית זרע          ">בית זרע           </option>
              <option value="בית חורון        ">בית חורון         </option>
              <option value="בית חירות        ">בית חירות         </option>
              <option value="בית חלקיה        ">בית חלקיה         </option>
              <option value="בית חנן          ">בית חנן           </option>
              <option value="בית חנניה        ">בית חנניה         </option>
              <option value="בית חשמונאי      ">בית חשמונאי       </option>
              <option value="בית יהושע        ">בית יהושע         </option>
              <option value="בית יוסף         ">בית יוסף          </option>
              <option value="בית ינאי         ">בית ינאי          </option>
              <option value="בית יצחק-שער חפר ">בית יצחק-שער חפר  </option>
              <option value="בית לחם הגלילית  ">בית לחם הגלילית   </option>
              <option value="בית מאיר         ">בית מאיר          </option>
              <option value="בית נחמיה        ">בית נחמיה         </option>
              <option value="בית ניר          ">בית ניר           </option>
              <option value="בית נקופה        ">בית נקופה         </option>
              <option value="בית עובד         ">בית עובד          </option>
              <option value="בית עוזיאל       ">בית עוזיאל        </option>
              <option value="בית עזרא         ">בית עזרא          </option>
              <option value="בית עריף         ">בית עריף          </option>
              <option value="בית צבי          ">בית צבי           </option>
              <option value="בית קמה          ">בית קמה           </option>
              <option value="בית קשת          ">בית קשת           </option>
              <option value="בית רבן          ">בית רבן           </option>
              <option value="בית רימון        ">בית רימון         </option>
              <option value="בית שאן          ">בית שאן           </option>
              <option value="בית שמש          ">בית שמש           </option>
              <option value="בית שערים        ">בית שערים         </option>
              <option value="בית שקמה         ">בית שקמה          </option>
              <option value="ביתן אהרן        ">ביתן אהרן         </option>
              <option value="ביתר עילית       ">ביתר עילית        </option>
              <option value="בלפוריה          ">בלפוריה           </option>
              <option value="בן זכאי          ">בן זכאי           </option>
              <option value="בן עמי           ">בן עמי            </option>
              <option value="בן שמן כפר נוער  ">בן שמן כפר נוער   </option>
              <option value="בן שמן מושב      ">בן שמן מושב       </option>
              <option value="בני ברק          ">בני ברק           </option>
              <option value="בני דקלים        ">בני דקלים         </option>
              <option value="בני דרום         ">בני דרום          </option>
              <option value="בני דרור         ">בני דרור          </option>
              <option value="בני יהודה        ">בני יהודה         </option>
              <option value="בני נצרים        ">בני נצרים         </option>
              <option value="בני עטרות        ">בני עטרות         </option>
              <option value="בני עיש         ">בני עי"ש          </option>
              <option value="בני ציון         ">בני ציון          </option>
              <option value="בני ראם          ">בני ראם           </option>
              <option value="בניה             ">בניה              </option>
              <option value="בנימינה-גבעת עדה ">בנימינה-גבעת עדה  </option>
              <option value="בסמה            ">בסמ"ה             </option>
              <option value="בסמת טבעון       ">בסמת טבעון        </option>
              <option value="בענה             ">בענה              </option>
              <option value="בצרה             ">בצרה              </option>
              <option value="בצת              ">בצת               </option>
              <option value="בקוע             ">בקוע              </option>
              <option value="בקעות            ">בקעות             </option>
              <option value="בר גיורא         ">בר גיורא          </option>
              <option value="בר יוחאי         ">בר יוחאי          </option>
              <option value="ברוכין           ">ברוכין            </option>
              <option value="ברור חיל         ">ברור חיל          </option>
              <option value="ברוש             ">ברוש              </option>
              <option value="ברכה             ">ברכה              </option>
              <option value="ברכיה            ">ברכיה             </option>
              <option value="ברעם             ">ברעם              </option>
              <option value="ברק              ">ברק               </option>
              <option value="ברקאי            ">ברקאי             </option>
              <option value="ברקן             ">ברקן              </option>
              <option value="ברקת             ">ברקת              </option>
              <option value="בת הדר           ">בת הדר            </option>
              <option value="בת חן            ">בת חן             </option>
              <option value="בת חפר           ">בת חפר            </option>
              <option value="בת חצור          ">בת חצור           </option>
              <option value="בת ים            ">בת ים             </option>
              <option value="בת עין           ">בת עין            </option>
              <option value="בת שלמה          ">בת שלמה           </option>
              <option value="ג'דיידה-מכר      ">ג'דיידה-מכר       </option>
              <option value="ג'ולס            ">ג'ולס             </option>
              <option value="ג'לג'וליה        ">ג'לג'וליה         </option>
              <option value="ג'נאביב שבט      ">ג'נאביב שבט       </option>
              <option value="ג'סר א-זרקא      ">ג'סר א-זרקא       </option>
              <option value="ג'ש גוש חלב      ">ג'ש גוש חלב       </option>
              <option value="ג'ת              ">ג'ת               </option>
              <option value="גאולי תימן       ">גאולי תימן        </option>
              <option value="גאולים           ">גאולים            </option>
              <option value="גאליה            ">גאליה             </option>
              <option value="גבולות           ">גבולות            </option>
              <option value="גבים             ">גבים              </option>
              <option value="גבע              ">גבע               </option>
              <option value="גבע בנימין       ">גבע בנימין        </option>
              <option value="גבע כרמל         ">גבע כרמל          </option>
              <option value="גבעולים          ">גבעולים           </option>
              <option value="גבעון החדשה      ">גבעון החדשה       </option>
              <option value="גבעות בר         ">גבעות בר          </option>
              <option value="גבעת אבני        ">גבעת אבני         </option>
              <option value="גבעת אלה         ">גבעת אלה          </option>
              <option value="גבעת ברנר        ">גבעת ברנר         </option>
              <option value="גבעת השלושה      ">גבעת השלושה       </option>
              <option value="גבעת זאב         ">גבעת זאב          </option>
              <option value="גבעת חן         ">גבעת ח"ן          </option>
              <option value="גבעת חיים איחוד  ">גבעת חיים איחוד   </option>
              <option value="גבעת חיים מאוחד  ">גבעת חיים מאוחד   </option>
              <option value="גבעת יואב        ">גבעת יואב         </option>
              <option value="גבעת יערים       ">גבעת יערים        </option>
              <option value="גבעת ישעיהו      ">גבעת ישעיהו       </option>
              <option value="גבעת כח         ">גבעת כ"ח          </option>
              <option value="גבעת נילי    ">גבעת נילי       </option>
              <option value="גבעת עוז         ">גבעת עוז          </option>
              <option value="גבעת שמואל       ">גבעת שמואל        </option>
              <option value="גבעת שמש         ">גבעת שמש          </option>
              <option value="גבעת שפירא       ">גבעת שפירא        </option>
              <option value="גבעתי            ">גבעתי             </option>
              <option value="גבעתיים          ">גבעתיים           </option>
              <option value="גברעם            ">גברעם             </option>
              <option value="גבת              ">גבת               </option>
              <option value="גדות             ">גדות              </option>
              <option value="גדיש             ">גדיש              </option>
              <option value="גדעונה           ">גדעונה            </option>
              <option value="גדרה             ">גדרה              </option>
              <option value="גונן             ">גונן              </option>
              <option value="גורן             ">גורן              </option>
              <option value="גורנות הגליל     ">גורנות הגליל      </option>
              <option value="גזית             ">גזית              </option>
              <option value="גזר              ">גזר               </option>
              <option value="גיאה             ">גיאה              </option>
              <option value="גיבתון           ">גיבתון            </option>
              <option value="גיזו             ">גיזו              </option>
              <option value="גילון            ">גילון             </option>
              <option value="גילת             ">גילת              </option>
              <option value="גינוסר           ">גינוסר            </option>
              <option value="גיניגר           ">גיניגר            </option>
              <option value="גינתון           ">גינתון            </option>
              <option value="גיתה             ">גיתה              </option>
              <option value="גיתית            ">גיתית             </option>
              <option value="גלאון            ">גלאון             </option>
              <option value="גלגל             ">גלגל              </option>
              <option value="גליל ים          ">גליל ים           </option>
              <option value="גלעד אבן יצחק    ">גלעד אבן יצחק     </option>
              <option value="גמזו             ">גמזו              </option>
              <option value="גן הדרום         ">גן הדרום          </option>
              <option value="גן השומרון       ">גן השומרון        </option>
              <option value="גן חיים          ">גן חיים           </option>
              <option value="גן יאשיה         ">גן יאשיה          </option>
              <option value="גן יבנה          ">גן יבנה           </option>
              <option value="גן נר            ">גן נר             </option>
              <option value="גן שורק          ">גן שורק           </option>
              <option value="גן שלמה          ">גן שלמה           </option>
              <option value="גן שמואל         ">גן שמואל          </option>
              <option value="גנות             ">גנות              </option>
              <option value="גנות הדר         ">גנות הדר          </option>
              <option value="גני הדר          ">גני הדר           </option>
              <option value="גני טל           ">גני טל            </option>
              <option value="גני יוחנן        ">גני יוחנן         </option>
              <option value="גני מודיעין      ">גני מודיעין       </option>
              <option value="גני עם           ">גני עם            </option>
              <option value="גני תקווה        ">גני תקווה         </option>
              <option value="געש              ">געש               </option>
              <option value="געתון            ">געתון             </option>
              <option value="גפן              ">גפן               </option>
              <option value="גרופית           ">גרופית            </option>
              <option value="גשור             ">גשור              </option>
              <option value="גשר              ">גשר               </option>
              <option value="גשר הזיו         ">גשר הזיו          </option>
              <option value="גת קיבוץ         ">גת קיבוץ          </option>
              <option value="גת רימון         ">גת רימון          </option>
              <option value="דאלית אל-כרמל    ">דאלית אל-כרמל     </option>
              <option value="דבורה            ">דבורה             </option>
              <option value="דבוריה           ">דבוריה            </option>
              <option value="דבירה            ">דבירה             </option>
              <option value="דברת             ">דברת              </option>
              <option value="דגניה א'         ">דגניה א'          </option>
              <option value="דגניה ב'         ">דגניה ב'          </option>
              <option value="דובב            ">דוב"ב             </option>
              <option value="דולב             ">דולב              </option>
              <option value="דור              ">דור               </option>
              <option value="דורות            ">דורות             </option>
              <option value="דחי              ">דחי               </option>
              <option value="דייר אל-אסד      ">דייר אל-אסד       </option>
              <option value="דייר חנא         ">דייר חנא          </option>
              <option value="דייר ראפאת       ">דייר ראפאת        </option>
              <option value="דימונה           ">דימונה            </option>
              <option value="דישון            ">דישון             </option>
              <option value="דליה             ">דליה              </option>
              <option value="דלתון            ">דלתון             </option>
              <option value="דמיידה           ">דמיידה            </option>
              <option value="דן               ">דן                </option>
              <option value="דפנה             ">דפנה              </option>
              <option value="דקל              ">דקל               </option>
              <option value="דריג'את          ">דריג'את           </option>
              <option value="האון             ">האון              </option>
              <option value="הבונים           ">הבונים            </option>
              <option value="הגושרים          ">הגושרים           </option>
              <option value="הדר עם           ">הדר עם            </option>
              <option value="הוד השרון        ">הוד השרון         </option>
              <option value="הודיה            ">הודיה             </option>
              <option value="הודיות           ">הודיות            </option>
              <option value="הוואשלה שבט      ">הוואשלה שבט       </option>
              <option value="הוזייל שבט       ">הוזייל שבט        </option>
              <option value="הושעיה           ">הושעיה            </option>
              <option value="הזורע            ">הזורע             </option>
              <option value="הזורעים          ">הזורעים           </option>
              <option value="החותרים          ">החותרים           </option>
              <option value="היוגב            ">היוגב             </option>
              <option value="הילה             ">הילה              </option>
              <option value="המעפיל           ">המעפיל            </option>
              <option value="הסוללים          ">הסוללים           </option>
              <option value="העוגן            ">העוגן             </option>
              <option value="הר אדר           ">הר אדר            </option>
              <option value="הר גילה          ">הר גילה           </option>
              <option value="הר עמשא          ">הר עמשא           </option>
              <option value="הראל             ">הראל              </option>
              <option value="הרדוף            ">הרדוף             </option>
              <option value="הרצליה           ">הרצליה            </option>
              <option value="הררית            ">הררית             </option>
              <option value="ורד יריחו        ">ורד יריחו         </option>
              <option value="ורדון            ">ורדון             </option>
              <option value="זבארגה שבט       ">זבארגה שבט        </option>
              <option value="זבדיאל           ">זבדיאל            </option>
              <option value="זוהר             ">זוהר              </option>
              <option value="זיקים            ">זיקים             </option>
              <option value="זיתן             ">זיתן              </option>
              <option value="זכרון יעקב       ">זכרון יעקב        </option>
              <option value="זכריה            ">זכריה             </option>
              <option value="זמר              ">זמר               </option>
              <option value="זמרת             ">זמרת              </option>
              <option value="זנוח             ">זנוח              </option>
              <option value="זרועה            ">זרועה             </option>
              <option value="זרזיר            ">זרזיר             </option>
              <option value="זרחיה            ">זרחיה             </option>
              <option value="ח'ואלד           ">ח'ואלד            </option>
              <option value="ח'ואלד שבט       ">ח'ואלד שבט        </option>
              <option value="חבצלת השרון      ">חבצלת השרון       </option>
              <option value="חבר              ">חבר               </option>
              <option value="חברון            ">חברון             </option>
              <option value="חגור             ">חגור              </option>
              <option value="חגי              ">חגי               </option>
              <option value="חגלה             ">חגלה              </option>
              <option value="חד-נס            ">חד-נס             </option>
              <option value="חדיד             ">חדיד              </option>
              <option value="חדרה             ">חדרה              </option>
              <option value="חוג'ייראת ד'הרה  ">חוג'ייראת ד'הרה   </option>
              <option value="חולדה            ">חולדה             </option>
              <option value="חולון            ">חולון             </option>
              <option value="חולית            ">חולית             </option>
              <option value="חולתה            ">חולתה             </option>
              <option value="חוסן             ">חוסן              </option>
              <option value="חוסנייה          ">חוסנייה           </option>
              <option value="חופית            ">חופית             </option>
              <option value="חוקוק            ">חוקוק             </option>
              <option value="חורה             ">חורה              </option>
              <option value="חורפיש           ">חורפיש            </option>
              <option value="חורשים           ">חורשים            </option>
              <option value="חזון             ">חזון              </option>
              <option value="חיבת ציון        ">חיבת ציון         </option>
              <option value="חיננית           ">חיננית            </option>
              <option value="חיפה             ">חיפה              </option>
              <option value="חירות            ">חירות             </option>
              <option value="חלוץ             ">חלוץ              </option>
              <option value="חלמיש            ">חלמיש             </option>
              <option value="חלץ              ">חלץ               </option>
              <option value="חמאם             ">חמאם              </option>
              <option value="חמד              ">חמד               </option>
              <option value="חמדיה            ">חמדיה             </option>
              <option value="חמדת             ">חמדת              </option>
              <option value="חמרה             ">חמרה              </option>
              <option value="חניאל            ">חניאל             </option>
              <option value="חניתה            ">חניתה             </option>
              <option value="חנתון            ">חנתון             </option>
              <option value="חספין            ">חספין             </option>
              <option value="חפץ חיים         ">חפץ חיים          </option>
              <option value="חפצי-בה          ">חפצי-בה           </option>
              <option value="חצב              ">חצב               </option>
              <option value="חצבה             ">חצבה              </option>
              <option value="חצור הגלילית     ">חצור הגלילית      </option>
              <option value="חצור-אשדוד       ">חצור-אשדוד        </option>
              <option value="חצר בארותיים     ">חצר בארותיים      </option>
              <option value="חצרות חולדה      ">חצרות חולדה       </option>
              <option value="חצרות יסף        ">חצרות יסף         </option>
              <option value="חצרות כ       ">חצרות כח         </option>
              <option value="חצרים            ">חצרים             </option>
              <option value="חרב לאת          ">חרב לאת           </option>
              <option value="חרוצים           ">חרוצים            </option>
              <option value="חריש             ">חריש              </option>
              <option value="חרמש             ">חרמש              </option>
              <option value="חרשים            ">חרשים             </option>
              <option value="חשמונאים         ">חשמונאים          </option>
              <option value="טבריה            ">טבריה             </option>
              <option value="טובא-זנגריה      ">טובא-זנגריה       </option>
              <option value="טורעאן           ">טורעאן            </option>
              <option value="טייבה            ">טייבה             </option>
              <option value="טייבה בעמק       ">טייבה בעמק        </option>
              <option value="טירה             ">טירה              </option>
              <option value="טירת יהודה       ">טירת יהודה        </option>
              <option value="טירת כרמל        ">טירת כרמל         </option>
              <option value="טירת צבי         ">טירת צבי          </option>
              <option value="טל שחר           ">טל שחר            </option>
              <option value="טל-אל            ">טל-אל             </option>
              <option value="טללים            ">טללים             </option>
              <option value="טלמון            ">טלמון             </option>
              <option value="טמרה             ">טמרה              </option>
              <option value="טמרה יזרעאל      ">טמרה יזרעאל       </option>
              <option value="טנא              ">טנא               </option>
              <option value="טפחות            ">טפחות             </option>
              <option value="יאנוח-ג'ת        ">יאנוח-ג'ת         </option>
              <option value="יבול             ">יבול              </option>
              <option value="יבנאל            ">יבנאל             </option>
              <option value="יבנה             ">יבנה              </option>
              <option value="יגור             ">יגור              </option>
              <option value="יגל              ">יגל               </option>
              <option value="יד בנימין        ">יד בנימין         </option>
              <option value="יד השמונה        ">יד השמונה         </option>
              <option value="יד חנה           ">יד חנה            </option>
              <option value="יד מרדכי         ">יד מרדכי          </option>
              <option value="יד נתן           ">יד נתן            </option>
              <option value="יד רמב"         >יד רמב         </option>
              <option value="ידידה            ">ידידה             </option>
              <option value="יהוד-מונוסון     ">יהוד-מונוסון      </option>
              <option value="יהל              ">יהל               </option>
              <option value="יובל             ">יובל              </option>
              <option value="יובלים           ">יובלים            </option>
              <option value="יודפת            ">יודפת             </option>
              <option value="יונתן            ">יונתן             </option>
              <option value="יושיביה          ">יושיביה           </option>
              <option value="יזרעאל           ">יזרעאל            </option>
              <option value="יחיעם            ">יחיעם             </option>
              <option value="יטבתה            ">יטבתה             </option>
              <option value="בייט            ">בייט            </option>
              <option value="יכיני            ">יכיני             </option>
              <option value="ינוב             ">ינוב              </option>
              <option value="ינון             ">ינון              </option>
              <option value="יסוד המעלה       ">יסוד המעלה        </option>
              <option value="יסודות           ">יסודות            </option>
              <option value="יסעור            ">יסעור             </option>
              <option value="יעד              ">יעד               </option>
              <option value="יעל              ">יעל               </option>
              <option value="יעף              ">יעף               </option>
              <option value="יערה             ">יערה              </option>
              <option value="יפיע             ">יפיע              </option>
              <option value="יפית             ">יפית              </option>
              <option value="יפעת             ">יפעת              </option>
              <option value="יפתח             ">יפתח              </option>
              <option value="יצהר             ">יצהר              </option>
              <option value="יציץ             ">יציץ              </option>
              <option value="יקום             ">יקום              </option>
              <option value="יקיר             ">יקיר              </option>
              <option value="יקנעם מושבה      ">יקנעם מושבה       </option>
              <option value="יקנעם עילית      ">יקנעם עילית       </option>
              <option value="יראון            ">יראון             </option>
              <option value="ירדנה            ">ירדנה             </option>
              <option value="ירוחם            ">ירוחם             </option>
              <option value="ירושלים          ">ירושלים           </option>
              <option value="ירחיב            ">ירחיב             </option>
              <option value="ירכא             ">ירכא              </option>
              <option value="ירקונה           ">ירקונה            </option>
              <option value="ישע              ">ישע               </option>
              <option value="ישעי             ">ישעי              </option>
              <option value="ישרש             ">ישרש              </option>
              <option value="יתד              ">יתד               </option>
              <option value="יתיר             ">יתיר              </option>
              <option value="כאבול            ">כאבול             </option>
              <option value="כברי             ">כברי              </option>
              <option value="כדורי            ">כדורי             </option>
              <option value="כדיתה            ">כדיתה             </option>
              <option value="כוכב השחר        ">כוכב השחר         </option>
              <option value="כוכב יאיר        ">כוכב יאיר         </option>
              <option value="כוכב יעקב        ">כוכב יעקב         </option>
              <option value="כוכב מיכאל       ">כוכב מיכאל        </option>
              <option value="כורזים           ">כורזים            </option>
              <option value="כחל              ">כחל               </option>
              <option value="כחלה             ">כחלה              </option>
              <option value="כיסופים          ">כיסופים           </option>
              <option value="כישור            ">כישור             </option>
              <option value="כליל             ">כליל              </option>
              <option value="כלנית            ">כלנית             </option>
              <option value="כמאנה            ">כמאנה             </option>
              <option value="כמהין            ">כמהין             </option>
              <option value="כמון             ">כמון              </option>
              <option value="כנות             ">כנות              </option>
              <option value="כנף              ">כנף               </option>
              <option value="כנרת מושבה       ">כנרת מושבה        </option>
              <option value="כנרת קבוצה       ">כנרת קבוצה        </option>
              <option value="כסיפה            ">כסיפה             </option>
              <option value="כסלון            ">כסלון             </option>
              <option value="כסרא-סמיע        ">כסרא-סמיע         </option>
              <option value="כפר אביב         ">כפר אביב          </option>
              <option value="כפר אדומים       ">כפר אדומים        </option>
              <option value="כפר אוריה        ">כפר אוריה         </option>
              <option value="כפר אחים         ">כפר אחים          </option>
              <option value="כפר ביאליק       ">כפר ביאליק        </option>
              <option value="כפר בילו        ">כפר ביל"ו         </option>
              <option value="כפר בלום         ">כפר בלום          </option>
              <option value="כפר בן נון       ">כפר בן נון        </option>
              <option value="כפר ברא          ">כפר ברא           </option>
              <option value="כפר ברוך         ">כפר ברוך          </option>
              <option value="כפר גדעון        ">כפר גדעון         </option>
              <option value="כפר גלים         ">כפר גלים          </option>
              <option value="כפר גליקסון      ">כפר גליקסון       </option>
              <option value="כפר גלעדי        ">כפר גלעדי         </option>
              <option value="כפר דניאל        ">כפר דניאל         </option>
              <option value="כפר האורנים      ">כפר האורנים       </option>
              <option value="כפר החורש        ">כפר החורש         </option>
              <option value="כפר המכבי        ">כפר המכבי         </option>
              <option value="כפר הנגיד        ">כפר הנגיד         </option>
              <option value="כפר הנוער הדתי   ">כפר הנוער הדתי    </option>
              <option value="כפר הנשיא        ">כפר הנשיא         </option>
              <option value="כפר הס           ">כפר הס            </option>
              <option value="כפר הראה        ">כפר הרא"ה         </option>
              <option value="כפר הריף        ">כפר הריף         </option>
              <option value="כפר ויתקין       ">כפר ויתקין        </option>
              <option value="כפר ורבורג       ">כפר ורבורג        </option>
              <option value="כפר ורדים        ">כפר ורדים         </option>
              <option value="כפר זוהרים       ">כפר זוהרים        </option>
              <option value="כפר זיתים        ">כפר זיתים         </option>
              <option value="כפר חבד         ">כפר חב"ד          </option>
              <option value="כפר חושן         ">כפר חושן          </option>
              <option value="כפר חיטים        ">כפר חיטים         </option>
              <option value="כפר חיים         ">כפר חיים          </option>
              <option value="כפר חנניה        ">כפר חנניה         </option>
              <option value="כפר חסידים א'    ">כפר חסידים א'     </option>
              <option value="כפר חסידים ב'    ">כפר חסידים ב'     </option>
              <option value="כפר חרוב         ">כפר חרוב          </option>
              <option value="כפר טרומן        ">כפר טרומן         </option>
              <option value="כפר יאסיף        ">כפר יאסיף         </option>
              <option value="כפר ידידיה       ">כפר ידידיה        </option>
              <option value="כפר יהושע        ">כפר יהושע         </option>
              <option value="כפר יונה         ">כפר יונה          </option>
              <option value="כפר יחזקאל       ">כפר יחזקאל        </option>
              <option value="כפר יעבץ         ">כפר יעבץ          </option>
              <option value="כפר כמא          ">כפר כמא           </option>
              <option value="כפר כנא          ">כפר כנא           </option>
              <option value="כפר מונש         ">כפר מונש          </option>
              <option value="כפר מימון        ">כפר מימון         </option>
              <option value="כפר מלל         ">כפר מלל          </option>
              <option value="כפר מנדא         ">כפר מנדא          </option>
              <option value="כפר מנחם         ">כפר מנחם          </option>
              <option value="כפר מסריק        ">כפר מסריק         </option>
              <option value="כפר מצר          ">כפר מצר           </option>
              <option value="כפר מרדכי        ">כפר מרדכי         </option>
              <option value="כפר נטר          ">כפר נטר           </option>
              <option value="כפר סאלד         ">כפר סאלד          </option>
              <option value="כפר סבא          ">כפר סבא           </option>
              <option value="כפר סילבר        ">כפר סילבר         </option>
              <option value="כפר סירקין       ">כפר סירקין        </option>
              <option value="כפר עבודה        ">כפר עבודה         </option>
              <option value="כפר עזה          ">כפר עזה           </option>
              <option value="כפר עציון        ">כפר עציון         </option>
              <option value="כפר פינס         ">כפר פינס          </option>
              <option value="כפר קאסם         ">כפר קאסם          </option>
              <option value="כפר קיש          ">כפר קיש           </option>
              <option value="כפר קרע          ">כפר קרע           </option>
              <option value="כפר ראש הנקרה    ">כפר ראש הנקרה     </option>
              <option value="כפר רופין        ">כפר רופין         </option>
              <option value="כפר רות          ">כפר רות           </option>
              <option value="כפר שמאי         ">כפר שמאי          </option>
              <option value="כפר שמואל        ">כפר שמואל         </option>
              <option value="כפר שמריהו       ">כפר שמריהו        </option>
              <option value="כפר תבור         ">כפר תבור          </option>
              <option value="כפר תפוח         ">כפר תפוח          </option>
              <option value="כרי דשא          ">כרי דשא           </option>
              <option value="כרכום            ">כרכום             </option>
              <option value="כרם בן זמרה      ">כרם בן זמרה       </option>
              <option value="כרם בן שמן       ">כרם בן שמן        </option>
              <option value="כרם יבנה ישיבה   ">כרם יבנה ישיבה    </option>
              <option value="כרם שלום         ">כרם שלום          </option>
              <option value="כרמי יוסף        ">כרמי יוסף         </option>
              <option value="כרמי צור         ">כרמי צור          </option>
              <option value="כרמי קטיף        ">כרמי קטיף         </option>
              <option value="כרמיאל           ">כרמיאל            </option>
              <option value="כרמיה            ">כרמיה             </option>
              <option value="כרמים            ">כרמים             </option>
              <option value="כרמל             ">כרמל              </option>
              <option value="לא רשום          ">לא רשום           </option>
              <option value="לבון             ">לבון              </option>
              <option value="לביא             ">לביא              </option>
              <option value="לבנים            ">לבנים             </option>
              <option value="להב              ">להב               </option>
              <option value="להבות הבשן       ">להבות הבשן        </option>
              <option value="להבות חביבה      ">להבות חביבה       </option>
              <option value="להבים            ">להבים             </option>
              <option value="לוד              ">לוד               </option>
              <option value="לוזית            ">לוזית             </option>
              <option value="לוחמי הגיטאות    ">לוחמי הגיטאות     </option>
              <option value="לוטם             ">לוטם              </option>
              <option value="לוטן             ">לוטן              </option>
              <option value="לימן             ">לימן              </option>
              <option value="לכיש             ">לכיש              </option>
              <option value="לפיד             ">לפיד              </option>
              <option value="לפידות           ">לפידות            </option>
              <option value="לקיה             ">לקיה              </option>
              <option value="מאור             ">מאור              </option>
              <option value="מאיר שפיה        ">מאיר שפיה         </option>
              <option value="מבוא ביתר        ">מבוא ביתר         </option>
              <option value="מבוא דותן        ">מבוא דותן         </option>
              <option value="מבוא חורון       ">מבוא חורון        </option>
              <option value="מבוא חמה         ">מבוא חמה          </option>
              <option value="מבוא מודיעים     ">מבוא מודיעים      </option>
              <option value="מבואות ים        ">מבואות ים         </option>
              <option value="מבועים           ">מבועים            </option>
              <option value="מבטחים           ">מבטחים            </option>
              <option value="מבקיעים          ">מבקיעים           </option>
              <option value="מבשרת ציון       ">מבשרת ציון        </option>
              <option value="מג'ד אל-כרום     ">מג'ד אל-כרום      </option>
              <option value="מג'דל שמס        ">מג'דל שמס         </option>
              <option value="מגאר             ">מגאר              </option>
              <option value="מגדים            ">מגדים             </option>
              <option value="מגדל             ">מגדל              </option>
              <option value="מגדל העמק        ">מגדל העמק         </option>
              <option value="מגדל עוז         ">מגדל עוז          </option>
              <option value="מגדלים           ">מגדלים            </option>
              <option value="מגידו            ">מגידו             </option>
              <option value="מגל              ">מגל               </option>
              <option value="מגן              ">מגן               </option>
              <option value="מגן שאול         ">מגן שאול          </option>
              <option value="מגשימים          ">מגשימים           </option>
              <option value="מדרך עוז         ">מדרך עוז          </option>
              <option value="מדרשת בן גוריון  ">מדרשת בן גוריון   </option>
              <option value="מדרשת רופין      ">מדרשת רופין       </option>
              <option value="מודיעין עילית    ">מודיעין עילית     </option>
              <option value="מודיעין-מכבים-רעות">מודיעין-מכבים-רעות</option>
              <option value="מולדת            ">מולדת             </option>
              <option value="מוצא עילית       ">מוצא עילית        </option>
              <option value="מוקייבלה         ">מוקייבלה          </option>
              <option value="מורן             ">מורן              </option>
              <option value="מורשת            ">מורשת             </option>
              <option value="מזור             ">מזור              </option>
              <option value="מזכרת בתיה       ">מזכרת בתיה        </option>
              <option value="מזרע             ">מזרע              </option>
              <option value="מזרעה            ">מזרעה             </option>
              <option value="מחולה            ">מחולה             </option>
              <option value="מחנה הילה        ">מחנה הילה         </option>
              <option value="מחנה טלי         ">מחנה טלי          </option>
              <option value="מחנה יהודית      ">מחנה יהודית       </option>
              <option value="מחנה יוכבד       ">מחנה יוכבד        </option>
              <option value="מחנה יפה         ">מחנה יפה          </option>
              <option value="מחנה יתיר        ">מחנה יתיר         </option>
              <option value="מחנה מרים        ">מחנה מרים         </option>
              <option value="מחנה תל נוף      ">מחנה תל נוף       </option>
              <option value="מחניים           ">מחניים            </option>
              <option value="מחסיה            ">מחסיה             </option>
              <option value="מטולה            ">מטולה             </option>
              <option value="מטע              ">מטע               </option>
              <option value="מי עמי           ">מי עמי            </option>
              <option value="מיטב             ">מיטב              </option>
              <option value="מייסר            ">מייסר             </option>
              <option value="מיצר             ">מיצר              </option>
              <option value="מירב             ">מירב              </option>
              <option value="מירון            ">מירון             </option>
              <option value="מישר             ">מישר              </option>
              <option value="מיתר             ">מיתר              </option>
              <option value="מכורה            ">מכורה             </option>
              <option value="מכחול            ">מכחול             </option>
              <option value="מכמורת           ">מכמורת            </option>
              <option value="מכמנים           ">מכמנים            </option>
              <option value="מלאה             ">מלאה              </option>
              <option value="מלילות           ">מלילות            </option>
              <option value="מלכיה            ">מלכיה             </option>
              <option value="מלכישוע          ">מלכישוע           </option>
              <option value="מנוחה            ">מנוחה             </option>
              <option value="מנוף             ">מנוף              </option>
              <option value="מנות             ">מנות              </option>
              <option value="מנחמיה           ">מנחמיה            </option>
              <option value="מנרה             ">מנרה              </option>
              <option value="מנשית זבדה       ">מנשית זבדה        </option>
              <option value="מסד              ">מסד               </option>
              <option value="מסדה             ">מסדה              </option>
              <option value="מסילות           ">מסילות            </option>
              <option value="מסילת ציון       ">מסילת ציון        </option>
              <option value="מסלול            ">מסלול             </option>
              <option value="מסעדה            ">מסעדה             </option>
              <option value="מסעודין אל-עזאזמה">מסעודין אל-עזאזמה </option>
              <option value="מעברות           ">מעברות            </option>
              <option value="מעגלים           ">מעגלים            </option>
              <option value="מעגן             ">מעגן              </option>
              <option value="מעגן מיכאל       ">מעגן מיכאל        </option>
              <option value="מעוז חיים        ">מעוז חיים         </option>
              <option value="מעון             ">מעון              </option>
              <option value="מעונה            ">מעונה             </option>
              <option value="מעיליא           ">מעיליא            </option>
              <option value="מעין ברוך        ">מעין ברוך         </option>
              <option value="מעין צבי         ">מעין צבי          </option>
              <option value="מעלה אדומים      ">מעלה אדומים       </option>
              <option value="מעלה אפרים       ">מעלה אפרים        </option>
              <option value="מעלה גלבוע       ">מעלה גלבוע        </option>
              <option value="מעלה גמלא        ">מעלה גמלא         </option>
              <option value="מעלה החמישה      ">מעלה החמישה       </option>
              <option value="מעלה לבונה       ">מעלה לבונה        </option>
              <option value="מעלה מכמש        ">מעלה מכמש         </option>
              <option value="מעלה עירון       ">מעלה עירון        </option>
              <option value="מעלה עמוס        ">מעלה עמוס         </option>
              <option value="מעלה שומרון      ">מעלה שומרון       </option>
              <option value="מעלות-תרשיחא     ">מעלות-תרשיחא      </option>
              <option value="מענית            ">מענית             </option>
              <option value="מעש              ">מעש               </option>
              <option value="מפלסים           ">מפלסים            </option>
              <option value="מצדות יהודה      ">מצדות יהודה       </option>
              <option value="מצובה            ">מצובה             </option>
              <option value="מצליח            ">מצליח             </option>
              <option value="מצפה             ">מצפה              </option>
              <option value="מצפה אביב       ">מצפה אבי"ב        </option>
              <option value="מצפה אילן        ">מצפה אילן         </option>
              <option value="מצפה יריחו       ">מצפה יריחו        </option>
              <option value="מצפה נטופה       ">מצפה נטופה        </option>
              <option value="מצפה רמון        ">מצפה רמון         </option>
              <option value="מצפה שלם         ">מצפה שלם          </option>
              <option value="מצר              ">מצר               </option>
              <option value="מקווה ישראל      ">מקווה ישראל       </option>
              <option value="מרגליות          ">מרגליות           </option>
              <option value="מרום גולן        ">מרום גולן         </option>
              <option value="מרחב עם          ">מרחב עם           </option>
              <option value="מרחביה מושב      ">מרחביה מושב       </option>
              <option value="מרחביה קיבוץ     ">מרחביה קיבוץ      </option>
              <option value="מרכז שפירא       ">מרכז שפירא        </option>
              <option value="משאבי שדה        ">משאבי שדה         </option>
              <option value="משגב דב          ">משגב דב           </option>
              <option value="משגב עם          ">משגב עם           </option>
              <option value="משהד             ">משהד              </option>
              <option value="משואה            ">משואה             </option>
              <option value="משואות יצחק      ">משואות יצחק       </option>
              <option value="משכיות           ">משכיות            </option>
              <option value="משמר איילון      ">משמר איילון       </option>
              <option value="משמר דוד         ">משמר דוד          </option>
              <option value="משמר הירדן       ">משמר הירדן        </option>
              <option value="משמר הנגב        ">משמר הנגב         </option>
              <option value="משמר העמק        ">משמר העמק         </option>
              <option value="משמר השבעה       ">משמר השבעה        </option>
              <option value="משמר השרון       ">משמר השרון        </option>
              <option value="משמרות           ">משמרות            </option>
              <option value="משמרת            ">משמרת             </option>
              <option value="משען             ">משען              </option>
              <option value="מתן              ">מתן               </option>
              <option value="מתת              ">מתת               </option>
              <option value="מתתיהו           ">מתתיהו            </option>
              <option value="נאות גולן        ">נאות גולן         </option>
              <option value="נאות הכיכר       ">נאות הכיכר        </option>
              <option value="נאות מרדכי       ">נאות מרדכי        </option>
              <option value="נאות סמדר        ">נאות סמדר         </option>
              <option value="נאעורה           ">נאעורה            </option>
              <option value="נבטים            ">נבטים             </option>
              <option value="נגבה             ">נגבה              </option>
              <option value="נגוהות           ">נגוהות            </option>
              <option value="נהורה            ">נהורה             </option>
              <option value="נהלל             ">נהלל              </option>
              <option value="נהריה            ">נהריה             </option>
              <option value="נוב              ">נוב               </option>
              <option value="נוגה             ">נוגה              </option>
              <option value="נווה             ">נווה              </option>
              <option value="נווה אבות        ">נווה אבות         </option>
              <option value="נווה אור         ">נווה אור          </option>
              <option value="נווה אטיב       ">נווה אטי"ב        </option>
              <option value="נווה אילן        ">נווה אילן         </option>
              <option value="נווה איתן        ">נווה איתן         </option>
              <option value="נווה דניאל       ">נווה דניאל        </option>
              <option value="נווה זוהר        ">נווה זוהר         </option>
              <option value="נווה זיו         ">נווה זיו          </option>
              <option value="נווה חריף        ">נווה חריף         </option>
              <option value="נווה ים          ">נווה ים           </option>
              <option value="נווה ימין        ">נווה ימין         </option>
              <option value="נווה ירק         ">נווה ירק          </option>
              <option value="נווה מבטח        ">נווה מבטח         </option>
              <option value="נווה מיכאל       ">נווה מיכאל        </option>
              <option value="נווה שלום        ">נווה שלום         </option>
              <option value="נועם             ">נועם              </option>
              <option value="נוף איילון       ">נוף איילון        </option>
              <option value="נופים            ">נופים             </option>
              <option value="נופית            ">נופית             </option>
              <option value="נופך             ">נופך              </option>
              <option value="נוקדים           ">נוקדים            </option>
              <option value="נורדיה           ">נורדיה            </option>
              <option value="נורית            ">נורית             </option>
              <option value="נחושה            ">נחושה             </option>
              <option value="נחל עוז          ">נחל עוז           </option>
              <option value="נחלה             ">נחלה              </option>
              <option value="נחליאל           ">נחליאל            </option>
              <option value="נחלים            ">נחלים             </option>
              <option value="נחם              ">נחם               </option>
              <option value="נחף              ">נחף               </option>
              <option value="נחשולים          ">נחשולים           </option>
              <option value="נחשון            ">נחשון             </option>
              <option value="נחשונים          ">נחשונים           </option>
              <option value="נטועה            ">נטועה             </option>
              <option value="נטור             ">נטור              </option>
              <option value="נטע              ">נטע               </option>
              <option value="נטעים            ">נטעים             </option>
              <option value="נטף              ">נטף               </option>
              <option value="ניין             ">ניין              </option>
              <option value="נילי           ">ניל"י             </option>
              <option value="ניצן             ">ניצן              </option>
              <option value="ניצן ב'          ">ניצן ב'           </option>
              <option value="ניצנה קהילת חינוך">ניצנה קהילת חינוך </option>
              <option value="ניצני סיני       ">ניצני סיני        </option>
              <option value="ניצני עוז        ">ניצני עוז         </option>
              <option value="ניצנים           ">ניצנים            </option>
              <option value="ניר אליהו        ">ניר אליהו         </option>
              <option value="ניר בנים         ">ניר בנים          </option>
              <option value="ניר גלים         ">ניר גלים          </option>
              <option value="ניר דוד תל עמל   ">ניר דוד תל עמל    </option>
              <option value="ניר חן          ">ניר ח"ן           </option>
              <option value="ניר יפה          ">ניר יפה           </option>
              <option value="ניר יצחק         ">ניר יצחק          </option>
              <option value="ניר ישראל        ">ניר ישראל         </option>
              <option value="ניר משה          ">ניר משה           </option>
              <option value="ניר עוז          ">ניר עוז           </option>
              <option value="ניר עם           ">ניר עם            </option>
              <option value="ניר עציון        ">ניר עציון         </option>
              <option value="ניר עקיבא        ">ניר עקיבא         </option>
              <option value="ניר צבי          ">ניר צבי           </option>
              <option value="נירים            ">נירים             </option>
              <option value="נירית            ">נירית             </option>
              <option value="נירן             ">נירן              </option>
              <option value="נמרוד            ">נמרוד             </option>
              <option value="נס הרים          ">נס הרים           </option>
              <option value="נס עמים          ">נס עמים           </option>
              <option value="נס ציונה         ">נס ציונה          </option>
              <option value="נעורים           ">נעורים            </option>
              <option value="נעלה             ">נעלה              </option>
              <option value="נעמה            ">נעמ"ה             </option>
              <option value="נען              ">נען               </option>
              <option value="נצאצרה שבט       ">נצאצרה שבט        </option>
              <option value="נצר חזני         ">נצר חזני          </option>
              <option value="נצר סרני         ">נצר סרני          </option>
              <option value="נצרת             ">נצרת              </option>
              <option value="נצרת עילית       ">נצרת עילית        </option>
              <option value="נשר              ">נשר               </option>
              <option value="נתיב הגדוד       ">נתיב הגדוד        </option>
              <option value="נתיב הלה        ">נתיב הל"ה         </option>
              <option value="נתיב העשרה       ">נתיב העשרה        </option>
              <option value="נתיב השיירה      ">נתיב השיירה       </option>
              <option value="נתיבות           ">נתיבות            </option>
              <option value="נתניה            ">נתניה             </option>
              <option value="סאג'ור           ">סאג'ור            </option>
              <option value="סאסא             ">סאסא              </option>
              <option value="סביון            ">סביון             </option>
              <option value="סגולה            ">סגולה             </option>
              <option value="סואעד חמרייה     ">סואעד חמרייה      </option>
              <option value="סואעד כמאנה שבט  ">סואעד כמאנה שבט   </option>
              <option value="סולם             ">סולם              </option>
              <option value="סוסיה            ">סוסיה             </option>
              <option value="סופה             ">סופה              </option>
              <option value="סח'נין           ">סח'נין            </option>
              <option value="סייד שבט         ">סייד שבט          </option>
              <option value="סלמה             ">סלמה              </option>
              <option value="סלעית            ">סלעית             </option>
              <option value="סמר              ">סמר               </option>
              <option value="סנסנה            ">סנסנה             </option>
              <option value="סעד              ">סעד               </option>
              <option value="סעוה             ">סעוה              </option>
              <option value="סער              ">סער               </option>
              <option value="ספיר             ">ספיר              </option>
              <option value="סתריה            ">סתריה             </option>
              <option value="ע'ג'ר            ">ע'ג'ר             </option>
              <option value="עבדון            ">עבדון             </option>
              <option value="עברון            ">עברון             </option>
              <option value="עגור             ">עגור              </option>
              <option value="עדי              ">עדי               </option>
              <option value="עדנים            ">עדנים             </option>
              <option value="עוזה             ">עוזה              </option>
              <option value="עוזייר           ">עוזייר            </option>
              <option value="עולש             ">עולש              </option>
              <option value="עומר             ">עומר              </option>
              <option value="עופר             ">עופר              </option>
              <option value="עופרה            ">עופרה             </option>
              <option value="עוצם             ">עוצם              </option>
              <option value="עוקבי בנו עוקבה  ">עוקבי בנו עוקבה   </option>
              <option value="עזוז             ">עזוז              </option>
              <option value="עזר              ">עזר               </option>
              <option value="עזריאל           ">עזריאל            </option>
              <option value="עזריה            ">עזריה             </option>
              <option value="עזריקם           ">עזריקם            </option>
              <option value="עטאוונה שבט      ">עטאוונה שבט       </option>
              <option value="עטרת             ">עטרת              </option>
              <option value="עידן             ">עידן              </option>
              <option value="עיילבון          ">עיילבון           </option>
              <option value="עיינות           ">עיינות            </option>
              <option value="עילוט            ">עילוט             </option>
              <option value="עין איילה        ">עין איילה         </option>
              <option value="עין אל-אסד       ">עין אל-אסד        </option>
              <option value="עין גב           ">עין גב            </option>
              <option value="עין גדי          ">עין גדי           </option>
              <option value="עין דור          ">עין דור           </option>
              <option value="עין הבשור        ">עין הבשור         </option>
              <option value="עין הוד          ">עין הוד           </option>
              <option value="עין החורש        ">עין החורש         </option>
              <option value="עין המפרץ        ">עין המפרץ         </option>
              <option value="עין הנציב       ">עין הנצי"ב        </option>
              <option value="עין העמק         ">עין העמק          </option>
              <option value="עין השופט        ">עין השופט         </option>
              <option value="עין השלושה       ">עין השלושה        </option>
              <option value="עין ורד          ">עין ורד           </option>
              <option value="עין זיוון        ">עין זיוון         </option>
              <option value="עין חוד          ">עין חוד           </option>
              <option value="עין חצבה         ">עין חצבה          </option>
              <option value="עין חרוד איחוד   ">עין חרוד איחוד    </option>
              <option value="עין חרוד מאוחד   ">עין חרוד מאוחד    </option>
              <option value="עין יהב          ">עין יהב           </option>
              <option value="עין יעקב         ">עין יעקב          </option>
              <option value="עין כרמל         ">עין כרמל          </option>
              <option value="עין מאהל         ">עין מאהל          </option>
              <option value="עין נקובא        ">עין נקובא         </option>
              <option value="עין עירון        ">עין עירון         </option>
              <option value="עין צורים        ">עין צורים         </option>
              <option value="עין קנייא        ">עין קנייא         </option>
              <option value="עין ראפה         ">עין ראפה          </option>
              <option value="עין שמר          ">עין שמר           </option>
              <option value="עין שריד         ">עין שריד          </option>
              <option value="עין תמר          ">עין תמר           </option>
              <option value="עינת             ">עינת              </option>
              <option value="עיר אובות        ">עיר אובות         </option>
              <option value="עכו              ">עכו               </option>
              <option value="עלומים           ">עלומים            </option>
              <option value="עלי              ">עלי               </option>
              <option value="עלי זהב          ">עלי זהב           </option>
              <option value="עלמה             ">עלמה              </option>
              <option value="עלמון            ">עלמון             </option>
              <option value="עמוקה            ">עמוקה             </option>
              <option value="עמיחי            ">עמיחי             </option>
              <option value="עמינדב           ">עמינדב            </option>
              <option value="עמיעד            ">עמיעד             </option>
              <option value="עמיעוז           ">עמיעוז            </option>
              <option value="עמיקם            ">עמיקם             </option>
              <option value="עמיר             ">עמיר              </option>
              <option value="עמנואל           ">עמנואל            </option>
              <option value="עמקה             ">עמקה              </option>
              <option value="ענב              ">ענב               </option>
              <option value="עספיא            ">עספיא             </option>
              <option value="עפולה            ">עפולה             </option>
              <option value="עץ אפרים         ">עץ אפרים          </option>
              <option value="עצמון שגב        ">עצמון שגב         </option>
              <option value="עראבה            ">עראבה             </option>
              <option value="עראמשה           ">עראמשה            </option>
              <option value="ערב אל נעים      ">ערב אל נעים       </option>
              <option value="ערד              ">ערד               </option>
              <option value="ערוגות           ">ערוגות            </option>
              <option value="ערערה            ">ערערה             </option>
              <option value="ערערה-בנגב       ">ערערה-בנגב        </option>
              <option value="עשרת             ">עשרת              </option>
              <option value="עתלית            ">עתלית             </option>
              <option value="עתניאל           ">עתניאל            </option>
              <option value="פארן             ">פארן              </option>
              <option value="פדואל            ">פדואל             </option>
              <option value="פדויים           ">פדויים            </option>
              <option value="פדיה             ">פדיה              </option>
              <option value="פוריה - כפר עבודה">פוריה - כפר עבודה </option>
              <option value="פוריה - נווה עובד">פוריה - נווה עובד </option>
              <option value="פוריה עילית      ">פוריה עילית       </option>
              <option value="פוריידיס         ">פוריידיס          </option>
              <option value="פורת             ">פורת              </option>
              <option value="פטיש             ">פטיש              </option>
              <option value="פלך              ">פלך               </option>
              <option value="פלמחים           ">פלמחים            </option>
              <option value="פני חבר          ">פני חבר           </option>
              <option value="פסגות            ">פסגות             </option>
              <option value="פסוטה            ">פסוטה             </option>
              <option value="פצאל             ">פצאל              </option>
              <option value="פקיעין בוקייעה   ">פקיעין בוקייעה    </option>
              <option value="פקיעין חדשה      ">פקיעין חדשה       </option>
              <option value="פרדס חנה-כרכור   ">פרדס חנה-כרכור    </option>
              <option value="פרדסיה           ">פרדסיה            </option>
              <option value="פרוד             ">פרוד              </option>
              <option value="פרזון            ">פרזון             </option>
              <option value="פרי גן           ">פרי גן            </option>
              <option value="פתח תקווה        ">פתח תקווה         </option>
              <option value="פתחיה            ">פתחיה             </option>
              <option value="צאלים            ">צאלים             </option>
              <option value="צביה             ">צביה              </option>
              <option value="צבעון            ">צבעון             </option>
              <option value="צובה             ">צובה              </option>
              <option value="צוחר             ">צוחר              </option>
              <option value="צופיה            ">צופיה             </option>
              <option value="צופים            ">צופים             </option>
              <option value="צופית            ">צופית             </option>
              <option value="צופר             ">צופר              </option>
              <option value="צוקי ים          ">צוקי ים           </option>
              <option value="צוקים            ">צוקים             </option>
              <option value="צור הדסה         ">צור הדסה          </option>
              <option value="צור יצחק         ">צור יצחק          </option>
              <option value="צור משה          ">צור משה           </option>
              <option value="צור נתן          ">צור נתן           </option>
              <option value="צוריאל           ">צוריאל            </option>
              <option value="צורית            ">צורית             </option>
              <option value="ציפורי           ">ציפורי            </option>
              <option value="צלפון            ">צלפון             </option>
              <option value="צנדלה            ">צנדלה             </option>
              <option value="צפריה            ">צפריה             </option>
              <option value="צפרירים          ">צפרירים           </option>
              <option value="צפת              ">צפת               </option>
              <option value="צרופה            ">צרופה             </option>
              <option value="צרעה             ">צרעה              </option>
              <option value="קבועה שבט        ">קבועה שבט         </option>
              <option value="קבוצת יבנה       ">קבוצת יבנה        </option>
              <option value="קדומים           ">קדומים            </option>
              <option value="קדימה-צורן       ">קדימה-צורן        </option>
              <option value="קדמה             ">קדמה              </option>
              <option value="קדמת צבי         ">קדמת צבי          </option>
              <option value="קדר              ">קדר               </option>
              <option value="קדרון            ">קדרון             </option>
              <option value="קדרים            ">קדרים             </option>
              <option value="קוואעין שבט      ">קוואעין שבט       </option>
              <option value="קוממיות          ">קוממיות           </option>
              <option value="קורנית           ">קורנית            </option>
              <option value="קטורה            ">קטורה             </option>
              <option value="קיסריה           ">קיסריה            </option>
              <option value="קלחים            ">קלחים             </option>
              <option value="קליה             ">קליה              </option>
              <option value="קלנסווה          ">קלנסווה           </option>
              <option value="קלע              ">קלע               </option>
              <option value="קציר             ">קציר              </option>
              <option value="קצר א-סר         ">קצר א-סר          </option>
              <option value="קצרין            ">קצרין             </option>
              <option value="קרית אונו        ">קרית אונו         </option>
              <option value="קרית ארבע        ">קרית ארבע         </option>
              <option value="קרית אתא         ">קרית אתא          </option>
              <option value="קרית ביאליק      ">קרית ביאליק       </option>
              <option value="קרית גת          ">קרית גת           </option>
              <option value="קרית טבעון       ">קרית טבעון        </option>
              <option value="קרית ים          ">קרית ים           </option>
              <option value="קרית יערים       ">קרית יערים        </option>
              <option value="קרית יעריםמוסד   ">קרית יעריםמוסד    </option>
              <option value="קרית מוצקין      ">קרית מוצקין       </option>
              <option value="קרית מלאכי       ">קרית מלאכי        </option>
              <option value="קרית נטפים       ">קרית נטפים        </option>
              <option value="קרית ענבים       ">קרית ענבים        </option>
              <option value="קרית עקרון       ">קרית עקרון        </option>
              <option value="קרית שלמה        ">קרית שלמה         </option>
              <option value="קרית שמונה       ">קרית שמונה        </option>
              <option value="קרני שומרון      ">קרני שומרון       </option>
              <option value="קשת              ">קשת               </option>
              <option value="ראמה             ">ראמה              </option>
              <option value="ראס אל-עין       ">ראס אל-עין        </option>
              <option value="ראס עלי          ">ראס עלי           </option>
              <option value="ראש העין         ">ראש העין          </option>
              <option value="ראש פינה         ">ראש פינה          </option>
              <option value="ראש צורים        ">ראש צורים         </option>
              <option value="ראשון לציון      ">ראשון לציון       </option>
              <option value="רבבה             ">רבבה              </option>
              <option value="רבדים            ">רבדים             </option>
              <option value="רביבים           ">רביבים            </option>
              <option value="רביד             ">רביד              </option>
              <option value="רגבה             ">רגבה              </option>
              <option value="רגבים            ">רגבים             </option>
              <option value="רהט              ">רהט               </option>
              <option value="רווחה            ">רווחה             </option>
              <option value="רוויה            ">רוויה             </option>
              <option value="רוח מדבר         ">רוח מדבר          </option>
              <option value="רוחמה            ">רוחמה             </option>
              <option value="רומאנה           ">רומאנה            </option>
              <option value="רומת הייב        ">רומת הייב         </option>
              <option value="רועי             ">רועי              </option>
              <option value="רותם             ">רותם              </option>
              <option value="רחוב             ">רחוב              </option>
              <option value="רחובות           ">רחובות            </option>
              <option value="רחלים            ">רחלים             </option>
              <option value="ריחאניה          ">ריחאניה           </option>
              <option value="ריחן             ">ריחן              </option>
              <option value="ריינה            ">ריינה             </option>
              <option value="רימונים          ">רימונים           </option>
              <option value="רינתיה           ">רינתיה            </option>
              <option value="רכסים            ">רכסים             </option>
              <option value="רם-און           ">רם-און            </option>
              <option value="רמות             ">רמות              </option>
              <option value="רמות השבים       ">רמות השבים        </option>
              <option value="רמות מאיר        ">רמות מאיר         </option>
              <option value="רמות מנשה        ">רמות מנשה         </option>
              <option value="רמות נפתלי       ">רמות נפתלי        </option>
              <option value="רמלה             ">רמלה              </option>
              <option value="רמת גן           ">רמת גן            </option>
              <option value="רמת דוד          ">רמת דוד           </option>
              <option value="רמת הכובש        ">רמת הכובש         </option>
              <option value="רמת השופט        ">רמת השופט         </option>
              <option value="רמת השרון        ">רמת השרון         </option>
              <option value="רמת יוחנן        ">רמת יוחנן         </option>
              <option value="רמת ישי          ">רמת ישי           </option>
              <option value="רמת מגשימים      ">רמת מגשימים       </option>
              <option value="רמת צבי          ">רמת צבי           </option>
              <option value="רמת רזיאל        ">רמת רזיאל         </option>
              <option value="רמת רחל          ">רמת רחל           </option>
              <option value="רנן              ">רנן               </option>
              <option value="רעים             ">רעים              </option>
              <option value="רעננה            ">רעננה             </option>
              <option value="רקפת             ">רקפת              </option>
              <option value="רשפון            ">רשפון             </option>
              <option value="רשפים            ">רשפים             </option>
              <option value="רתמים            ">רתמים             </option>
              <option value="שאר ישוב         ">שאר ישוב          </option>
              <option value="שבי דרום         ">שבי דרום          </option>
              <option value="שבי ציון         ">שבי ציון          </option>
              <option value="שבי שומרון       ">שבי שומרון        </option>
              <option value="שבלי - אום אל-גנם">שבלי - אום אל-גנם </option>
              <option value="שגב-שלום         ">שגב-שלום          </option>
              <option value="שדה אילן         ">שדה אילן          </option>
              <option value="שדה אליהו        ">שדה אליהו         </option>
              <option value="שדה אליעזר       ">שדה אליעזר        </option>
              <option value="שדה בוקר         ">שדה בוקר          </option>
              <option value="שדה דוד          ">שדה דוד           </option>
              <option value="שדה ורבורג       ">שדה ורבורג        </option>
              <option value="שדה יואב         ">שדה יואב          </option>
              <option value="שדה יעקב         ">שדה יעקב          </option>
              <option value="שדה יצחק         ">שדה יצחק          </option>
              <option value="שדה משה          ">שדה משה           </option>
              <option value="שדה נחום         ">שדה נחום          </option>
              <option value="שדה נחמיה        ">שדה נחמיה         </option>
              <option value="שדה ניצן         ">שדה ניצן          </option>
              <option value="שדה עוזיהו       ">שדה עוזיהו        </option>
              <option value="שדה צבי          ">שדה צבי           </option>
              <option value="שדות ים          ">שדות ים           </option>
              <option value="שדות מיכה        ">שדות מיכה         </option>
              <option value="שדי אברהם        ">שדי אברהם         </option>
              <option value="שדי חמד          ">שדי חמד           </option>
              <option value="שדי תרומות       ">שדי תרומות        </option>
              <option value="שדמה             ">שדמה              </option>
              <option value="שדמות דבורה      ">שדמות דבורה       </option>
              <option value="שדמות מחולה      ">שדמות מחולה       </option>
              <option value="שדרות            ">שדרות             </option>
              <option value="שואבה            ">שואבה             </option>
              <option value="שובה             ">שובה              </option>
              <option value="שובל             ">שובל              </option>
              <option value="שוהם             ">שוהם              </option>
              <option value="שומרה            ">שומרה             </option>
              <option value="שומריה           ">שומריה            </option>
              <option value="שוקדה            ">שוקדה             </option>
              <option value="שורש             ">שורש              </option>
              <option value="שורשים           ">שורשים            </option>
              <option value="שושנת העמקים     ">שושנת העמקים      </option>
              <option value="שזור             ">שזור              </option>
              <option value="שחר              ">שחר               </option>
              <option value="שחרות            ">שחרות             </option>
              <option value="שיבולים          ">שיבולים           </option>
              <option value="שיטים            ">שיטים             </option>
              <option value="שייח' דנון       ">שייח' דנון        </option>
              <option value="שילה             ">שילה              </option>
              <option value="שילת             ">שילת              </option>
              <option value="שכניה            ">שכניה             </option>
              <option value="שלווה            ">שלווה             </option>
              <option value="שלווה במדבר      ">שלווה במדבר       </option>
              <option value="שלוחות           ">שלוחות            </option>
              <option value="שלומי            ">שלומי             </option>
              <option value="שלומית           ">שלומית            </option>
              <option value="שמיר             ">שמיר              </option>
              <option value="שמעה             ">שמעה              </option>
              <option value="שמרת             ">שמרת              </option>
              <option value="שמשית            ">שמשית             </option>
              <option value="שני              ">שני               </option>
              <option value="שניר             ">שניר              </option>
              <option value="שעב              ">שעב               </option>
              <option value="שעורים           ">שעורים            </option>
              <option value="שעל              ">שעל               </option>
              <option value="שעלבים           ">שעלבים            </option>
              <option value="שער אפרים        ">שער אפרים         </option>
              <option value="שער הגולן        ">שער הגולן         </option>
              <option value="שער העמקים       ">שער העמקים        </option>
              <option value="שער מנשה         ">שער מנשה          </option>
              <option value="שערי תקווה       ">שערי תקווה        </option>
              <option value="שפיים            ">שפיים             </option>
              <option value="שפיר             ">שפיר              </option>
              <option value="שפר              ">שפר               </option>
              <option value="שפרעם            ">שפרעם             </option>
              <option value="שקד              ">שקד               </option>
              <option value="שקף              ">שקף               </option>
              <option value="שרונה            ">שרונה             </option>
              <option value="שריגים לי-און    ">שריגים לי-און     </option>
              <option value="שריד             ">שריד              </option>
              <option value="שרשרת            ">שרשרת             </option>
              <option value="שתולה            ">שתולה             </option>
              <option value="שתולים           ">שתולים            </option>
              <option value="תאשור            ">תאשור             </option>
              <option value="תדהר             ">תדהר              </option>
              <option value="תובל             ">תובל              </option>
              <option value="תומר             ">תומר              </option>
              <option value="תושיה            ">תושיה             </option>
              <option value="תימורים          ">תימורים           </option>
              <option value="תירוש            ">תירוש             </option>
              <option value="תל אביב - יפו    ">תל אביב - יפו     </option>
              <option value="תל יוסף          ">תל יוסף           </option>
              <option value="תל יצחק          ">תל יצחק           </option>
              <option value="תל מונד          ">תל מונד           </option>
              <option value="תל עדשים         ">תל עדשים          </option>
              <option value="תל קציר          ">תל קציר           </option>
              <option value="תל שבע           ">תל שבע            </option>
              <option value="תל תאומים        ">תל תאומים         </option>
              <option value="תלם              ">תלם               </option>
              <option value="תלמי אליהו       ">תלמי אליהו        </option>
              <option value="תלמי אלעזר       ">תלמי אלעזר        </option>
              <option value="תלמי ביל"       >תלמי בילו        </option>
              <option value="תלמי יוסף        ">תלמי יוסף         </option>
              <option value="תלמי יחיאל       ">תלמי יחיאל        </option>
              <option value="תלמי יפה         ">תלמי יפה          </option>
              <option value="תלמים            ">תלמים             </option>
              <option value="תמרת             ">תמרת              </option>
              <option value="תנובות           ">תנובות            </option>
              <option value="תעוז             ">תעוז              </option>
              <option value="תפרח             ">תפרח              </option>
              <option value="תקומה            ">תקומה             </option>
              <option value="תקוע             ">תקוע              </option>
              <option value="תראבין א-צאנע שבט">תראבין א-צאנע שבט </option>
              <option value="תראבין א-צאנעישוב">תראבין א-צאנעישוב </option>
              <option value="תרום             ">תרום              </option>
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3" controlId="postalCode">
            <Form.Label>מיקוד</Form.Label>
            <Form.Control
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
              maxLength={7}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="country">
            <Form.Label>מדינה</Form.Label>
            <Form.Control
              value="ישראל"
              placeholder="ישראל"
              disabled
              onChange={(e) => setCountry(e.target.value)}
              required
            />
          </Form.Group>
          {/* <div className="mb-3">
            <Button
              id="chooseOnMap"
              type="button"
              variant="light"
              onClick={() => navigate('/map')}
            >
              בחר מיקום במפה
            </Button>
            {shippingAddress.location && shippingAddress.location.lat ? (
              <div>
                קו רוחב: {shippingAddress.location.lat}
                קו אורח:{shippingAddress.location.lng}
              </div>
            ) : (
              <div>מיקום לא זמין</div>
            )}
          </div> */}

          <div className="mb-3">
            <Button variant="primary" type="submit">
              המשך
            </Button>
          </div>
        </Form>
      </div>
    </div >
  );
}
