import React, { useEffect, useReducer, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getError } from '../utils';
import { Helmet } from 'react-helmet-async';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Rating from '../components/Rating';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import Button from 'react-bootstrap/Button';
import Product from '../components/Product';
import LinkContainer from 'react-router-bootstrap/LinkContainer';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        products: action.payload.products,
        page: action.payload.page,
        pages: action.payload.pages,
        countProducts: action.payload.countProducts,
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

const prices = [
  {
    name: 'מ₪50 ל₪200',
    value: '100-250',
  },
  {
    name: 'מ₪200 ל₪1000',
    value: '250-450',
  },
  {
    name: 'מ₪450 ל₪800',
    value: '450-800',
  },
];

export const ratings = [
  {
    name: 'כוככבים ומעלה 4',
    rating: 4,
  },

  {
    name: 'כוככבים ומעלה 3',
    rating: 3,
  },

  {
    name: '2כוכבים ומעלה ',
    rating: 2,
  },

  {
    name: 'כוכב אחד ומעלה',
    rating: 1,
  },
];

export default function SearchScreen() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const sp = new URLSearchParams(search); // /search?category=Shirts
  const category = sp.get('category') || 'all';
  const query = sp.get('query') || 'all';
  const price = sp.get('price') || 'all';
  const rating = sp.get('rating') || 'all';
  const order = sp.get('order') || 'newest';
  const page = sp.get('page') || 1;

  const [{ loading, error, products, pages, countProducts }, dispatch] =
    useReducer(reducer, {
      loading: true,
      error: '',
    });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `https://et-jewelry-api.onrender.com/api/products/search?page=${page}&query=${query}&category=${category}&price=${price}&rating=${rating}&order=${order}`
        );
        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (err) {
        dispatch({
          type: 'FETCH_FAIL',
          payload: getError(error),
        });
      }
    };
    fetchData();
  }, [category, error, order, page, price, query, rating]);


  const [categories, setCategories] = useState([]);
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const { data } = await axios.get(`https://et-jewelry-api.onrender.com/api/products/categories`); setCategories(data);
      } catch (err) {
        toast.error(getError(err));
      }
    };
    fetchCategories();
  }, [dispatch]);

  const getFilterUrl = (filter) => {
    const filterPage = filter.page || page;
    const filterCategory = filter.category || category;
    const filterQuery = filter.query || query;
    const filterRating = filter.rating || rating;
    const filterPrice = filter.price || price;
    const sortOrder = filter.order || order;
    return `/search?category=${filterCategory}&query=${filterQuery}&price=${filterPrice}&rating=${filterRating}&order=${sortOrder}&page=${filterPage}`;
  };
  return (
    <div>
      <Helmet>
        <title>חיפוש מוצרים</title>
      </Helmet>
      <Row>
        <Col md={12}>
          {loading ? (
            <LoadingBox></LoadingBox>
          ) : error ? (
            <MessageBox variant="danger">{error}</MessageBox>
          ) : (
            <>
              {/* <Row className="justify-content-between mb-2">
                <Col md={6}>
                  <div>
                    {countProducts === 0 ? 'No' : countProducts}תוצאות  
                    {query !== 'all' && ' : ' + query}
                    {category !== 'all' && ' : ' + category}
                    {price !== 'all' && ' : מחיר' + price}
                    {rating !== 'all' && ' : דירוג ' + rating + ' & ומעלה'}
                    {query !== 'all' ||
                      category !== 'all' ||
                      rating !== 'all' ||
                      price !== 'all' ? (
                      <Button
                        variant="dark"
                        onClick={() => navigate('/search')}
                      >
                        <i className="fas fa-times-circle"></i>
                      </Button>
                    ) : null}
                  </div>
                </Col>
              </Row> */}

              {products.length === 0 && (
                <MessageBox>לא נמצאו מוצרים</MessageBox>
              )}
              <Row className="justify-content-between ">
                <Col md={12}>
                  <div>
                    {countProducts === 0 ? 'No' : countProducts}&nbsp;:תוצאות
                    {query !== 'all' && ':' + query}
                    {category !== 'all' && ': ' + category}&nbsp; | כמות באתר
                    {price !== 'all' && ': מחיר' + price}
                    {rating !== 'all' && ' : דירוג' + rating + ' & ומעלה'}
                    {query !== 'all' ||
                      category !== 'all' ||
                      rating !== 'all' ||
                      price !== 'all' ? (
                      <Badge
                        bg="none"
                        Style="color:white; margin-right:-7px; cursor:pointer;"
                        onClick={() => navigate('/search')}
                      >
                        <i className="fas fa-times-circle"></i>
                      </Badge>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Col md={12}>
                {/* 
       <h3>Department</h3>
          <div>
            <ul>
              <li>
                <Link
                  className={'all' === category ? 'text-bold' : ''}
                  to={getFilterUrl({ category: 'all' })}
                >
                  הכל
                </Link>
              </li>
              {categories.map((c) => (
                <li key={c}>
                  <Link
                    className={c === category ? 'text-bold' : ''}
                    to={getFilterUrl({ category: c })}
                  >
                    {c}
                  </Link>
                </li>
              ))}
            </ul>
          </div> */}
                <br></br>

                <Col className="text-end">
                  <h4>סנן לפי</h4>
                 
                  <Form.Select
                    value={order}
                    Style="width:200px; float:right; color:white;border:solid 1px #2a2a2a; background-color:#181a1b;"
                    onChange={(e) => {
                      navigate(getFilterUrl({ order: e.target.value }));
                    }}
                  >
                    <option value="newest" selected>מוצר החדש ביותר</option>
                    <option value="lowest">מחיר: מנמוך לגבוה</option>
                    <option value="highest">מחיר: מגבוה לנמוך</option>
                    {/* <option value="toprated">כמות ביקורות</option> */}
                  </Form.Select>
                </Col>
                <br></br>
                <br></br>
                <div>
                  <h4>מחיר</h4>
                  <ul>
                    <ol>
                      <Link
                        className={'all' === price ? 'text-bold' : ''}
                        to={getFilterUrl({ price: 'all' })}
                      >
                        הכל
                      </Link>
                    </ol>
                    {prices.map((p) => (
                      <ol key={p.value}>
                        <Link
                          to={getFilterUrl({ price: p.value })}
                          className={p.value === price ? 'text-bold' : ''}
                        >
                          {p.name}
                        </Link>
                      </ol>
                    ))}
                  </ul>
                </div>
                <div>
                  <h4>הצג לפי כמות ביקורות</h4>
                  <ul>
                    {ratings.map((r) => (
                      <ol key={r.name}>

                        <Link
                          to={getFilterUrl({ rating: r.rating })}
                          className={`${r.rating}` === `${rating}` ? 'text-bold' : ''}
                        >
                          <Rating caption={' '} rating={r.rating}></Rating>
                        </Link>
                      </ol>
                    ))}
                    <ol>
                      <Link
                        to={getFilterUrl({ rating: 'all' })}
                        className={rating === 'all' ? 'text-bold' : ''}
                      >
                        <Rating caption={' '} rating={0}></Rating>
                      </Link>
                    </ol>
                  </ul>
                </div>
              </Col>
              <Row>
                {products.map((product) => (
                  <Col sm={6} lg={3} className="mb-4" id="products-search-col" key={product._id}>
                    <Product className="search-products" product={product}></Product>
                  </Col>
                ))}
              </Row>
            </>
          )}


        </Col>
      </Row>
      <div Style="text-align:center">
        {[...Array(pages).keys()].map((x) => (
          <Link
            key={x + 1}
            className="mx-1"
            to={getFilterUrl({ page: x + 1 })}
          >
            <Button
              className={Number(page) === x + 1 ? 'text-bold' : ''}
              variant="dark"
            >
              {x + 1}
            </Button>
          </Link>
        ))}
      </div>
    </div>
  );
}
