import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HomeScreen from './screens/HomeScreen';
import ProductScreen from './screens/ProductScreen';
import Navbar from 'react-bootstrap/Navbar';
import Badge from 'react-bootstrap/Badge';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import { LinkContainer } from 'react-router-bootstrap';
import React, { useContext, useEffect, useState } from 'react';
import { Store } from './Store';
import CartScreen from './screens/CartScreen';
import SigninScreen from './screens/SigninScreen';
import ShippingAddressScreen from './screens/ShippingAddressScreen';
import SignupScreen from './screens/SignupScreen';
import PaymentMethodScreen from './screens/PaymentMethodScreen';
import PlaceOrderScreen from './screens/PlaceOrderScreen';
import OrderScreen from './screens/OrderScreen';
import OrderHistoryScreen from './screens/OrderHistoryScreen';
import ProfileScreen from './screens/ProfileScreen';
import { getError } from './utils';
import axios from 'axios';
import SearchBox from './components/SearchBox';
import SearchScreen from './screens/SearchScreen';
import ProtectedRoute from './components/ProtectedRoute';
import AdminRoute from './components/AdminRoute';
import ProductListScreen from './screens/ProductListScreen';
import ProductEditScreen from './screens/ProductEditScreen';
import OrderListScreen from './screens/OrderListScreen';
import UserListScreen from './screens/UserListScreen';
import UserEditScreen from './screens/UserEditScreen';
import TermsOfServiceScreen from './screens/TermsOfServiceScreen';
import ContactScreen from './screens/ContactScreen';
import DashboardScreen from './screens/DashboardScreen';
import Button from 'react-bootstrap/Button';
import { MDBBtn } from 'mdb-react-ui-kit';


export default function App() {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { fullBox, cart, userInfo } = state;
  const [isOpen, setOpen] = useState(false);


  const signoutHandler = () => {
    ctxDispatch({ type: 'USER_SIGNOUT' });
    localStorage.removeItem('userInfo');
    localStorage.removeItem('shippingAddress');
    localStorage.removeItem('paymentMethod');
    window.location.href = '/signin';
  };
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {

        const { data } = await axios.get('https://et-jewelry-api.onrender.com/api/products/categories');

        setCategories(Array.from(data));
      } catch (err) {

        toast.error(getError(err));
      }
    };
    fetchCategories();
  }, []);
  return (
    <BrowserRouter>
      <div
        className={
          sidebarIsOpen
            ? fullBox
              ? 'site-container active-cont d-flex flex-column full-box'
              : 'site-container active-cont d-flex flex-column'
            : fullBox
              ? 'site-container d-flex flex-column full-box'
              : 'site-container d-flex flex-column'
        }
      >
        <ToastContainer position="bottom-right"  toastStyle={{ backgroundColor: "#181a1b",color:'white' }}/>
        <header>


          <Navbar fixed="top" className="navnav" variant="dark">
            <Container>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto w-100 justify-content-end">
                  &nbsp;&nbsp;&nbsp;{!userInfo?.isAdmin ? (
                    <MDBBtn id="mdb-bars" Style="background-color: #222424; box-shadow:none; border:none;" onClick={() => setSidebarIsOpen(!sidebarIsOpen)} >
                      <i className="fas fa-bars"></i>
                    </MDBBtn>
                    // <Hamburger size={20}   toggled={isOpen} toggle={setOpen} onToggle={() => setSidebarIsOpen(!sidebarIsOpen)} id="sidebar-btn" />
                  ) : userInfo?.isAdmin === true && (
                    <span></span>
                  )}
                  &nbsp;&nbsp;&nbsp;<SearchBox />
                  {!userInfo?.isAdmin &&
                    <Link to="/cart" className="nav-link">
                      {cart.cartItems.length > 0 && (
                        <Badge pill bg="danger">
                          <i className="fa fa-shopping-cart"></i>&nbsp;
                          {cart.cartItems.reduce((a, c) => a + c.quantity, 0)}
                        </Badge>

                      )}
                    </Link>
                  }
                  <Link className="nav-link" id="nav-contact" to="/contact-us">
                    צור קשר
                  </Link>
                  {userInfo?.isAdmin === false ? (
                    <NavDropdown title={<i class="fas fa-user fa-xs"> </i>} id={`dropdown-button-drop-down`} drop={`down`} menuVariant="dark">
             
                      <NavDropdown.Item disabled Style="text-align:right; font-weight:bolder; color:white;">{userInfo.name}</NavDropdown.Item>
                      
                      <div className='hrhr'></div>
                      <LinkContainer to="/orderhistory" Style="text-align:right">
                        <NavDropdown.Item>היסטורית הזמנות</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/profile" Style="text-align:right">
                        <NavDropdown.Item>שינוי סיסמה</NavDropdown.Item>
                      </LinkContainer>
                      <div className='hrhr'></div>
                      <Link Style="text-align:right"
                        className="dropdown-item"
                        to="#signout"
                        onClick={signoutHandler}
                      >
                        התנתק
                      </Link>
                 
                    </NavDropdown>
                  ) : !userInfo && (

                    <Link className="nav-link" to="/signin">
                      התחבר
                    </Link>
                  )}

                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  {userInfo?.isAdmin === true && (
                    <NavDropdown sticky="top" title="מנהל" id="admin-nav-dropdown" menuVariant="dark">
                      <LinkContainer to="/admin/dashboard">
                        <NavDropdown.Item>Dashboard</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/admin/products">
                        <NavDropdown.Item>הוסף/ערוך מוצרים</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/admin/orders">
                        <NavDropdown.Item>ערוך הזמנות</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/admin/users">
                        <NavDropdown.Item>ערוך משתמשים</NavDropdown.Item>
                      </LinkContainer>
                      <NavDropdown.Divider />
                      <LinkContainer to="/profile">
                        <NavDropdown.Item>פרופיל מנהל</NavDropdown.Item>
                      </LinkContainer>
                      <Link
                        className="dropdown-item"
                        to="#signout"
                        onClick={signoutHandler}
                      >
                        התנתק
                      </Link>
                    </NavDropdown>
                  )}
                </Nav>
                &nbsp;
                <LinkContainer to="/">
                  <Navbar.Brand id="navbar-brand">&nbsp;אִיתִּי תכשיטים</Navbar.Brand>
                </LinkContainer>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </header>
<br></br>
<br></br>
        <div
          className={
            sidebarIsOpen
              ? 'active-nav side-navbar d-flex justify-content-between flex-wrap flex-column'
              : 'side-navbar d-flex justify-content-between flex-wrap flex-column'
          }
        >
          <Nav className="flex-column text-white w-100 p-2 scroll">
            <Nav.Item>
              <strong Style="text-decoration:underline; ">קטגוריות</strong>
              <Badge Style="margin-left:5px;  color:white; cursor:pointer;" bg="none" onToggle={!isOpen} onClick={() => setSidebarIsOpen(!sidebarIsOpen)}>
                <i className="fas fa-close"></i>
              </Badge>
              &nbsp;</Nav.Item>
            {categories.map((category) => (
              <Nav.Item key={category}>
                <LinkContainer
                  Style="margin-right:15px;"
                  to={{
                    pathname: "/search",
                    search: `?category=${category}`
                  }}
                  onClick={() => setSidebarIsOpen(false)}
                >
                  <Nav.Link>{category}</Nav.Link>
                </LinkContainer>
              </Nav.Item>

            ))}
            <Nav.Item>
              <Nav.Item>
                <LinkContainer
                  Style="margin-right:15px;"
                  to={`/search`}
                  onClick={() => setSidebarIsOpen(false)} >
                  <Nav.Link>כל המוצרים</Nav.Link>
                </LinkContainer>
              </Nav.Item>
              <strong Style="text-decoration:underline; margin-right:30.3px;">צור קשר</strong>
            </Nav.Item>
            <Nav.Item>
              <LinkContainer
                Style="margin-right:15px;"
                to={`/contact-us`}
                onClick={() => setSidebarIsOpen(false)} >
                <Nav.Link>שלח אימייל</Nav.Link>
              </LinkContainer>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link>
                <a Style="margin-right:15px;text-decoration:none;" href="https://api.whatsapp.com/send?phone=972523500700&text=&source=&data=" target="_blank">הודעה בווצ'אפ</a>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <strong Style="text-decoration:underline; margin-right:30.3px;">משתמש</strong>
            </Nav.Item>
            <Nav.Item>
              <LinkContainer
                Style="margin-right:15px;"
                to={`/signup`}
                onClick={() => setSidebarIsOpen(false)} >
                <Nav.Link>רישום לאתר</Nav.Link>
              </LinkContainer>
            </Nav.Item>
            <Nav.Item>
              <LinkContainer
                Style="margin-right:15px;"
                to={`/signin`}
                onClick={() => setSidebarIsOpen(false)} >
                <Nav.Link>התחברות לאתר</Nav.Link>
              </LinkContainer>
            </Nav.Item>
          </Nav>
        </div>
        <main>
          <Container className="mt-3">
            <Routes>
              <Route path="/product/:slug" element={<ProductScreen />} />
              <Route path="/cart" element={<CartScreen />} />
              <Route path="/search" element={<SearchScreen />} />
              <Route path="/signin" element={<SigninScreen />} />
              <Route path="/signup" element={<SignupScreen />} />
              <Route path="/terms-of-service" element={<TermsOfServiceScreen />} />
              <Route path="/contact-us" element={<ContactScreen />} />


              <Route
                path="/profile"
                element={
                  <ProtectedRoute>
                    <ProfileScreen />
                  </ProtectedRoute>
                }
              />
              <Route path="/placeorder" element={<PlaceOrderScreen />} />
              <Route
                path="/order/:id"
                element={
                  <ProtectedRoute>
                    <OrderScreen />
                  </ProtectedRoute>
                }
              ></Route>
              <Route
                path="/orderhistory"
                element={
                  <ProtectedRoute>
                    <OrderHistoryScreen />
                  </ProtectedRoute>
                }
              ></Route>
              <Route
                path="/shipping"
                element={<ShippingAddressScreen />}
              ></Route>
              <Route path="/payment" element={<PaymentMethodScreen />}></Route>
              {/* Admin Routes */}
              <Route
                path="/admin/dashboard"
                element={
                  <AdminRoute>
                    <DashboardScreen />
                  </AdminRoute>
                }
              ></Route>
              <Route
                path="/admin/orders"
                element={
                  <AdminRoute>
                    <OrderListScreen />
                  </AdminRoute>
                }
              ></Route>
              <Route
                path="/admin/users"
                element={
                  <AdminRoute>
                    <UserListScreen />
                  </AdminRoute>
                }
              ></Route>
              <Route
                path="/admin/products"
                element={
                  <AdminRoute>
                    <ProductListScreen />
                  </AdminRoute>
                }
              ></Route>
              <Route
                path="/admin/product/:id"
                element={
                  <AdminRoute>
                    <ProductEditScreen />
                  </AdminRoute>
                }
              ></Route>
              <Route
                path="/admin/user/:id"
                element={
                  <AdminRoute>
                    <UserEditScreen />
                  </AdminRoute>
                }
              ></Route>

              <Route path="/" element={<HomeScreen />} />
            </Routes>
          </Container>
        </main>
        <footer>
          <br></br>
          <div className="text-center mb-2">
            .כל הזכויות שמורות לליר עדיני©
            <br></br>
            <a target="_blank" rel="noreferrer" href="https://api.whatsapp.com/send?phone=972523500700&text=&source=&data=" ><i className="fab fa-whatsapp fa-2x">&nbsp;&nbsp;</i></a><a target="_blank" rel="noreferrer" href="https://www.facebook.com/etsunadini"><i className="fab fa-facebook fa-2x">&nbsp;&nbsp;</i></a><Link to="/terms-of-service">לחץ כאן למדיניות פרטיות האתר</Link>
          </div>
          {/* <div className="text-center">
          .כל הזכויות שמורות לאיתי עדיני. האתר נבנה על-ידי ליר עדיני, <Link to="/terms-of-service">לחץ כאן לתנאי השימוש</Link>©
          </div> */}

        </footer>
      </div>
    </BrowserRouter>
  );
}